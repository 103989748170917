import React from "react";
import {
  Animate,
  AnimateGroup,
  useAnimateGroup,
  AnimateKeyframes,
} from "react-simple-animate";

import { initReactI18next, useTranslation } from "react-i18next";

function LetterAppear({ tri, set_tri }) {
  const [t, i18n] = useTranslation();

  const [a_2, set_a_2] = React.useState(false);
  const [a_3, set_a_3] = React.useState(false);

  return (
    <p
      style={{
        // fontSize: 137,
        fontSize: "9vw",
        // fontSize: 60,
        fontFamily: "Montserrat, sans-serif",
        fontWeight: 600,
        lineHeight: 1,
        letterSpacing: 0,
        marginLeft: "10vw",
        // WebkitTextStrokeColor: "white",
        // WebkitTextStrokeWidth: 2,
      }}
    >
      {/* <span style={{ overflow: "hidden", display: "block" }}>
        <AnimateLetter
          tri={tri}
          set_tri={set_a_2}
          // color="transparent"
          str="Interior"
          css={"outline"}
          delay={0}
        />
      </span>

      <span style={{ overflow: "hidden", display: "block" }}>
        <AnimateLetter
          tri={tri}
          set_tri={set_a_3}
          str="Exterior"
          css={"solid"}
          // color="white"
          delay={0.2}
        />
      </span>
      <span style={{ overflow: "hidden", display: "block" }}>
        <AnimateLetter
          tri={tri}
          set_tri={set_tri}
          str="Renovation"
          css={"outline"}
          // color="transparent"
          delay={0.4}
        />
      </span> */}

      <span style={{ overflow: "hidden", display: "block" }}>
        <span style={{ overflow: "hidden", display: "block" }}>
          <AnimateLetter
            tri={tri}
            set_tri={set_a_2}
            str="Renovation"
            css={"solid"}
            // color="transparent"
          delay={0}
          />
        </span>
        <span style={{ overflow: "hidden", display: "block" }}>
          <AnimateLetter
            tri={tri}
            set_tri={set_a_3}
            str="Exterior"
            css={"outline"}
            // color="white"
            delay={0.4}
          />
        </span>
        <AnimateLetter
          tri={tri}
          set_tri={set_tri}
          // color="transparent"
          str="Interior"
          css={"outline"}
            delay={0.8}
        />
      </span>
    </p>
  );
}

function AnimateLetter({ tri, set_tri, str, color, delay, css }) {
  return (
    <Animate
      play={tri}
      pause={false}
      // easeType="cubic-bezier(0.165, 0.84, 0.44, 1)"
      // easeType="cubic-bezier(0.170, 0.85, 0.50, 1)"
      easeType="ease"
      delay={delay}
      duration={1}
      start={{ opacity: 0, transform: "translateY(80px)" }}
      end={{ opacity: 1, transform: "translateY(0)" }}
      onComplete={() => {
        set_tri(true);
      }}
    >
      {/* <span className={css} style={{ color }}> */}
      <span className={css}>{str}</span>
      <br />
    </Animate>
  );
}

export default LetterAppear;
