import { combineReducers } from "redux";

import { entries } from "../stores_api/entries";
import { entries_id } from "../stores_api/entries_id";
import { postal } from "../stores_api/postal";
import { contacts } from "../stores_api/contacts";

export default combineReducers({
  entries,
  entries_id,
  postal,
  contacts
});
