import React from "react";
import { Button, Container, Row, Col } from "reactstrap";
import { Transition } from "react-transition-group";
import { initReactI18next, useTranslation } from "react-i18next";

export function DivImage1({ scroll }) {
  const [t, i18n] = useTranslation();

  return (
    <>
      <div
        style={{
          minHeight: "100vh",
          backgroundPosition: "center center",
          backgroundSize: "cover",
          // backgroundImage: `url(${require("assets/img/envato/back2-min.jpg")})`,
          backgroundImage: `url(${require("assets/img/envato/terrace1-min.jpg")})`,
        }}
      ></div>
    </>
  );
}

export function DivImage2({ scroll }) {
  const [t, i18n] = useTranslation();

  return (
    <>
      <div
        style={{
          minHeight: "100vh",
          backgroundPosition: "center center",
          backgroundSize: "cover",
          // backgroundImage: `url(${require("assets/img/envato/back3-min.jpg")})`,
          backgroundImage: `url(${require("assets/img/envato/kitchen-min.jpg")})`,
        }}
      ></div>
    </>
  );
}
