import { useDispatch, useSelector } from "react-redux";
import React, { FC, useEffect, useRef, useState } from "react";
import BlogDetail from "../ContainerLG/BlogDetail";
import BlogDetailSM from "../ContainerSM/BlogDetail";
import { Helmet } from "react-helmet";
function BlogDetailContainer() {

  const [width, set_width] = React.useState(window.innerWidth);

  function resizeListener() {
    set_width(window.innerWidth);
  }
  
  useEffect(() => {
    window.addEventListener("resize", resizeListener);

    return function cleanup() {
      window.removeEventListener("resize", resizeListener);
    };

  }, []);




  return (
    <>
      <Helmet>
        <meta
          name="keywords"
          content="松永興業株式会社,東大阪市,給湯器交換,外壁塗装"
        />
        <title>日々の仕事などを配信｜松永興業株式会社</title>
        <meta
          name="description"
          content="給湯器の交換で年間4億5000万円を売り上げている松永興業（大阪府東大阪市） は、外装や水まわりのリフォームの受注強化を進めています。 同社の強みは、年間3000件もの給湯器交換を自社職人で実施していることにあります。"
        />
        <link
          rel="canonical"
          href="https://www.matsunaga-industrial.com/blogdetail"
        />
      </Helmet>

      {width > 992 ? (
        <BlogDetail />
      ) : (
        <div id="style-sm" className="overflow-hidden">
          <BlogDetailSM />
        </div>
      )}

{/* 
      <div className="d-none d-lg-block">
        <BlogDetail />
      </div>
      <div className="d-lg-none overflow-hidden"><BlogDetailSm /></div> */}
    </>
  );
}

export default BlogDetailContainer;
