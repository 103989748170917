import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import reducer from "./reducers";
import saga from "./sagas";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import sessionStorage from "redux-persist/lib/storage/session";

// 永続化の設定
const persistConfig = {
  key: "lotterie", // Storageに保存されるキー名を指定する
  storage:sessionStorage, // 保存先としてlocalStorageがここで設定される
//   whitelist: [
//     "forgot_passwords",
//     "forgot_passwords_codes",
//     "forgot_passwords_hash",
//   ], 
  // Stateは`todos`のみStorageに保存する
  // blacklist: ['visibilityFilter'] // `visibilityFilter`は保存しない
};

// 永続化設定されたReducerとして定義
const persistedReducer = persistReducer(persistConfig, reducer);

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
  persistedReducer,
  applyMiddleware(sagaMiddleware)
);

sagaMiddleware.run(saga);

export const persistor = persistStore(store);
