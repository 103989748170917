import { useDispatch, useSelector } from "react-redux";
import React, { FC, useEffect, useRef, useState } from "react";
import About from "../ContainerLG/About";
import AboutSM from "../ContainerSM/About";
import { Helmet } from "react-helmet";
function AboutContainer() {

  const [width, set_width] = React.useState(window.innerWidth);

  function resizeListener() {
    set_width(window.innerWidth);
  }
  
  useEffect(() => {
    window.addEventListener("resize", resizeListener);

    return function cleanup() {
      window.removeEventListener("resize", resizeListener);
    };

  }, []);



  return (
    <>
      <Helmet>
        <meta
          name="keywords"
          content="松永興業株式会社,給湯器交換,外壁塗装"
        />
        <title>支持されている様々な特徴をご紹介｜松永興業株式会社</title>
        <meta
          name="description"
          content="下請け、外注が当たり前の業界ですが、当社は自社職人がお伺いし、工事を行います。給湯器だけでも年間3,000件以上の工事を行います。施工実績1万件以上、安心安全の当社に是非お任せ下さいませ。"
        />
        <link
          rel="canonical"
          href="https://www.matsunaga-industrial.com/about"
        />
      </Helmet>
      {width > 992 ? (
        <About />
      ) : (
        <div id="style-sm" className="overflow-hidden">
          <AboutSM />
        </div>
      )}


      {/* <div className="d-none d-lg-block">
        <About />
      </div>
      <div className="d-lg-none overflow-hidden"><AboutSm /></div> */}
    </>
  );
}

export default AboutContainer;
