import React, { FC, useEffect, useRef, useState } from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import { useSpring, animated, interpolate } from "react-spring";

export function ContainerFullImage({ inview }) {
  // { opa, tra, clip }
  let { opa, flowright, flowleft, transxleft, transxright, transytop } =
    useSpring({
      opa: inview ? 1 : 0,
      flowright: inview
        ? "rect(0vh, 100vw, 100vh, 0vw)"
        : "rect(0vh, 0vw, 100vh, 0vw)",
      flowleft: inview
        ? "rect(0vh, 100vw, 100vh, 0vw)"
        : "rect(0vh, 100vw, 100vh, 100vw)",
      transxleft: inview ? "translate3d(0px, 0, 0)" : "translate3d(70px, 0, 0)",
      transxright: inview
        ? "translate3d(0px, 0, 0)"
        : "translate3d(-100px, 0, 0)",

      transytop: inview ? "translateY(0)" : "translateY(70px)",
    });

  return (
    <Container fluid className="pb-5" style={{ paddingBottom: 200 }}>
      <Row>
        <Col lg="12">
          <div
            className="backgroundImage-80"
            style={{
              // float: "right",
              // position: "relative",
              // top: "5vh",
              left: 0,
              width: "100%",
              height: "40vw",
              backgroundPosition: "0px 0px",
              backgroundSize: "cover",
              // backgroundSize: "100%",
              backgroundRepeat: "no-repeat",
              // marginRight:30,
              // marginLeft:30,
              // marginBottom:30,
              // backgroundImage:
              //   // "url(" + require("assets/img/envato/handshake2.jpg") + ")",
              //   "url(" +
              //   // require("assets/img/barista-preparing-cup-of-coffee-for-customer-in-co-P5XSB35.jpg") +
              //   require("assets/img/matsunaga/nagata6-min.png") +
              //   // require("assets/img/business-cool.jpg") +
              //   ")",
              backgroundColor: "#f8f9fa",
            }}
          >
            <animated.div
              style={{
                // transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
                transition: "all 800ms ease",
                // transitionDelay: "0.3s",
                transform: transytop.interpolate((p) => p),
                opacity: opa.interpolate((p) => p),
                position: "absolute",
                top: -110,
                left: 30,
              }}
            >
              <div
                style={{
                  position: "absolute",
                  // top: 200,
                  width: "70vw",
                }}
              >
                <div>
                  <h1
                    style={{
                      color: "black",
                      fontWeight: 700,
                      fontSize: 40,
                      letterSpacing: 3,
                      marginBottom: 40,
                      display: "inline-block",
                      position: "relative",
                    }}
                  >
                    GREETINGS
                    <span
                      style={{
                        // color: "black",
                        fontWeight: 300,
                        fontSize: 13,
                        position: "absolute",
                        right: 0,
                        bottom: "-1.5em",
                        letterSpacing: 1,
                        // paddingTop: 10,
                      }}
                    >
                      代表挨拶
                    </span>
                  </h1>
                </div>

                <p
                  style={{
                    color: "#C3B189",
                    fontWeight: 500,
                    fontSize: "7vw",
                    lineHeight: 1.6,
                    letterSpacing: 2,
                    paddingTop: 0,
                    marginBottom: 20,
                  }}
                >
                  一生涯の
                  <br />
                  お付き合いを
                </p>
                <p
                  style={{
                    color: "black",
                    fontSize: "4vw",
                    // fontWeight: 600,
                    letterSpacing: 1,
                    paddingTop: 0,
                  }}
                >
                  代表取締役社長　永田 悠介
                </p>
              </div>
            </animated.div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg="12" style={{ paddingTop: 50 }}>
          <animated.div
            style={{
              // transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
              transition: "all 800ms ease",
              // transitionDelay: "0.3s",
              transform: transytop.interpolate((p) => p),
              opacity: opa.interpolate((p) => p),
              top: 30,
              left: "5vw",
            }}
          >
            <p
              style={{
                color: "black",
                // fontWeight: 600,
                letterSpacing: 1,
                lineHeight: 2,
                paddingTop: 10,
              }}
            >
              はじめまして。 代表の永田と申します。
              わたしは元々、この業界とは別の業界に長年身を置いてきました。
              ご縁があり、この業界に飛びこみました。
              他業種から参入してみて感じることは、
              リフォーム業界はまだまだ未成熟、発展途上だということです。
              設備関連や外壁塗装等もそうですが、大がかりなリフォームなど、
              お客様からすると一生に一度やるかどうかの工事です。
              お客様はどこの業者に依頼すればいいのか、
              価格帯はどのくらいが適正なのか、様々な不安があるかと思います。
              本来は、プロである業者がお客様へ寄り添い手助けをするのがお仕事です。
              とても悲しいことですが、それにつけこみ手抜き工事、
              適当な工事をする業者がいることも事実です。
              他業種から来た私だからこそ、
              「作る・売る立場」ではなく、「使う・買う立場」に立ってよりお客様に寄り添える
              そんな会社でいたいと考えております。
              常にお客様目線で従業員一同お客様の手助けをさせて頂きます。
              どんなことでも相談してください。一生涯のお付き合いをさせて頂きます。
            </p>
          </animated.div>
        </Col>
      </Row>
    </Container>
  );
}

export function ContainerSMART1({ inview }) {
  // { opa, tra, clip }
  let { opa, flowright, flowleft, transxleft, transxright, transytop } =
    useSpring({
      opa: inview ? 1 : 0,
      flowright: inview
        ? "rect(0vh, 100vw, 100vh, 0vw)"
        : "rect(0vh, 0vw, 100vh, 0vw)",
      flowleft: inview
        ? "rect(0vh, 100vw, 100vh, 0vw)"
        : "rect(0vh, 100vw, 100vh, 100vw)",
      transxleft: inview ? "translate3d(0px, 0, 0)" : "translate3d(70px, 0, 0)",
      transxright: inview
        ? "translate3d(0px, 0, 0)"
        : "translate3d(-100px, 0, 0)",

      transytop: inview ? "translateY(0)" : "translateY(70px)",
    });

  return (
    <Container fluid className="pb-5">
      <Row>
        <Col lg="6">
          <animated.div
            style={{
              // transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
              left: "10vw",
              transition: "all 800ms ease",
              // transitionDelay: "0.3s",
              transform: transytop.interpolate((p) => p),
              opacity: opa.interpolate((p) => p),
            }}
          >
            <div
              style={{
                // position: "absolute",
                // top: "15vh",
                // left: "10vw",
                width: "100%",
                // width: "80vw",
                // height: "100vh",
                // backgroundColor: "white",
              }}
            >
              <div className="d-flex justify-content-center align-items-center h-100">
                <h1
                  style={{
                    color: "black",
                    fontWeight: 700,
                    fontSize: 40,
                    letterSpacing: 3,
                    marginBottom: 50,
                    display: "inline-block",
                    position: "relative",
                  }}
                >
                  GREETINGS
                  <span
                    style={{
                      // color: "black",
                      fontWeight: 300,
                      fontSize: 13,
                      position: "absolute",
                      right: 0,
                      bottom: "-1.5em",
                      letterSpacing: 1,
                      // paddingTop: 10,
                    }}
                  >
                    代表挨拶
                  </span>
                </h1>
              </div>
            </div>
          </animated.div>
        </Col>
        <Col lg="6">
          {/* <animated.div
            style={{
              // transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
              transition: "all 800ms ease",
              // transitionDelay: "0.3s",
              // transform: transxleft.interpolate((p) => p),
              // opacity: opa.interpolate((p) => p),
              // clip: flowleft.interpolate((p) => p),
              position: "fixed",
            }}
          > */}
          <div
            className="backgroundImage-80"
            style={{
              // position: "absolute",
              // left: "10vw",
              // top: "15vh",
              // width: "90vw",
              width: "100%",
              // height: "65vw",
              height: "5vw",
              // minHeight: "65vh",
              // maxHeight: "65vh",
              // backgroundImage:
              //   // "url(" + require("assets/img/envato/handshake2.jpg") + ")",
              //   "url(" +
              //   // require("assets/img/barista-preparing-cup-of-coffee-for-customer-in-co-P5XSB35.jpg") +
              //   require("assets/img/matsunaga/retouchIMG_2652-min.png") +
              //   // require("assets/img/business-cool.jpg") +
              //   ")",
              backgroundColor: "#f8f9fa",
            }}
          ></div>
          {/* </animated.div> */}
        </Col>
        <Col lg="6">
          <animated.div
            style={{
              // transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
              left: "10vw",
              transition: "all 800ms ease",
              // transitionDelay: "0.3s",
              transform: transytop.interpolate((p) => p),
              opacity: opa.interpolate((p) => p),
            }}
          >
            <div
              style={{
                // position: "absolute",
                // top: "15vh",
                // left: "10vw",
                width: "100%",
                // width: "80vw",
                // height: "100vh",
                // backgroundColor: "white",
              }}
            >
              {/* <div className="d-flex justify-content-center align-items-center h-100">
                <h1
                  style={{
                    color: "black",
                    fontWeight: 700,
                    fontSize: 40,
                    letterSpacing: 3,
                    marginBottom: 50,
                    display: "inline-block",
                    position: "relative",
                  }}
                >
                  GREETINGS
                  <span
                    style={{
                      // color: "black",
                      fontWeight: 300,
                      fontSize: 13,
                      position: "absolute",
                      right: 0,
                      bottom: "-1.5em",
                      letterSpacing: 1,
                      // paddingTop: 10,
                    }}
                  >
                    代表挨拶
                  </span>
                </h1>
              </div> */}
              <div className="d-flex justify-content-center align-items-center h-100">
                <p
                  style={{
                    color: "#C3B189",
                    fontWeight: 500,
                    fontSize: 26,
                    lineHeight: 1.6,
                    letterSpacing: 2,
                    paddingTop: 10,
                    marginBottom: 20,
                  }}
                >
                  {/* 「一生涯のお付き合いを」 */}
                  一生涯のお付き合いを
                </p>
              </div>

              <p
                style={{
                  color: "black",
                  // fontWeight: 600,
                  letterSpacing: 1,
                  // fontSize: "5vw",
                  paddingTop: 10,
                }}
              >
                はじめまして。
                <br />
                代表の永田と申します。
                <br />
                わたしは元々、この業界とは別の業界に長年身を置いてきました。
                <br />
                ご縁があり、この業界に飛びこみました。
                <br />
                他業種から参入してみて感じることは、
                <br />
                リフォーム業界はまだまだ未成熟、発展途上だということです。
                <br />
                設備関連や外壁塗装等もそうですが、大がかりなリフォームなど、
                <br />
                お客様からすると一生に一度やるかどうかの工事です。
                <br />
                お客様はどこの業者に依頼すればいいのか、
                <br />
                価格帯はどのくらいが適正なのか、様々な不安があるかと思います。
                <br />
                本来は、プロである業者がお客様へ寄り添い手助けをするのがお仕事です。
                <br />
                とても悲しいことですが、それにつけこみ手抜き工事、適当な工事をする業者がいることも事実です。
                <br />
                他業種から来た私だからこそ、
                <br />
                「作る・売る立場」ではなく、「使う・買う立場」に立ってよりお客様に寄り添える
                <br />
                そんな会社でいたいと考えております。
                <br />
                常にお客様目線で従業員一同お客様の手助けをさせて頂きます。
                <br />
                どんなことでも相談してください。一生涯のお付き合いをさせて頂きます。
                <br />
              </p>
            </div>
          </animated.div>
        </Col>
      </Row>
    </Container>
  );
}

export function ContainerSMART2({ inview }) {
  // { opa, tra, clip }
  let { opa, flowright, flowleft, transxleft, transxright, transytop } =
    useSpring({
      opa: inview ? 1 : 0,
      flowright: inview
        ? "rect(0vh, 100vw, 100vh, 0vw)"
        : "rect(0vh, 0vw, 100vh, 0vw)",
      flowleft: inview
        ? "rect(0vh, 100vw, 100vh, 0vw)"
        : "rect(0vh, 100vw, 100vh, 100vw)",
      transxleft: inview ? "translate3d(0px, 0, 0)" : "translate3d(70px, 0, 0)",
      transxright: inview
        ? "translate3d(0px, 0, 0)"
        : "translate3d(-100px, 0, 0)",

      transytop: inview ? "translateY(0)" : "translateY(70px)",
    });

  return (
    <Container fluid className="pb-5">
      <Row>
        <Col lg="6">
          <animated.div
            style={{
              // transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
              left: "10vw",
              transition: "all 800ms ease",
              // transitionDelay: "0.3s",
              transform: transytop.interpolate((p) => p),
              opacity: opa.interpolate((p) => p),
            }}
          >
            <div
              style={{
                // position: "absolute",
                // top: "15vh",
                // left: "10vw",
                // width: "80vw",
                width: "100%",
                // height: "100vh",
                // backgroundColor: "white",
              }}
            >
              <div className="d-flex justify-content-center align-items-center h-100">
                <h1
                  style={{
                    color: "black",
                    fontWeight: 700,
                    fontSize: 40,
                    letterSpacing: 3,
                    marginBottom: 50,
                    display: "inline-block",
                    position: "relative",
                  }}
                >
                  PHILOSOPHY
                  <span
                    style={{
                      // color: "black",
                      fontWeight: 300,
                      fontSize: 13,
                      position: "absolute",
                      right: 0,
                      bottom: "-1.5em",
                      letterSpacing: 1,
                      // paddingTop: 10,
                    }}
                  >
                    企業理念
                  </span>
                </h1>
              </div>
            </div>
          </animated.div>
        </Col>
        <Col lg="6">
          {/* <animated.div
            style={{
              // transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
              transition: "all 800ms ease",
              // transitionDelay: "0.3s",
              // transform: transxleft.interpolate((p) => p),
              // opacity: opa.interpolate((p) => p),
              // clip: flowleft.interpolate((p) => p),
              position: "fixed",
            }}
          > */}
          <div
            className="backgroundImage-80"
            style={{
              // position: "absolute",
              // left: "10vw",
              // top: "15vh",
              // width: "90vw",
              width: "100%",
              height: "50vh",
              // minHeight: "65vh",
              // maxHeight: "65vh",
              backgroundImage:
                // "url(" + require("assets/img/envato/handshake2.jpg") + ")",
                "url(" +
                // require("assets/img/barista-preparing-cup-of-coffee-for-customer-in-co-P5XSB35.jpg") +
                require("assets/img/envato/windows-skyscraper-business-reflect-office-corpora-PGZWL9F-min.jpg") +
                // require("assets/img/business-cool.jpg") +
                ")",
            }}
          ></div>
          {/* </animated.div> */}
        </Col>
        <Col lg="6">
          <animated.div
            style={{
              // transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
              left: "10vw",
              transition: "all 800ms ease",
              // transitionDelay: "0.3s",
              transform: transytop.interpolate((p) => p),
              opacity: opa.interpolate((p) => p),
            }}
          >
            <div
              style={{
                // position: "absolute",
                // top: "15vh",
                // left: "10vw",
                // width: "80vw",
                width: "100%",
                // height: "100vh",
                // backgroundColor: "white",
              }}
            >
              {/* <div className="d-flex justify-content-center align-items-center h-100">
                <h1
                  style={{
                    color: "black",
                    fontWeight: 700,
                    fontSize: 40,
                    letterSpacing: 3,
                    marginBottom: 50,
                    display: "inline-block",
                    position: "relative",
                  }}
                >
                  PHILOSOPHY
                  <span
                    style={{
                      // color: "black",
                      fontWeight: 300,
                      fontSize: 13,
                      position: "absolute",
                      right: 0,
                      bottom: "-1.5em",
                      letterSpacing: 1,
                      // paddingTop: 10,
                    }}
                  >
                    企業理念
                  </span>
                </h1>
              </div> */}
              <div className="d-flex justify-content-center align-items-center h-100">
                <p
                  style={{
                    color: "#C3B189",
                    fontWeight: 500,
                    fontSize: 26,
                    textAlign: "center",
                    // fontSize: 16,
                    lineHeight: 1.6,
                    letterSpacing: 2,
                    paddingTop: 10,
                    marginBottom: 20,
                  }}
                >
                  {/* 「関わるすべての人に愛される企業に」 */}
                  住まいの提案を通じて
                  <br />
                  四方良しを実現する
                </p>
              </div>

              <p
                style={{
                  color: "black",
                  // fontWeight: 600,
                  letterSpacing: 1,
                  paddingTop: 10,
                }}
              >
                お客様、社員とその家族、お取引先様と一生涯のパートナー
                になれるような会社作りを目指します。
                お客様、地域の皆様へ住まいの提案を通じて自社のサービス
                を提供し暮らしの豊かさを提案します。
                又、より良いサービスを提供することでお客様から支持され、
                結果、会社と社員が成長することでより良い労働環境、人間関
                係の構築を実現し社員とその家族が物心両面で豊かな人生を送
                れるよう努力します。
                商社様や協力業者様へも末永い関係が構築できるように会社を
                発展させ共に成長していけるように日々の営業活動に励み永続
                する企業を作ります。
              </p>
            </div>
          </animated.div>
        </Col>
      </Row>
    </Container>
  );
}

export function ContainerSMART3({ inview }) {
  // { opa, tra, clip }
  let { opa, flowright, flowleft, transxleft, transxright, transytop } =
    useSpring({
      opa: inview ? 1 : 0,
      flowright: inview
        ? "rect(0vh, 100vw, 100vh, 0vw)"
        : "rect(0vh, 0vw, 100vh, 0vw)",
      flowleft: inview
        ? "rect(0vh, 100vw, 100vh, 0vw)"
        : "rect(0vh, 100vw, 100vh, 100vw)",
      transxleft: inview ? "translate3d(0px, 0, 0)" : "translate3d(70px, 0, 0)",
      transxright: inview
        ? "translate3d(0px, 0, 0)"
        : "translate3d(-100px, 0, 0)",

      transytop: inview ? "translateY(0)" : "translateY(70px)",
    });

  return (
    <Container fluid className="pb-5">
      <Row>
        <Col lg="6">
          <animated.div
            style={{
              // transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
              left: "10vw",
              transition: "all 800ms ease",
              // transitionDelay: "0.3s",
              transform: transytop.interpolate((p) => p),
              opacity: opa.interpolate((p) => p),
            }}
          >
            <div
              style={{
                // position: "absolute",
                // top: "15vh",
                // left: "10vw",
                // width: "80vw",
                width: "100%",
                // height: "100vh",
                // backgroundColor: "white",
              }}
            >
              <div className="d-flex justify-content-center align-items-center h-100">
                <h1
                  style={{
                    color: "black",
                    fontWeight: 700,
                    fontSize: 40,
                    letterSpacing: 3,
                    marginBottom: 50,
                    display: "inline-block",
                    position: "relative",
                  }}
                >
                  POLICY
                  <span
                    style={{
                      // color: "black",
                      fontWeight: 300,
                      fontSize: 13,
                      position: "absolute",
                      right: 0,
                      bottom: "-1.5em",
                      letterSpacing: 1,
                      // paddingTop: 10,
                    }}
                  >
                    企業精神
                  </span>
                </h1>
              </div>
              {/* <div style={{ overflow: "hidden" }}>
                <animated.div
                  style={{
                    transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
                    // transition: "all 800ms ease",
                    // transitionDelay: "0.3s",
                    // transform: transxleft.interpolate((p) => p),
                    transform: transytop.interpolate((p) => p),
                    opacity: opa.interpolate((p) => p),
                  }}
                >
                  <h4
                    className="title mb-1"
                    style={{
                      paddingLeft: "1.5em",
                      textIndent: "-1.5em",
                      // padding-left:1em;
                      // text-indent:-1em;
                    }}
                  >
                    １.
                    お客様に喜ばれる提案・商品・工事・サービスを提供し、その為には
                    <span className="under">一切の妥協はしない</span>
                    <p
                      className="description pt-4"
                      style={{
                        textIndent: 0,
                      }}
                    >
                      我々は作る・売る立場でなく使う・買う立場に立って考えます。
                    </p>
                  </h4>
                </animated.div>
              </div>

              <div style={{ overflow: "hidden" }}>
                <animated.div
                  style={{
                    transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
                    // transition: "all 800ms ease",
                    transitionDelay: "0.5s",
                    // transform: transxleft.interpolate((p) => p),
                    transform: transytop.interpolate((p) => p),
                    opacity: opa.interpolate((p) => p),
                  }}
                >
                  <h4
                    className="title mb-1"
                    style={{
                      paddingLeft: "1.5em",
                      textIndent: "-1.5em",
                      // padding-left:1em;
                      // text-indent:-1em;
                    }}
                  >
                    ２. お客様と
                    <span className="under">一生涯</span>
                    のパートナーシップを
                    <p
                      className="description pt-4"
                      style={{
                        textIndent: 0,
                      }}
                    >
                      人の病院はありますが、お家の病院はありません。一度工事したら終わり、ではなく何かお困り事があれば、今後松永興業株式会社にご相談頂ける様、安心・信頼を頂ける企業を目指します。
                    </p>
                  </h4>
                </animated.div>
              </div>

              <div style={{ overflow: "hidden" }}>
                <animated.div
                  style={{
                    transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
                    // transition: "all 800ms ease",
                    transitionDelay: "0.5s",
                    // transform: transxleft.interpolate((p) => p),
                    transform: transytop.interpolate((p) => p),
                    opacity: opa.interpolate((p) => p),
                  }}
                >
                  <h4
                    className="title mb-1"
                    style={{
                      paddingLeft: "1.5em",
                      textIndent: "-1.5em",
                      // padding-left:1em;
                      // text-indent:-1em;
                    }}
                  >
                    ３. 仕事に誇りを持ち、
                    <span className="under">正々堂々誠実</span>に
                    <p
                      className="description pt-4"
                      style={{
                        textIndent: 0,
                      }}
                    >
                      お客様から安心信頼を頂くためには、まず我々自身が自分に恥じない仕事をしなければなりません。我々は全員が目の前の仕事に誠実に、一生懸命打ち込むことを約束します。
                    </p>
                  </h4>
                </animated.div>
              </div>
              <div style={{ overflow: "hidden" }}>
                <animated.div
                  style={{
                    transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
                    // transition: "all 800ms ease",
                    transitionDelay: "1.0s",
                    // transform: transxleft.interpolate((p) => p),
                    transform: transytop.interpolate((p) => p),
                    opacity: opa.interpolate((p) => p),
                  }}
                >
                  <h4
                    className="title mb-1"
                    style={{
                      paddingLeft: "1.5em",
                      textIndent: "-1.5em",
                      // padding-left:1em;
                      // text-indent:-1em;
                    }}
                  >
                    ４. <span className="under">成長欲求</span>と
                    <span className="under">チャレンジ精神</span>をもつ
                    <p
                      className="description pt-4"
                      style={{
                        textIndent: 0,
                      }}
                    >
                      現状維持はマイナス。
                    </p>
                  </h4>

                  <p className="description"></p>
                </animated.div>
              </div>
              <div style={{ overflow: "hidden" }}>
                <animated.div
                  style={{
                    transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
                    // transition: "all 800ms ease",
                    transitionDelay: "1.5s",
                    // transform: transxleft.interpolate((p) => p),
                    transform: transytop.interpolate((p) => p),
                    opacity: opa.interpolate((p) => p),
                  }}
                >
                  <h4
                    className="title mb-1"
                    style={{
                      paddingLeft: "1.5em",
                      textIndent: "-1.5em",
                      // padding-left:1em;
                      // text-indent:-1em;
                    }}
                  >
                    ５. <span className="under">社会貢献</span>
                    を意識する
                    <p
                      className="description pt-4"
                      style={{
                        textIndent: 0,
                      }}
                    >
                      会社を成長させていくことでお客様へ提供出来るサービスの幅や質を高めていきます。また、日々当たり前のように仕事が出来るのは、地域の方々、お客様、協力業者様、家族、親からの協力があってのことです。どんな形であれ、全社員が感謝の気持ちを忘れずに仕事に励みます。
                    </p>
                  </h4>
                </animated.div>
              </div> */}
            </div>
          </animated.div>
        </Col>
        <Col lg="6">
          {/* <animated.div
            style={{
              // transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
              transition: "all 800ms ease",
              // transitionDelay: "0.3s",
              // transform: transxleft.interpolate((p) => p),
              // opacity: opa.interpolate((p) => p),
              // clip: flowleft.interpolate((p) => p),
              position: "fixed",
            }}
          > */}
          <div
            className="backgroundImage-80"
            style={{
              // position: "absolute",
              // left: "10vw",
              // top: "15vh",
              // width: "90vw",
              width: "100%",
              height: "50vh",
              // minHeight: "65vh",
              // maxHeight: "65vh",
              backgroundImage:
                // "url(" + require("assets/img/envato/handshake2.jpg") + ")",
                "url(" +
                // require("assets/img/barista-preparing-cup-of-coffee-for-customer-in-co-P5XSB35.jpg") +
                require("assets/img/envato/men-suites-pants-dress-shoes-street-sidewalk-min.jpg") +
                // require("assets/img/business-cool.jpg") +
                ")",
            }}
          ></div>
          {/* </animated.div> */}
        </Col>
        <Col lg="6">
          <animated.div
            style={{
              // transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
              left: "10vw",
              transition: "all 800ms ease",
              // transitionDelay: "0.3s",
              transform: transytop.interpolate((p) => p),
              opacity: opa.interpolate((p) => p),
            }}
          >
            <div
              style={{
                // position: "absolute",
                // top: "15vh",
                // left: "10vw",
                // width: "80vw",
                width: "100%",
                // height: "100vh",
                // backgroundColor: "white",
              }}
            >
              {/* <div className="d-flex justify-content-center align-items-center h-100">
                <h1
                  style={{
                    color: "black",
                    fontWeight: 700,
                    fontSize: 40,
                    letterSpacing: 3,
                    marginBottom: 50,
                    display: "inline-block",
                    position: "relative",
                  }}
                >
                  POLICY
                  <span
                    style={{
                      // color: "black",
                      fontWeight: 300,
                      fontSize: 13,
                      position: "absolute",
                      right: 0,
                      bottom: "-1.5em",
                      letterSpacing: 1,
                      // paddingTop: 10,
                    }}
                  >
                    企業精神
                  </span>
                </h1>
              </div> */}
              <div style={{ overflow: "hidden" }}>
                <animated.div
                  style={{
                    transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
                    // transition: "all 800ms ease",
                    // transitionDelay: "0.3s",
                    // transform: transxleft.interpolate((p) => p),
                    transform: transytop.interpolate((p) => p),
                    opacity: opa.interpolate((p) => p),
                  }}
                >
                  <h4
                    className="title mb-1"
                    style={{
                      paddingLeft: "1.5em",
                      textIndent: "-1.5em",
                      // padding-left:1em;
                      // text-indent:-1em;
                    }}
                  >
                    １.
                    お客様に喜ばれる提案・商品・工事・サービスを提供し、その為には
                    <span className="under">一切の妥協はしない</span>
                    <p
                      className="description pt-4"
                      style={{
                        textIndent: 0,
                      }}
                    >
                      我々は作る・売る立場でなく使う・買う立場に立って考えます。
                    </p>
                  </h4>
                </animated.div>
              </div>

              <div style={{ overflow: "hidden" }}>
                <animated.div
                  style={{
                    transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
                    // transition: "all 800ms ease",
                    transitionDelay: "0.5s",
                    // transform: transxleft.interpolate((p) => p),
                    transform: transytop.interpolate((p) => p),
                    opacity: opa.interpolate((p) => p),
                  }}
                >
                  <h4
                    className="title mb-1"
                    style={{
                      paddingLeft: "1.5em",
                      textIndent: "-1.5em",
                      // padding-left:1em;
                      // text-indent:-1em;
                    }}
                  >
                    ２. お客様と
                    <span className="under">一生涯</span>
                    のパートナーシップを
                    <p
                      className="description pt-4"
                      style={{
                        textIndent: 0,
                      }}
                    >
                      人の病院はありますが、お家の病院はありません。一度工事したら終わり、ではなく何かお困り事があれば、今後松永興業株式会社にご相談頂ける様、安心・信頼を頂ける企業を目指します。
                    </p>
                  </h4>
                </animated.div>
              </div>

              <div style={{ overflow: "hidden" }}>
                <animated.div
                  style={{
                    transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
                    // transition: "all 800ms ease",
                    transitionDelay: "0.5s",
                    // transform: transxleft.interpolate((p) => p),
                    transform: transytop.interpolate((p) => p),
                    opacity: opa.interpolate((p) => p),
                  }}
                >
                  <h4
                    className="title mb-1"
                    style={{
                      paddingLeft: "1.5em",
                      textIndent: "-1.5em",
                      // padding-left:1em;
                      // text-indent:-1em;
                    }}
                  >
                    ３. 仕事に誇りを持ち、
                    <span className="under">正々堂々誠実</span>に
                    <p
                      className="description pt-4"
                      style={{
                        textIndent: 0,
                      }}
                    >
                      お客様から安心信頼を頂くためには、まず我々自身が自分に恥じない仕事をしなければなりません。我々は全員が目の前の仕事に誠実に、一生懸命打ち込むことを約束します。
                    </p>
                  </h4>
                </animated.div>
              </div>
              <div style={{ overflow: "hidden" }}>
                <animated.div
                  style={{
                    transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
                    // transition: "all 800ms ease",
                    transitionDelay: "1.0s",
                    // transform: transxleft.interpolate((p) => p),
                    transform: transytop.interpolate((p) => p),
                    opacity: opa.interpolate((p) => p),
                  }}
                >
                  <h4
                    className="title mb-1"
                    style={{
                      paddingLeft: "1.5em",
                      textIndent: "-1.5em",
                      // padding-left:1em;
                      // text-indent:-1em;
                    }}
                  >
                    ４. <span className="under">成長欲求</span>と
                    <span className="under">チャレンジ精神</span>をもつ
                    <p
                      className="description pt-4"
                      style={{
                        textIndent: 0,
                      }}
                    >
                      現状維持はマイナス。
                    </p>
                  </h4>

                  <p className="description"></p>
                </animated.div>
              </div>
              <div style={{ overflow: "hidden" }}>
                <animated.div
                  style={{
                    transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
                    // transition: "all 800ms ease",
                    transitionDelay: "1.5s",
                    // transform: transxleft.interpolate((p) => p),
                    transform: transytop.interpolate((p) => p),
                    opacity: opa.interpolate((p) => p),
                  }}
                >
                  <h4
                    className="title mb-1"
                    style={{
                      paddingLeft: "1.5em",
                      textIndent: "-1.5em",
                      // padding-left:1em;
                      // text-indent:-1em;
                    }}
                  >
                    ５. <span className="under">社会貢献</span>
                    を意識する
                    <p
                      className="description pt-4"
                      style={{
                        textIndent: 0,
                      }}
                    >
                      会社を成長させていくことでお客様へ提供出来るサービスの幅や質を高めていきます。また、日々当たり前のように仕事が出来るのは、地域の方々、お客様、協力業者様、家族、親からの協力があってのことです。どんな形であれ、全社員が感謝の気持ちを忘れずに仕事に励みます。
                    </p>
                  </h4>
                </animated.div>
              </div>
            </div>
          </animated.div>
        </Col>
      </Row>
    </Container>
  );
}

export function ContainerSMART4({ inview }) {
  // { opa, tra, clip }
  let { opa, flowright, flowleft, transxleft, transxright, transytop } =
    useSpring({
      opa: inview ? 1 : 0,
      flowright: inview
        ? "rect(0vh, 100vw, 100vh, 0vw)"
        : "rect(0vh, 0vw, 100vh, 0vw)",
      flowleft: inview
        ? "rect(0vh, 100vw, 100vh, 0vw)"
        : "rect(0vh, 100vw, 100vh, 100vw)",
      transxleft: inview ? "translate3d(0px, 0, 0)" : "translate3d(70px, 0, 0)",
      transxright: inview
        ? "translate3d(0px, 0, 0)"
        : "translate3d(-100px, 0, 0)",

      transytop: inview ? "translateY(0)" : "translateY(70px)",
    });

  return (
    <Container fluid className="pb-5">
      <Row>
        <Col lg="6">
          <animated.div
            style={{
              // transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
              left: "10vw",
              transition: "all 800ms ease",
              // transitionDelay: "0.3s",
              transform: transytop.interpolate((p) => p),
              opacity: opa.interpolate((p) => p),
            }}
          >
            <div
              style={{
                // position: "absolute",
                // top: "15vh",
                // left: "10vw",
                // width: "80vw",
                width: "100%",
                // height: "100vh",
                // backgroundColor: "white",
              }}
            >
              <div className="d-flex justify-content-center align-items-center h-100">
                <h1
                  style={{
                    color: "black",
                    fontWeight: 700,
                    fontSize: 40,
                    letterSpacing: 3,
                    marginBottom: 50,
                    display: "inline-block",
                    position: "relative",
                  }}
                >
                  GUIDELINES
                  <span
                    style={{
                      // color: "black",
                      fontWeight: 300,
                      fontSize: 13,
                      position: "absolute",
                      right: 0,
                      bottom: "-1.5em",
                      letterSpacing: 1,
                      // paddingTop: 10,
                    }}
                  >
                    行動指針
                  </span>
                </h1>
              </div>
              {/* <h4 className="title mb-0">１. 礼儀正しく誠実に</h4>
              <h4 className="title mb-0">
                ２. 情熱と執念をもって仕事に打ち込む
              </h4>
              <h4 className="title mb-0">３. 感謝と敬意の気持ちを忘れない</h4>
              <h4 className="title ">４. 成長意欲とチャレンジ精神をもつ</h4>
              <p className="description" style={{ color: "black" }}>
                松永興業株式会社の社員は、上記の行動指針を守り企業の発展に邁進します。
              </p> */}
            </div>
          </animated.div>
        </Col>
        <Col lg="6">
          {/* <animated.div
            style={{
              // transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
              transition: "all 800ms ease",
              // transitionDelay: "0.3s",
              // transform: transxleft.interpolate((p) => p),
              // opacity: opa.interpolate((p) => p),
              // clip: flowleft.interpolate((p) => p),
              position: "fixed",
            }}
          > */}
          <div
            className="backgroundImage-80"
            style={{
              // position: "absolute",
              // left: "10vw",
              // top: "15vh",
              // width: "90vw",
              width: "100%",
              height: "50vh",
              // minHeight: "65vh",
              // maxHeight: "65vh",
              backgroundImage:
                // "url(" + require("assets/img/envato/handshake2.jpg") + ")",
                "url(" +
                // require("assets/img/barista-preparing-cup-of-coffee-for-customer-in-co-P5XSB35.jpg") +
                require("assets/img/envato/islamic-friends-hands-stack-for-teamwork-W86SFUQ-min.jpg") +
                // require("assets/img/business-cool.jpg") +
                ")",
            }}
          ></div>
          {/* </animated.div> */}
        </Col>
        <Col lg="6">
          <animated.div
            style={{
              // transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
              left: "10vw",
              transition: "all 800ms ease",
              // transitionDelay: "0.3s",
              transform: transytop.interpolate((p) => p),
              opacity: opa.interpolate((p) => p),
            }}
          >
            <div
              style={{
                // position: "absolute",
                // top: "15vh",
                // left: "10vw",
                // width: "80vw",
                width: "100%",
                // height: "100vh",
                // backgroundColor: "white",
              }}
            >
              {/* <div className="d-flex justify-content-center align-items-center h-100">
                <h1
                  style={{
                    color: "black",
                    fontWeight: 700,
                    fontSize: 40,
                    letterSpacing: 3,
                    marginBottom: 50,
                    display: "inline-block",
                    position: "relative",
                  }}
                >
                  GUIDELINES
                  <span
                    style={{
                      // color: "black",
                      fontWeight: 300,
                      fontSize: 13,
                      position: "absolute",
                      right: 0,
                      bottom: "-1.5em",
                      letterSpacing: 1,
                      // paddingTop: 10,
                    }}
                  >
                    行動指針
                  </span>
                </h1>
              </div> */}
              <h4 className="title mb-0">１. 礼儀正しく誠実に</h4>
              <h4 className="title mb-0">
                ２. 情熱と執念をもって仕事に打ち込む
              </h4>
              <h4 className="title mb-0">３. 感謝と敬意の気持ちを忘れない</h4>
              <h4 className="title ">４. 成長意欲とチャレンジ精神をもつ</h4>
              <p className="description" style={{ color: "black" }}>
                松永興業株式会社の社員は、上記の行動指針を守り企業の発展に邁進します。
                {/* 上記は、一企業人の立ち振る舞いとして必要な考え方になります。松永興業株式会社は全社員が上記の行動指針をセンターピンとして日々の業務にあたります。 */}
              </p>
            </div>
          </animated.div>
        </Col>
      </Row>
    </Container>
  );
}
