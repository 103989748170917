import React, { FC, useEffect, useRef, useState } from "react";
import ReactTable from "react-table";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { initReactI18next, useTranslation } from "react-i18next";

const AppContainer = ({ description, before, after }) => {
  const [t, i18n] = useTranslation();
  const dispatch = useDispatch();
  const ref = useRef();

  return (
    <>
      <div className="d-flex justify-content-center align-items-center">
        <div
          className="moimg1 card-mask card-horizon w-50"
          style={{
            position: "relative",
          }}
        >
          {/* <div
            className="d-flex justify-content-center align-items-center"
            style={{ width: "100%", height: "50vh", overflow: "hidden" }}
          >
            <img
              alt="..."
              className="img"
              src={before}
              style={{ width: "100%" }}
              // imgはインラインだけどinline-blockみたいなもの例外width: "100%"が必要
            />
          </div> */}

          <div
          id="bedd"
            className="d-flex justify-content-center align-items-center divimg"
            style={{
              width: "100%",
              height: "50vh",
              backgroundImage: `url(${before})`,
              backgroundPosition: "center center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          ></div>

          <div className="mask d-flex justify-content-center align-items-center">
            <div className="caption">Before</div>
          </div>
          <span
            style={{
              position: "absolute",
              left: 0,
              bottom: 0,
              backgroundColor: "#ababab",
              color: "#fff",
              width: 93,
              height: 30,
              textAlign: "center",
              lineHeight: "30px",
              // display:"block"
              fontWeight: 600,
              letterSpacing: 2,
            }}
          >
            Before
          </span>
          {/* <span
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.45)",
              color: "#fff",
              width: 30,
              height: 30,
              textAlign: "center",
              lineHeight: "30px",
              fontWeight: 600,
              letterSpacing: 2,
            }}
          >
            <i class="fas fa-search-plus"></i>
            Before
          </span> */}
        </div>

        <div
          className="moimg1 card-mask card-horizon w-50"
          style={{
            position: "relative",
          }}
        >
          {/* <div
            className="d-flex justify-content-center align-items-center"
            style={{ width: "100%", height: "50vh", overflow: "hidden" }}
          >
            <img
              alt="..."
              className="img"
              src={after}
              style={{ width: "100%", verticalAlign: "middle" }}
            />
          </div> */}

          <div
            className="d-flex justify-content-center align-items-center divimg"
            style={{
              width: "100%",
              height: "50vh",
              backgroundImage: `url(${after})`,
              // backgroundImage: `url(${require("assets/img/envato/back1-min.jpg")})`,

              backgroundPosition: "center center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          ></div>

          <div className="mask d-flex justify-content-center align-items-center">
            <div className="caption">After</div>
          </div>
          <span
            style={{
              position: "absolute",
              left: 0,
              bottom: 0,
              backgroundColor: "#C3B189",
              color: "#fff",
              width: 93,
              height: 30,
              textAlign: "center",
              lineHeight: "30px",
              // display:"block"
              fontWeight: 600,
              letterSpacing: 2,
            }}
          >
            After
          </span>
        </div>
      </div>
      <p
        style={{
          textAlign: "center",
          height: 40,
          lineHeight: "40px",
          backgroundColor: "#302f2f",
          color: "#fff",
        }}
      >
        {description}
        <i
          className="fas fa-search-plus"
          style={{ fontSize: 17, verticalAlign: "text-top", paddingLeft: 5 }}
        />
      </p>
    </>
  );
};
export default AppContainer;
