import React from "react";
import {
  Animate,
  AnimateGroup,
  useAnimateGroup,
  AnimateKeyframes,
} from "react-simple-animate";
import { CSSTransition } from "react-transition-group";
import { initReactI18next, useTranslation } from "react-i18next";

export function LetterFlowVertLeft({ color, str }) {
  const [t, i18n] = useTranslation();

  return (
    <>
      {/* <LetterArea
        opacity={1}
        clipPath="polygon(0 0, 10% 0, 10% 100%, 0 100%)"
        color={color}
        str={str}
      /> */}
      {/* <div className="rotate-letter"> */}

      <LetterAreaLeft
        opacity={0.1}
        clipPath="polygon(0% 0, 100vh 0, 100vh 100%, 0% 100%)"
        color={color}
        str={str}
      />
      {/* <LetterArea
        opacity={1}
        clipPath="polygon(50% 0, 100% 0, 100% 100%, 50% 100%)"
        color={color}
        str={str}
      /> */}
    </>
  );
}
export function LetterFlowVertLeft2({ color, str }) {
  const [t, i18n] = useTranslation();

  return (
    <>
      {/* <LetterArea
        opacity={1}
        clipPath="polygon(0 0, 10% 0, 10% 100%, 0 100%)"
        color={color}
        str={str}
      /> */}
      {/* <div className="rotate-letter"> */}

      <LetterAreaLeft2
        opacity={0.1}
        clipPath="polygon(0% 0, 100vh 0, 100vh 100%, 0% 100%)"
        color={color}
        str={str}
      />
      {/* <LetterArea
        opacity={1}
        clipPath="polygon(50% 0, 100% 0, 100% 100%, 50% 100%)"
        color={color}
        str={str}
      /> */}
    </>
  );
}



export function LetterFlowVertRight({ color, str }) {
  const [t, i18n] = useTranslation();

  return (
    <>
      {/* <LetterArea
        opacity={1}
        clipPath="polygon(0 0, 10% 0, 10% 100%, 0 100%)"
        color={color}
        str={str}
      /> */}
      {/* <div className="rotate-letter"> */}

      <LetterAreaRight
        opacity={0.1}
        clipPath="polygon(0% 0, 100vh 0, 100vh 100%, 0% 100%)"
        color={color}
        str={str}
      />
      {/* <LetterArea
        opacity={1}
        clipPath="polygon(50% 0, 100% 0, 100% 100%, 50% 100%)"
        color={color}
        str={str}
      /> */}
    </>
  );
}


function LetterAreaRight({ opacity, clipPath, color, str }) {
  return (
    <div
      style={{
        // WebkitClipPath: clipPath,
        // clipPath: clipPath,
        // overflow: "hidden",
        height: "10vw",
        width: "100vh",
        position: "absolute",
        top: 0,
        // top: "50vh",
        bottom: 0,
        right: "10vw",
        // transform: "rotate(10deg)",
        transform: "rotate(-90deg)",
        transformOrigin: "right top",
        // backgroundColor: "yellow",

        // left: "50vh",
        // right: 0,
      }}
    >
      <AnimateKeyframes
        play={true}
        pause={false}
        easeType="linear"
        iterationCount="infinite"
        // direction="alternate"
        duration={15}
        // duration={4}
        // keyframes={[
        //   "right: 0; transform: translate(-100%)",
        //   "right: 100%; transform: translate(0)",
        // ]}
        // keyframes={[
        //   "transform: translate(+100%); opacity:0",
        //   "transform: translate(+75%); opacity:1",
        //   "transform: translate(+50%); opacity:1",
        //   "transform: translate(+25%); opacity:1",
        //   "transform: translate(0); opacity:0",
        // ]}
        keyframes={[
          "transform: translate(-200%); opacity:0",
          "transform: translate(-150%); opacity:1",
          "transform: translate(-100%); opacity:1",
          "transform: translate(-50%); opacity:1",
          "transform: translate(0); opacity:0",
        ]}
      >
      <p
        style={{
          // fontSize: 137,
          fontSize: "9vw",
          fontFamily: "Montserrat, sans-serif",
          fontWeight: 600,
          lineHeight: 1,
          letterSpacing: 0,
          WebkitTextStrokeColor: "#E5E5E5",
          WebkitTextStrokeWidth: 2,
          color,
          // opacity,
          whiteSpace: "nowrap",

          // left:-30
        }}
      >
        {str}
      </p>
      </AnimateKeyframes>
    </div>
  );
}



function LetterAreaLeft({ opacity, clipPath, color, str }) {
  return (
    <div
      style={{
        // WebkitClipPath: clipPath,
        // clipPath: clipPath,
        // overflow: "hidden",
        height: "10vw",
        width: "100vh",
        position: "absolute",
        top: 0,
        // top: "50vh",
        bottom: 0,
        left: "10vw",
        transform: "rotate(90deg)",
        transformOrigin: "left top",
        // backgroundColor: "yellow",

        // left: "50vh",
        // right: 0,
      }}
    >
      <AnimateKeyframes
        play={true}
        pause={false}
        easeType="linear"
        iterationCount="infinite"
        // direction="alternate"
        duration={15}
        // duration={8}
        // duration={4}
        // keyframes={[
        //   "left: 100%; transform: translate(0)",
        //   "left: 0; transform: translate(-100%)",
        // ]}
        // keyframes={[
        //   "transform: translate(+100%); opacity:0",
        //   "transform: translate(+75%); opacity:1",
        //   "transform: translate(+50%); opacity:1",
        //   "transform: translate(+25%); opacity:1",
        //   "transform: translate(0); opacity:0",
        // ]}
        // keyframes={[
        //   "transform: translate(+200%); opacity:0",
        //   "transform: translate(+150%); opacity:1",
        //   "transform: translate(+100%); opacity:1",
        //   "transform: translate(+50%); opacity:1",
        //   "transform: translate(0); opacity:0",
        // ]}
        keyframes={[
          "transform: translate(+100%); opacity:0",
          "transform: translate(+50%); opacity:1",
          "transform: translate(+0%); opacity:1",
          "transform: translate(-50%); opacity:1",
          "transform: translate(-100%); opacity:0",
        ]}
      >
      <p
        style={{
          // fontSize: 137,
          fontSize: "9vw",
          fontFamily: "Montserrat, sans-serif",
          fontWeight: 600,
          lineHeight: 1,
          letterSpacing: 0,
          WebkitTextStrokeColor: "#E5E5E5",
          WebkitTextStrokeWidth: 2,
          color,
          // opacity,
          whiteSpace: "nowrap",
        }}
      >
        {str}
      </p>
      </AnimateKeyframes>
    </div>
  );
}



function LetterAreaLeft2({ opacity, clipPath, color, str }) {
  return (
    <div
      style={{
        // WebkitClipPath: clipPath,
        // clipPath: clipPath,
        // overflow: "hidden",
        height: "10vw",
        width: "100vh",
        position: "absolute",
        top: 0,
        // top: "50vh",
        bottom: 0,
        left: "30vw",
        transform: "rotate(90deg)",
        transformOrigin: "left top",
        // backgroundColor: "yellow",

        // left: "50vh",
        // right: 0,
      }}
    >
      <AnimateKeyframes
        play={true}
        pause={false}
        easeType="linear"
        iterationCount="infinite"
        // direction="alternate"
        duration={15}
        // duration={8}
        // duration={4}
        // keyframes={[
        //   "left: 100%; transform: translate(0)",
        //   "left: 0; transform: translate(-100%)",
        // ]}
        // keyframes={[
        //   "transform: translate(+100%); opacity:0",
        //   "transform: translate(+75%); opacity:1",
        //   "transform: translate(+50%); opacity:1",
        //   "transform: translate(+25%); opacity:1",
        //   "transform: translate(0); opacity:0",
        // ]}
        // keyframes={[
        //   "transform: translate(+200%); opacity:0",
        //   "transform: translate(+150%); opacity:1",
        //   "transform: translate(+100%); opacity:1",
        //   "transform: translate(+50%); opacity:1",
        //   "transform: translate(0); opacity:0",
        // ]}
        keyframes={[
          "transform: translate(+100%); opacity:0",
          "transform: translate(+50%); opacity:1",
          "transform: translate(+0%); opacity:1",
          "transform: translate(-50%); opacity:1",
          "transform: translate(-100%); opacity:0",
        ]}
      >
      <p
        style={{
          fontSize: "9vw",
          fontFamily: "Montserrat, sans-serif",
          fontWeight: 600,
          lineHeight: 1,
          letterSpacing: 0,
          WebkitTextStrokeColor: "#E5E5E5",
          WebkitTextStrokeWidth: 2,
          color,
          // opacity,
          whiteSpace: "nowrap",
        }}
      >
        {str}
      </p>
      </AnimateKeyframes>
    </div>
  );
}

