import { useDispatch, useSelector } from "react-redux";
import React, { FC, useEffect, useRef, useState } from "react";
// import AboutSm from "../ContainerSM/About";
import {
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Row,
  Col,
  UncontrolledTooltip,
  Card,
  CardBody,
  CardTitle,
  CardFooter,
  Button,
} from "reactstrap";
export function CardImage({ src }) {
  return (
    <>
      <Card data-background="image" style={src}>
        <div className="card-icon">
          {/* <i className="nc-icon nc-mobile" /> */}
        </div>
      </Card>
    </>
  );
}

export function CardDescription({ uptitle, title, address, mapsrc }) {
  return (
    <>
      <Card className="card-plain">
        <CardBody className="mt-0 pt-0 pb-0">
          <h6 className="card-category mt-0">{uptitle}</h6>
          <a href="..." onClick={(e) => e.preventDefault()}>
            <CardTitle tag="h3">{title}</CardTitle>
          </a>
          <p className="card-description" style={{ marginBottom: 20 }}>
            {address}
            <br />
            {/* 最寄駅河内永和のメインの事務所 */}
          </p>
          <CardFooter>
            {/* <Button
              className="btn-neutral mr-1"
              color="link"
              href="..."
              onClick={(e) => e.preventDefault()}
            >
              <i className="fa fa-book mr-1" />
              Read More
            </Button>
            <Button
              className="btn-just-icon btn-neutral mr-1"
              color="link"
              href="..."
              onClick={(e) => e.preventDefault()}
            >
              <i className="fab fa-telegram-plane" />
            </Button>
            <Button
              className="btn-just-icon btn-neutral mr-1"
              color="link"
              href="..."
              onClick={(e) => e.preventDefault()}
            >
              <i className="fas fa-mobile-alt" />
            </Button>
            <Button
              className="btn-just-icon btn-neutral"
              color="link"
              href="..."
              onClick={(e) => e.preventDefault()}
            >
              <i className="fas fa-map-marker-alt" />
            </Button> */}
            <div>
              <iframe
                title="map1"
                src={mapsrc}
                width="430"
                height="230"
                frameborder="0"
                style={{ border: 0 }}
                allowfullscreen=""
                aria-hidden="false"
                tabindex="0"
              ></iframe>
            </div>
          </CardFooter>
        </CardBody>
      </Card>
    </>
  );
}
