import React, { FC, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NotificationAlert from "react-notification-alert";
import { initReactI18next, useTranslation } from "react-i18next";
import ColorNavbar from "./Navbars/ColorNavbar";
import ColorNavbar2 from "./Navbars/ColorNavbar2";

import { Parallax, ParallaxLayer } from "react-spring/renderprops-addons";

import SectionFooterAreas from "./Component/SectionFooterAreas.js";
import SectionFooterAreas2 from "./Component/SectionFooterAreas2.js";
import SectionFooterAreas3 from "./Component/SectionFooterAreas3.js";

import Carousel from "./Component/Carousel.js";
import { useInView } from "react-intersection-observer";

import {
  ContainerHead,
  ContainerLeftRectangle,
  ContainerRightRectangle,
} from "./Common/Container";

import { ContainerInfo } from "./Contact/Container";

import { Decimal } from "decimal.js";

function DashBoard() {
  const [t, i18n] = useTranslation();
  const refnotify = useRef();
  document.documentElement.classList.remove("nav-open");

  let parallax = null;

  React.useEffect(() => {
    // window.HeightEqual();
  }, []);

  const [refContainerHead, inContainerHead] = useInView();
  const [refContainer1, inContainer1] = useInView();
  const [refContainer2, inContainer2] = useInView();
  const [refContainer3, inContainer3] = useInView();

  const [ph1, set_ph1] = useState(0);
  const [ph2, set_ph2] = useState(0);
  const [ph3, set_ph3] = useState(0);
  const [ph4, set_ph4] = useState(0);
  const [ph5, set_ph5] = useState(0);
  const [ph6, set_ph6] = useState(0);
  // const [ph7, set_ph7] = useState(0);
  // const [ph8, set_ph8] = useState(0);
  // const [ph9, set_ph9] = useState(0);
  // const [ph10, set_ph10] = useState(0);

  // const ph = () => {
  //   const phfunc = () => {
  //     // const wi = window.innerHeight;
  //     // const wi = document.documentElement.clientHeight;

  //     const vh = window.jQuery("#parallax").height();

  //     const ph0 = window.jQuery("#ph0").height();
  //     const ph1 = window.jQuery("#ph1").height() + ph0;
  //     const ph2 = window.jQuery("#ph2").height() + ph1;
  //     const ph3 = window.jQuery("#ph3").height() + ph2;
  //     const ph4 = window.jQuery("#ph4").height() + ph3;
  //     const ph5 = window.jQuery("#ph5").height() + ph4;

  //     // const ph6 = window.jQuery("#ph6").height() + ph5;
  //     // const ph7 = window.jQuery("#ph7").height() + ph6;
  //     // const ph8 = window.jQuery("#ph8").height() + ph7;
  //     // const ph9 = window.jQuery("#ph9").height() + ph8;
  //     // const ph10 = window.jQuery("#ph10").height() + ph9;

  //     if (ph0) {
  //       set_ph1(ph0 / vh);
  //       set_ph2(ph1 / vh);
  //       set_ph3(ph2 / vh);
  //       set_ph4(ph3 / vh);
  //       set_ph5(ph4 / vh);
  //       set_ph6(ph5 / vh);
  //       // set_ph7(ph6 / vh);
  //       // set_ph8(ph7 / vh);
  //       // set_ph9(ph8 / vh);
  //       // set_ph10(ph9 / vh);
  //     }
  //   };
  //   setTimeout(phfunc, 1000);
  //   setTimeout(phfunc, 2000);
  // };

  // useEffect(() => {
  //   ph();

  //   document.addEventListener(
  //     "DOMFocusIn",
  //     function (event) {
  //       //キーボードが出現した時
  //       ph();
  //     },
  //     false
  //   );
  //   document.addEventListener(
  //     "DOMFocusOut",
  //     function (event) {
  //       //キーボードが引っ込んだ時
  //       ph();
  //     },
  //     false
  //   );
  // }, []);

  return (
    <>
      <div id="parallax-bar">
        <NotificationAlert ref={refnotify} />
        <ColorNavbar2
          nonparallax={true}
          title="住宅設備関連、リフォームのことなら松永興業株式会社へ"
          shareLink="https://www.matsunaga-industrial.com/contact"
        />
      </div>
      <div ref={refContainerHead} >
        <ContainerHead
          inview={inContainerHead}
          title="Contact"
          subtitle="連絡先"
          background={{
            // backgroundImage: { src },
            backgroundImage:
              "url(" + require("assets/img/envato/contact.jpg") + ")",
            // backgroundPosition: "0vw -15vh",
            backgroundSize: "cover",
            // backgroundSize: "78%",
            // backgroundRepeat: "no-repeat",
          }}
        />
      </div>
      <ContainerInfo inview={inContainer1} refnotify={refnotify} />
      <SectionFooterAreas3
        title="住宅設備関連、リフォームのことなら松永興業株式会社へ"
        shareLink="https://www.matsunaga-industrial.com/contact"
      />
    </>
  );
}
export default DashBoard;
