import React, { FC, useEffect, useRef, useState } from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import BlogList from "../Blog/entriesContainer";

import Lightgallery from "../About/Lightgallery";
import BeforeAfter from "../About/BeforeAfter";

import { useSpring, animated, interpolate } from "react-spring";
import ColorCard from "../About/ColorCard";
import DescriptionCard from "../About/DescriptionCard";
// 親要素がabsoluteであることから、ContainerやRowはすべて高さが０である
// このことを利用して、Rowを重ねていこう

// 子要素を無視するabsoluteとfixed
// 子要素を計算して自分の大きさを決めるrelativeとstatic

export function ContainerBlogList({ inview,scrollTo ,ph}) {
  return (
    <Container>
      {/* <div className="title">
              <h3>Tables</h3>
            </div> */}
      <Row>
        <Col className="ml-auto mr-auto text-center" md="8">
          {/* <h2 className="title">ブログ</h2> */}
          {/* <h3 className="title">どんな小さなことでも構いません。是非、弊社にご相談ください。</h3> */}
          <h5 className="description">
          松永興業株式会社の日々の活動、ニュース等を発信していきます。
          </h5>
        </Col>
      </Row>
      <div className="space-top" />
      <BlogList scrollTo={scrollTo} ph={ph}/>
    </Container>
  );
}
