import React from "react";
import { Button, Container, Row, Col } from "reactstrap";
import { Transition } from "react-transition-group";
import { initReactI18next, useTranslation } from "react-i18next";

export function DivMessage1({ scroll }) {
  const [t, i18n] = useTranslation();

  return (
    <>
      <div style={{ height: "4.8vw" }}>
        <div className="height1_6vw" style={{ fontWeight: 600 }}>
          Renovation
        </div>
        <div style={{ height: "1.2vw" }}></div>
        <div className="height2_0vw" style={{ fontWeight: 400 }}>
          リノベーションで素敵なお家に
        </div>
      </div>
      <div style={{ height: "9vw" }}></div>
      <div style={{ height: "9vw" }}>
        <div className="height1_6vw" style={{ fontWeight: 400, opacity: 0.9 }}>
          お問い合わせ受付から営業、工事までを一貫して自社にて行うことで不要な中間コストをカット。適正な価格でのご提案を実現します。
        </div>
      </div>

      <div style={{ height: "3.2vw" }}></div>
      <div style={{ height: "3.2vw" }}>
        <div className="height1_6vw" style={{ fontWeight: 400, opacity: 0.9 }}>
          地域の皆様のご要望により多くお応え致します。
        </div>
      </div>
    </>
  );
}

export function DivMessage2({ scroll }) {
  const [t, i18n] = useTranslation();

  return (
    <>
      <div style={{ height: "4.8vw" }}>
        <div className="height1_6vw" style={{ fontWeight: 600 }}>
          Exterior
        </div>
        <div style={{ height: "1.2vw" }}></div>

        <div className="height2_0vw" style={{ fontWeight: 400 }}>
          外装事業
        </div>
      </div>
      <div style={{ height: "9vw" }}></div>
      <div style={{ height: "9vw" }}>
        <div className="height1_6vw" style={{ fontWeight: 400, opacity: 0.9 }}>
          屋根、外壁の事ならご相談下さい。
          <br />
          お客様にとって本当に良いものとは？
          <br />
          を追求しお客様へご提案させて頂きます。
        </div>
      </div>

      <div style={{ height: "3.2vw" }}></div>
      <div style={{ height: "3.2vw" }}>
        <div className="height1_6vw" style={{ fontWeight: 400, opacity: 0.9 }}>
          {/* 全国展開中のガイソーのVC加盟店です。 */}
          {"　"}
        </div>
      </div>
    </>
  );
}

export function DivMessage3({ scroll }) {
  const [t, i18n] = useTranslation();

  return (
    <>
      <div style={{ height: "4.8vw" }}>
        <div className="height1_6vw" style={{ fontWeight: 600 }}>
          Interior
        </div>
        <div style={{ height: "1.2vw" }}></div>

        <div className="height2_0vw" style={{ fontWeight: 400 }}>
          内装・設備関連事業
        </div>
      </div>
      <div style={{ height: "9vw" }}></div>
      <div style={{ height: "9vw" }}>
        <div className="height1_6vw" style={{ fontWeight: 400, opacity: 0.9 }}>
          エコキュート・ガス給湯器・コンロを始めとして、浴室乾燥機・トイレ・洗面化粧台・食洗器・水栓まで家の内装設備のことであればすべて自社職人にて対応致します。年間３,０００件の工事実績、創業以来１万件を超える工事実績で自信を持ってご対応させて頂きます。
        </div>
      </div>

      <div style={{ height: "3.2vw" }}></div>
      <div style={{ height: "3.2vw" }}>
        <div className="height1_6vw" style={{ fontWeight: 400, opacity: 0.9 }}>
          {"　"}
        </div>
      </div>
    </>
  );
}
