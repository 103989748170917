import React, { FC, useEffect, useRef, useState } from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import Lightgallery from "../About/Lightgallery";
import BeforeAfter from "../About/BeforeAfter";
import { useInView } from "react-intersection-observer";

import { useSpring, animated, interpolate } from "react-spring";
import ColorCard from "../About/ColorCard";
import { CardImage, CardDescription } from "../About/DescriptionCard";
// 親要素がabsoluteであることから、ContainerやRowはすべて高さが０である
// このことを利用して、Rowを重ねていこう

// 子要素を無視するabsoluteとfixed
// 子要素を計算して自分の大きさを決めるrelativeとstatic

export function ContainerHead({ inview }) {
  // { opa, tra, clip }
  let { opa, flowright, flowleft, transxleft, transytop } = useSpring({
    opa: inview ? 1 : 0,
    flowright: inview
      ? "rect(0vh, 100vw, 100vh, 0vw)"
      : "rect(0vh, 0vw, 100vh, 0vw)",
    flowleft: inview
      ? "rect(0vh, 100vw, 100vh, 0vw)"
      : "rect(0vh, 100vw, 100vh, 100vw)",
    // transxleft: inview ? "translate3d(0px, 0, 0)" : "translate3d(70px, 0, 0)",
    transxleft: inview ? "translateX(0)" : "translateX(70px)",
    transxright: inview ? "translateX(0)" : "translateX(-70px)",
    transytop: inview ? "translateY(0)" : "translateY(70px)",
  });

  return (
    <Container fluid>
      <Row>
        <Col lg="12" className="px-0">
          <animated.div
            style={{
              // transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
              transition: "all 800ms ease",
              opacity: opa.interpolate((p) => p),
              clip: flowright.interpolate((p) => p),
              position: "fixed",
            }}
          >
            <div
              style={{ position: "absolute", height: "70vh", width: "100vw" }}
              className="black-cool"
            ></div>
          </animated.div>
        </Col>
      </Row>
      <Row>
        <Col lg="2" className="px-0"></Col>
        <Col lg="10" className="px-0">
          <animated.div
            style={{
              // transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
              transition: "all 800ms ease",
              transitionDelay: "0.3s",
              transform: transxleft.interpolate((p) => p),
              opacity: opa.interpolate((p) => p),
              // clip: flowleft.interpolate((p) => p),
              position: "fixed",
            }}
          >
            <div
              className="backgroundImage-80"
              style={{
                position: "absolute",
                left: "5vw",
                top: "15vh",
                width: "100vw",
                hiight: "100vh",
                minHeight: "65vh",
                maxHeight: "65vh",
                backgroundImage:
                  // "url(" + require("assets/img/envato/handshake2.jpg") + ")",
                  "url(" +
                  // require("assets/img/envato/concept.jpg") +
                  require("assets/img/envato/workers-min.jpg") +
                  // require("assets/img/close-cool.jpg") +
                  // require("assets/img/handshake2.jpg") +

                  ")",
              }}
            ></div>
            <div
              className="backgroundImage-80"
              style={{
                position: "absolute",
                left: "5vw",
                top: "15vh",
                width: "100vw",
                hiight: "100vh",
                minHeight: "65vh",
                maxHeight: "65vh",
                backgroundColor: "rgba(0, 0, 0, 0.2)",
              }}
            ></div>
            {/* <div
              style={{
                position: "absolute",
                height: "100vh",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            ></div> */}
          </animated.div>

          {/* <div
              style={{
                position: "absolute",
                left: "5vw",
                top: "15vh",
                height: "65vh",
                width: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.2)",
              }}
            ></div> */}
        </Col>
      </Row>
      <Row>
        <Col lg="6" style={{ paddingLeft: 0 }}>
          <animated.div
            style={{
              // transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
              transition: "all 800ms ease",
              transitionDelay: "0.3s",
              transform: transytop.interpolate((p) => p),
              opacity: opa.interpolate((p) => p),
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "25vh",
                left: "10vw",
                // width: "100vw",
                // hiight: "100vh",
                // backgroundColor: "white",
              }}
            >
              <h1
                style={{
                  // color: "white",
                  color: "#C3B189",
                  fontWeight: 600,
                  letterSpacing: 4,
                }}
              >
                About
              </h1>
              <p
                style={{
                  // color: "white",
                  color: "#C3B189",

                  // fontWeight: 600,
                  letterSpacing: 1,
                  paddingTop: 10,
                }}
              >
                当社について
              </p>
            </div>
          </animated.div>
        </Col>
      </Row>
    </Container>
  );
}

export function ContainerLeftRectangle({ inview }) {
  return (
    <Container fluid>
      <Row>
        <Col lg="8" className="px-0"></Col>
        <Col lg="4" className="px-0">
          <div
            style={{
              position: "absolute",
              // left: "5vw",
              top: "10vh",
              height: "65vh",
              width: "100%",
            }}
            className="gray-cool"
          ></div>
        </Col>
      </Row>
    </Container>
  );
}

export function ContainerRightRectangle({ inview }) {
  return (
    <Container fluid>
      <Row>
        <Col lg="2" className="px-0"></Col>
        <Col lg="5" className="px-0">
          <div
            style={{
              position: "absolute",
              // left: "5vw",
              top: "10vh",
              height: "70vh",
              width: "100%",
            }}
            className="gray-cool"
          ></div>
        </Col>
        <Col lg="4" className="px-0"></Col>
      </Row>
    </Container>
  );
}

export function ContainerLeftImage({ inview }) {
  // { opa, tra, clip }
  let {
    opa,
    flowright,
    flowleft,
    transxleft,
    transxright,
    transytop,
  } = useSpring({
    opa: inview ? 1 : 0,
    flowright: inview
      ? "rect(0vh, 100vw, 100vh, 0vw)"
      : "rect(0vh, 0vw, 100vh, 0vw)",
    flowleft: inview
      ? "rect(0vh, 100vw, 100vh, 0vw)"
      : "rect(0vh, 100vw, 100vh, 100vw)",
    transxleft: inview ? "translate3d(0px, 0, 0)" : "translate3d(70px, 0, 0)",
    transxright: inview
      ? "translate3d(0px, 0, 0)"
      : "translate3d(-100px, 0, 0)",

    transytop: inview ? "translateY(0)" : "translateY(70px)",
  });

  return (
    <Container fluid>
      <Row>
        <Col lg="8" className="px-0"></Col>
        <Col lg="4" className="px-0">
          {/* <div
            style={{
              position: "absolute",
              // left: "5vw",
              top: "10vh",
              height: "65vh",
              width: "100%",
            }}
            className="gray-cool"
          ></div> */}
        </Col>
      </Row>
      <Row>
        <Col lg="5" className="px-0">
          <animated.div
            style={{
              // transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
              transition: "all 800ms ease",
              // transitionDelay: "0.3s",

              transform: transxright.interpolate((p) => p),
              opacity: opa.interpolate((p) => p),
              // clip: flowleft.interpolate((p) => p),
              position: "fixed",
            }}
          >
            <div
              className="backgroundImage-80"
              style={{
                position: "absolute",
                // left: "5vw",
                // top: "15vh",
                width: "45vw",
                height: "70vh",
                // minHeight: "65vh",
                // maxHeight: "65vh",
                backgroundImage:
                  // "url(" + require("assets/img/envato/handshake2.jpg") + ")",
                  "url(" +
                  require("assets/img/envato/business-documents-min.jpg") +
                  // require("assets/img/business-cool.jpg") +
                  ")",
              }}
            ></div>
          </animated.div>
        </Col>
        <Col lg="7" className="px-0"></Col>
      </Row>
      <Row>
        <Col lg="6" style={{ paddingLeft: 0 }}></Col>
        <Col lg="6" style={{ paddingLeft: 0 }}>
          <animated.div
            style={{
              // transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
              transition: "all 800ms ease",
              // transitionDelay: "0.3s",
              // transform: transxleft.interpolate((p) => p),
              transform: transytop.interpolate((p) => p),
              opacity: opa.interpolate((p) => p),
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "15vh",
                left: "5vw",
                width: "40vw",
                // height: "100vh",
                // backgroundColor: "white",
              }}
            >
              <h1
                style={{
                  color: "black",
                  fontWeight: 700,
                  fontSize: 40,
                  letterSpacing: 3,
                  marginBottom: 50,
                  display: "inline-block",
                  position: "relative",
                }}
              >
                PHILOSOPHY
                <span
                  style={{
                    // color: "black",
                    fontWeight: 300,
                    fontSize: 13,
                    position: "absolute",
                    right: 0,
                    bottom: "-1.5em",
                    letterSpacing: 1,
                    // paddingTop: 10,
                  }}
                >
                  企業理念
                </span>
              </h1>
              <p
                style={{
                  color: "#C3B189",
                  fontWeight: 500,
                  fontSize: 26,
                  lineHeight: 1.6,
                  letterSpacing: 2,
                  paddingTop: 10,
                  marginBottom: 20,
                }}
              >
                「関わるすべての人に愛される企業に」
              </p>

              <p
                style={{
                  color: "black",
                  // fontWeight: 600,
                  letterSpacing: 1,
                  paddingTop: 10,
                }}
              >
                お客様、地域の皆様等、松永興業株式会社に関わるすべての人と一生涯のパートナーになれるような会社作りを目指します。お客様、地域の皆様へは、住まいの提案を通じて関わるすべての人に暮らしの豊かさを提案します。
              </p>
            </div>
          </animated.div>
        </Col>
      </Row>
    </Container>
  );
}

export function ContainerRightImage({ inview }) {
  // { opa, tra, clip }
  let {
    opa,
    flowright,
    flowleft,
    transxleft,
    transxright,
    transytop,
  } = useSpring({
    opa: inview ? 1 : 0,
    flowright: inview
      ? "rect(0vh, 100vw, 100vh, 0vw)"
      : "rect(0vh, 0vw, 100vh, 0vw)",
    flowleft: inview
      ? "rect(0vh, 100vw, 100vh, 0vw)"
      : "rect(0vh, 100vw, 100vh, 100vw)",
    transxleft: inview ? "translate3d(0px, 0, 0)" : "translate3d(70px, 0, 0)",
    transxright: inview
      ? "translate3d(0px, 0, 0)"
      : "translate3d(-100px, 0, 0)",

    transytop: inview ? "translateY(0)" : "translateY(70px)",
  });

  return (
    <Container fluid>
      <Row>
        <Col lg="2" className="px-0"></Col>
        <Col lg="5" className="px-0">
          {/* <div
            style={{
              position: "absolute",
              // left: "5vw",
              top: "10vh",
              height: "70vh",
              width: "100%",
            }}
            className="gray-cool"
          ></div> */}
        </Col>
        <Col lg="4" className="px-0"></Col>
      </Row>
      <Row>
        <Col lg="5" className="px-0"></Col>

        <Col lg="7" className="px-0">
          <animated.div
            style={{
              // transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
              transition: "all 800ms ease",
              // transitionDelay: "0.3s",
              transform: transxleft.interpolate((p) => p),
              opacity: opa.interpolate((p) => p),
              // clip: flowleft.interpolate((p) => p),
              position: "fixed",
            }}
          >
            <div
              className="backgroundImage-80"
              style={{
                position: "absolute",
                left: "5vw",
                // top: "15vh",
                width: "60vw",
                height: "90vh",
                // minHeight: "65vh",
                // maxHeight: "65vh",
                backgroundImage:
                  // "url(" + require("assets/img/envato/handshake2.jpg") + ")",
                  "url(" +
                  require("assets/img/envato/business-corporation-organization-teamwork-concept-P8K92GH-min.jpg") +
                  // require("assets/img/business-cool.jpg") +
                  ")",
              }}
            ></div>
          </animated.div>
        </Col>
      </Row>
      <Row>
        <Col lg="6" style={{ paddingLeft: 0 }}>
          <animated.div
            style={{
              // transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
              transition: "all 800ms ease",
              // transitionDelay: "0.3s",
              transform: transytop.interpolate((p) => p),
              opacity: opa.interpolate((p) => p),
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "15vh",
                left: "5vw",
                width: "40vw",
                // height: "100vh",
                // backgroundColor: "white",
              }}
            >
              <h1
                style={{
                  color: "black",
                  fontWeight: 700,
                  fontSize: 40,
                  letterSpacing: 3,
                  marginBottom: 50,
                  display: "inline-block",
                  position: "relative",
                }}
              >
                POLICY
                <span
                  style={{
                    // color: "black",
                    fontWeight: 300,
                    fontSize: 13,
                    position: "absolute",
                    right: 0,
                    bottom: "-1.5em",
                    letterSpacing: 1,
                    // paddingTop: 10,
                  }}
                >
                  企業精神
                </span>
              </h1>
              {/* <p
                style={{
                  color: "#C3B189",
                  fontWeight: 500,
                  fontSize: 26,
                  lineHeight: 1.6,
                  marginLeft: "1vw",
                  letterSpacing: 2,
                  paddingTop: 10,
                  marginBottom: 20,
                }}
              >
                「会社としての指針」
              </p> */}
              {/* <div className="text-center"> */}
              {/* <img
                alt="..."
                className="img"
                src={require("assets/img/企業理念.png")}
                // style={{ width: 600 }}
                style={{ width: "110%" }}
              /> */}

              {/* </div> */}
              <div style={{ overflow: "hidden" }}>
                <animated.div
                  style={{
                    transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
                    // transition: "all 800ms ease",
                    // transitionDelay: "0.3s",
                    // transform: transxleft.interpolate((p) => p),
                    transform: transytop.interpolate((p) => p),
                    opacity: opa.interpolate((p) => p),
                  }}
                >
                  <h4
                    className="title mb-1"
                    style={{
                      paddingLeft: "1.5em",
                      textIndent: "-1.5em",
                      // padding-left:1em;
                      // text-indent:-1em;
                    }}
                  >
                    １.
                    お客様に喜ばれる提案・商品・工事・サービスを提供し、その為には
                    <span className="under">一切の妥協はしない</span>事
                  </h4>
                  <p className="description">
                    　我々は作る・売る立場でなく使う・買う立場に立って考えます。
                  </p>
                </animated.div>
              </div>

              <div style={{ overflow: "hidden" }}>
                <animated.div
                  style={{
                    transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
                    // transition: "all 800ms ease",
                    transitionDelay: "0.5s",
                    // transform: transxleft.interpolate((p) => p),
                    transform: transytop.interpolate((p) => p),
                    opacity: opa.interpolate((p) => p),
                  }}
                >
                  <h4 className="title mb-1">
                    ２. お客様と
                    <span className="under">一生涯</span>
                    のパートナーシップを
                  </h4>
                  <p className="description">
                    　人の病院はありますが、お家の病院はありません。一度工事したら終わり、ではなく何かお困り事があれば、今後松永興業株式会社にご相談頂ける様、安心・信頼を頂ける企業を目指します。
                  </p>
                </animated.div>
              </div>

              <div style={{ overflow: "hidden" }}>
                <animated.div
                  style={{
                    transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
                    // transition: "all 800ms ease",
                    transitionDelay: "0.5s",
                    // transform: transxleft.interpolate((p) => p),
                    transform: transytop.interpolate((p) => p),
                    opacity: opa.interpolate((p) => p),
                  }}
                >
                  <h4 className="title mb-1">
                    ３. 自分の仕事に誇りを持ち、
                    <span className="under">正々堂々誠実</span>に
                  </h4>
                  <p className="description">
                    　お客様から安心信頼を頂くためには、まず我々自身が自分に恥じない仕事をしなければなりません。我々は全員が目の前の仕事に誠実に、一生懸命打ち込むことを約束します。
                  </p>
                </animated.div>
              </div>
              <div style={{ overflow: "hidden" }}>
                <animated.div
                  style={{
                    transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
                    // transition: "all 800ms ease",
                    transitionDelay: "1.0s",
                    // transform: transxleft.interpolate((p) => p),
                    transform: transytop.interpolate((p) => p),
                    opacity: opa.interpolate((p) => p),
                  }}
                >
                  <h4 className="title mb-1">
                    ４. <span className="under">成長欲求</span>と
                    <span className="under">チャレンジ精神</span>をもつ
                  </h4>

                  <p className="description">　現状維持はマイナス。</p>
                </animated.div>
              </div>
              <div style={{ overflow: "hidden" }}>
                <animated.div
                  style={{
                    transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
                    // transition: "all 800ms ease",
                    transitionDelay: "1.5s",
                    // transform: transxleft.interpolate((p) => p),
                    transform: transytop.interpolate((p) => p),
                    opacity: opa.interpolate((p) => p),
                  }}
                >
                  <h4 className="title mb-1">
                    ５. <span className="under">社会貢献</span>
                    を意識する
                  </h4>
                  <p className="description">
                    　会社を成長させていくことでお客様へ提供出来るサービスの幅や質を高めていきます。また、日々当たり前のように仕事が出来るのは、地域の方々、お客様、協力業者様、家族、親からの協力があってのことです。どんな形であれ、全社員が感謝の気持ちを忘れずに仕事に励みます。
                  </p>
                </animated.div>
              </div>
            </div>
          </animated.div>
        </Col>
        <Col lg="6" style={{ paddingLeft: 0 }}></Col>
      </Row>
    </Container>
  );
}

export function ContainerLeftImage2({ inview }) {
  // { opa, tra, clip }
  let {
    opa,
    flowright,
    flowleft,
    transxleft,
    transxright,
    transytop,
  } = useSpring({
    opa: inview ? 1 : 0,
    flowright: inview
      ? "rect(0vh, 100vw, 100vh, 0vw)"
      : "rect(0vh, 0vw, 100vh, 0vw)",
    flowleft: inview
      ? "rect(0vh, 100vw, 100vh, 0vw)"
      : "rect(0vh, 100vw, 100vh, 100vw)",
    transxleft: inview ? "translate3d(0px, 0, 0)" : "translate3d(70px, 0, 0)",
    transxright: inview
      ? "translate3d(0px, 0, 0)"
      : "translate3d(-100px, 0, 0)",

    transytop: inview ? "translateY(0)" : "translateY(70px)",
  });

  return (
    <Container fluid>
      <Row>
        <Col lg="8" className="px-0"></Col>
        <Col lg="4" className="px-0">
          {/* <div
            style={{
              position: "absolute",
              // left: "5vw",
              top: "10vh",
              height: "65vh",
              width: "100%",
            }}
            className="gray-cool"
          ></div> */}
        </Col>
      </Row>
      <Row>
        <Col lg="5" className="px-0">
          <animated.div
            style={{
              // transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
              transition: "all 800ms ease",
              // transitionDelay: "0.3s",
              transform: transxright.interpolate((p) => p),
              opacity: opa.interpolate((p) => p),
              // clip: flowleft.interpolate((p) => p),
              position: "fixed",
            }}
          >
            <div
              className="backgroundImage-80"
              style={{
                position: "absolute",
                // left: "5vw",
                // top: "15vh",
                width: "45vw",
                height: "90vh",
                // minHeight: "65vh",
                // maxHeight: "65vh",
                backgroundImage:
                  // "url(" + require("assets/img/envato/handshake2.jpg") + ")",
                  "url(" +
                  require("assets/img/envato/business-people-min.jpg") +
                  // require("assets/img/business-cool.jpg") +
                  ")",
              }}
            ></div>
          </animated.div>
        </Col>
        <Col lg="7" className="px-0"></Col>
      </Row>
      <Row>
        <Col lg="6" style={{ paddingLeft: 0 }}></Col>
        <Col lg="6" style={{ paddingLeft: 0 }}>
          <animated.div
            style={{
              // transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
              transition: "all 800ms ease",
              // transitionDelay: "0.3s",
              transform: transytop.interpolate((p) => p),
              opacity: opa.interpolate((p) => p),
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "15vh",
                left: "5vw",
                width: "40vw",
                // height: "100vh",
                // backgroundColor: "white",
              }}
            >
              <h1
                style={{
                  color: "black",
                  fontWeight: 700,
                  fontSize: 40,
                  letterSpacing: 3,
                  marginBottom: 50,
                  display: "inline-block",
                  position: "relative",
                }}
              >
                GUIDELINES
                <span
                  style={{
                    // color: "black",
                    fontWeight: 300,
                    fontSize: 13,
                    position: "absolute",
                    right: 0,
                    bottom: "-1.5em",
                    letterSpacing: 1,
                    // paddingTop: 10,
                  }}
                >
                  行動指針
                </span>
              </h1>
              {/* <p
                style={{
                  color: "#C3B189",
                  fontWeight: 500,
                  fontSize: 26,
                  lineHeight: 1.6,
                  letterSpacing: 2,
                  paddingTop: 10,
                  marginBottom: 20,
                }}
              >
                「松永興業株式会社の社員は、下記の行動指針を守り企業の発展に邁進します。」
              </p> */}
              {/* <img
                alt="..."
                className="img"
                src={require("assets/img/指針.png")}
                // style={{ width: 600 }}
                style={{ width: "110%", marginBottom: 20 }}
              /> */}
              <h4 className="title mb-0">１. 礼儀正しく誠実に</h4>
              <h4 className="title mb-0">
                ２. 情熱と執念をもって仕事に打ち込む
              </h4>
              <h4 className="title mb-0">
                ３. 感謝と敬意の気持ちを忘れないこと
              </h4>
              <h4 className="title ">４. 成長意欲とチャレンジ精神もつこと</h4>
              <p className="description" style={{ color: "black" }}>
                松永興業株式会社の社員は、上記の行動指針を守り企業の発展に邁進します。
                {/* 上記は、一企業人の立ち振る舞いとして必要な考え方になります。松永興業株式会社は全社員が上記の行動指針をセンターピンとして日々の業務にあたります。 */}
              </p>
            </div>
          </animated.div>
        </Col>
      </Row>
    </Container>
  );
}

export function ContainerColorCard({ inview }) {
  React.useEffect(() => {
    window.HeightEqual();
  }, []);

  // { opa, tra, clip }
  let {
    opa,
    flowright,
    flowleft,
    transxleft,
    transxright,
    transytop,
    transybottom,
  } = useSpring({
    opa: inview ? 1 : 0,
    flowright: inview
      ? "rect(0vh, 100vw, 100vh, 0vw)"
      : "rect(0vh, 0vw, 100vh, 0vw)",
    flowleft: inview
      ? "rect(0vh, 100vw, 100vh, 0vw)"
      : "rect(0vh, 100vw, 100vh, 100vw)",
    transxleft: inview ? "translate3d(0px, 0, 0)" : "translate3d(100px, 0, 0)",
    transxright: inview
      ? "translate3d(0px, 0, 0)"
      : "translate3d(-100px, 0, 0)",

    transytop: inview ? "translateY(0)" : "translateY(100px)",
    transybottom: inview ? "translateY(0)" : "translateY(-100px)",
  });

  return (
    <Container>
      <Row>
        <Col className="ml-auto mr-auto text-center" md="6">
          <h2 className="title">当社について</h2>
          {/* <h3 className="title"></h3> */}

          <h5 className="description">
            地域の皆様から支持されている様々な特徴をご紹介します。
          </h5>
        </Col>
      </Row>
      <div className="space-top" />
      <Row className="coloured-cards">
        {/* <Row className="coloured-cards" ref={refRow}> */}

        <Col lg="4" md="6" className="mb-5">
          <animated.div
            className="h-100"
            style={{
              // transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
              transition: "all 800ms ease",
              transitionDelay: "0.0s",
              transform: transxleft.interpolate((p) => p),
              opacity: opa.interpolate((p) => p),
            }}
          >
            <ColorCard
              pathname="/about"
              hash="#self-produce"
              datacolor="blue"
              engtitle="self produce"
              title="自社職人"
              description="下請け、外注が当たり前の業界ですが、当社は自社職人がお伺いし、工事を行います。資格が必要な工事に関してはもちろん必ず有資格者がお伺い致します。"
            />
          </animated.div>
        </Col>
        <Col lg="4" md="6" className="mb-5">
          <animated.div
            className="h-100"
            style={{
              // transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
              transition: "all 800ms ease",
              transitionDelay: "0.2s",
              transform: transxright.interpolate((p) => p),
              opacity: opa.interpolate((p) => p),
            }}
          >
            <ColorCard
              pathname="/about"
              hash="#purchase-cost"
              datacolor="green"
              engtitle="purchase cost"
              title="仕入れコスト"
              description="同業ネットワークを構築しており、一括して大量仕入れすることで、コストダウンに成功しました。"
            />
          </animated.div>
        </Col>
        <Col lg="4" md="6" className="mb-5">
          <animated.div
            className="h-100"
            style={{
              // transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
              transition: "all 800ms ease",
              transitionDelay: "0.4s",
              transform: transytop.interpolate((p) => p),
              opacity: opa.interpolate((p) => p),
            }}
          >
            <ColorCard
              pathname="/about"
              hash="#quality-warranty"
              datacolor="yellow"
              engtitle="warranty"
              title="保証"
              // description="自社職人にて工事を行っている為、施工には自信があります。安心の施工10年保証をどの工事にもつけております。安心してご依頼下さい。"
              description="安心の施工１０年保証をどの工事にもつけております。自社職人で工事を行うため、施工には自信があります。安心してご依頼下さいませ。"
            />
          </animated.div>
        </Col>

        <Col lg="4" md="6" className="mb-5">
          <animated.div
            className="h-100"
            style={{
              // transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
              transition: "all 800ms ease",
              transitionDelay: "0.6s",
              transform: transybottom.interpolate((p) => p),
              opacity: opa.interpolate((p) => p),
            }}
          >
            <ColorCard
              pathname="/about"
              hash="#self-produce"
              datacolor="brown"
              engtitle="variety interior"
              title="内装設備全般"
              description="ユニットバス・システムキッチン・浴室乾燥機・食洗機・トイレ・ウォシュレット・クロスの張り替え・洗面台・水道蛇口の交換など、おうちのことなら何でもお任せ下さいませ。"
            />
          </animated.div>
        </Col>
        <Col lg="4" md="6" className="mb-5">
          <animated.div
            className="h-100"
            style={{
              // transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
              transition: "all 800ms ease",
              transitionDelay: "0.8s",
              transform: transxleft.interpolate((p) => p),
              opacity: opa.interpolate((p) => p),
            }}
          >
            <ColorCard
              pathname="/about"
              hash="#purchase-cost"
              datacolor="purple"
              engtitle="achievements"
              title="豊富な実績"
              description="給湯器だけでも年間３,０００件以上の工事を行います。施工実績１万件以上、安心安全の当社に是非お任せ下さいませ。"
            />
          </animated.div>
        </Col>
        <Col lg="4" md="6" className="mb-5">
          <animated.div
            className="h-100"
            style={{
              // transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
              transition: "all 800ms ease",
              transitionDelay: "1.0s",
              transform: transybottom.interpolate((p) => p),
              opacity: opa.interpolate((p) => p),
            }}
          >
            <ColorCard
              pathname="/about"
              hash="#quality-warranty"
              datacolor="orange"
              engtitle="fire insurance"
              title="火災保険"
              description="火災保険の申請のサポートをさせて頂きます。煩わしい手続き等、親切丁寧にお客様に寄り添いお手伝い致します。"
            />
          </animated.div>
        </Col>
      </Row>
    </Container>
  );
}

export function ContainerColorDescription() {
  React.useEffect(() => {
    window.HeightEqual();
  }, []);

  const [refDiv1, inDiv1] = useInView();
  const [refDiv2, inDiv2] = useInView();
  const [refDiv3, inDiv3] = useInView();
  const [refDiv4, inDiv4] = useInView();
  const [refDiv5, inDiv5] = useInView();
  const [refDiv6, inDiv6] = useInView();

  React.useEffect(() => {}, []);
  let { opa1, trans1 } = useSpring({
    opa1: inDiv1 ? 1 : 0,
    trans1: inDiv1 ? "translateY(0)" : "translateY(70px)",
  });
  let { opa2, trans2 } = useSpring({
    opa2: inDiv2 ? 1 : 0,
    trans2: inDiv2 ? "translate3d(0px, 0, 0)" : "translate3d(100px, 0, 0)",
  });
  let { opa3, trans3 } = useSpring({
    opa3: inDiv3 ? 1 : 0,
    trans3: inDiv3 ? "translate3d(0px, 0, 0)" : "translate3d(-100px, 0, 0)",
  });
  let { opa4, trans4 } = useSpring({
    opa4: inDiv4 ? 1 : 0,
    trans4: inDiv4 ? "translateY(0)" : "translateY(70px)",
  });
  let { opa5, trans5 } = useSpring({
    opa5: inDiv5 ? 1 : 0,
    trans5: inDiv5 ? "translateY(0)" : "translateY(70px)",
  });
  let { opa6, trans6 } = useSpring({
    opa6: inDiv6 ? 1 : 0,
    trans6: inDiv6 ? "translate3d(0px, 0, 0)" : "translate3d(100px, 0, 0)",
  });

  return (
    <Container className="pb-5">
      <h2 className="title">特徴</h2>
      <br />
      <Row>
        <Col lg="4">
          <div
            ref={refDiv1}
            style={{ position: "absolute", height: "200vh" }}
          ></div>
          <animated.div
            className="h-100"
            style={{
              transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
              // transition: "all 800ms ease",
              // transitionDelay: "0.0s",
              transform: trans1.interpolate((p) => p),
              opacity: opa1.interpolate((p) => p),
            }}
          >
            {/* <CardImage src={require("assets/img/envato/cardbig3-min.jpg")} /> */}
            <CardImage
              src={require("assets/img/matsunaga/エコキュート.jpg")}
            />
          </animated.div>
        </Col>
        <Col lg="8">
          <div
            ref={refDiv2}
            style={{ position: "absolute", height: "200vh" }}
          ></div>
          <animated.div
            className="h-100"
            style={{
              transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
              // transition: "all 800ms ease",
              // transitionDelay: "0.0s",
              transform: trans2.interpolate((p) => p),
              opacity: opa2.interpolate((p) => p),
            }}
          >
            <CardDescription
              datacolor="text-info"
              engtitle="self produce"
              title1="住宅設備機器専門店を全国に展開"
              title2=""
              // description="下請け、外注が当たり前の業界ですが、当社には大手ガス会社やメーカーのサービスショップ経験者などが在籍し、自社職人が直接お伺いし、工事を行います。資格が必要な工事に関してはもちろん必ず有資格者がお伺い致します。"
              description="エコキュートやガス給湯器を始めとして、幹太くんや、ハイブリット給湯器など商社・メーカーと共同で全国47都道府県に販売網を敷いています。
圧倒的な信頼と実績で各エリア、全国のお客様へ満足頂けるサービスをお届けます。"
            />
          </animated.div>
        </Col>
      </Row>
      <Row>
        <Col lg="4">
          <div
            ref={refDiv4}
            style={{ position: "absolute", height: "200vh" }}
          ></div>
          <animated.div
            className="h-100"
            style={{
              transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
              // transition: "all 800ms ease",
              // transitionDelay: "0.4s",
              transform: trans4.interpolate((p) => p),
              opacity: opa4.interpolate((p) => p),
            }}
          >
            {/* <CardImage src={require("assets/img/envato/cost.jpg")} /> */}
            {/* <CardImage src={require("assets/img/envato/architectural-house-exterior-7HVDWWS-min.jpg")} /> */}
            <CardImage
              src={require("assets/img/matsunaga/外壁.jpg")}
            />
          </animated.div>
        </Col>
        <Col lg="8">
          <div
            ref={refDiv3}
            style={{ position: "absolute", height: "200vh" }}
          ></div>
          <animated.div
            className="h-100"
            style={{
              transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
              // transition: "all 800ms ease",
              // transitionDelay: "0.4s",
              transform: trans3.interpolate((p) => p),
              opacity: opa3.interpolate((p) => p),
            }}
          >
            <CardDescription
              datacolor="text-danger"
              engtitle="Exterior renovation"
              title1="外装リフォーム専門店"
              title2=""
              // description="火災保険の申請は複雑な作業が必要になります。ご希望であれば、弊社では複雑な手続きのサポート業務もさせていただきます。"
              description="顧客満足を追求しお客様と生涯のお付き合いをさせて頂きます。
              最高の外装リフォームを追求することで住まいの資産価値を最大化します。
              住環境の未来を切り開くことで地域社会に貢献します。"
            />
          </animated.div>
        </Col>
      </Row>
      <Row>
        <Col lg="4">
          <div
            ref={refDiv5}
            style={{ position: "absolute", height: "200vh" }}
          ></div>
          <animated.div
            className="h-100"
            style={{
              transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
              // transition: "all 800ms ease",
              // transitionDelay: "0.8s",
              transform: trans5.interpolate((p) => p),
              opacity: opa5.interpolate((p) => p),
            }}
          >
            {/* <CardImage src={require("assets/img/envato/insurance.jpg")} /> */}
            <CardImage
              src={require("assets/img/envato/insurance-agent-services-6668AMT-min.jpg")}
            />
          </animated.div>
        </Col>
        <Col lg="8">
          <div
            ref={refDiv6}
            style={{ position: "absolute", height: "200vh" }}
          ></div>
          <animated.div
            className="h-100"
            style={{
              // transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
              transition: "all 800ms ease",
              // transitionDelay: "0.8s",
              transform: trans6.interpolate((p) => p),
              opacity: opa6.interpolate((p) => p),
            }}
          >
            <CardDescription
              datacolor="text-success"
              engtitle="warranty"
              title1="安心の保証制度"
              title2=""
              // description="自社職人にて工事を行っている為、施工には自信があります。安心の施工10年保証をどの工事にもつけております。安心してご依頼下さい。"
              description="保証会社と提携しています。工事保証は安心の１０年間保証を無償でおつけしています。
              別途費用を頂くことで商品保証も１０年間に延長可能となっております。
              天災・自然災害を除く商品機器の不良を幅広くカバーしております。
              工事、商品ともにお客様に安心をお届けできるサービスを提供します。"
            />
          </animated.div>
        </Col>
      </Row>
    </Container>
  );
}

export function ContainerGallery() {
  const [refDiv1, inDiv1] = useInView();
  const [refDiv2, inDiv2] = useInView();
  const [refDiv3, inDiv3] = useInView();
  const [refDiv4, inDiv4] = useInView();

  React.useEffect(() => {}, []);
  let { opa1, transytop1 } = useSpring({
    opa1: inDiv1 ? 1 : 0,
    transytop1: inDiv1 ? "translateY(0)" : "translateY(70px)",
  });
  let { opa2, transytop2 } = useSpring({
    opa2: inDiv2 ? 1 : 0,
    transytop2: inDiv2 ? "translateY(0)" : "translateY(70px)",
  });
  let { opa3, transytop3 } = useSpring({
    opa3: inDiv3 ? 1 : 0,
    transytop3: inDiv3 ? "translateY(0)" : "translateY(70px)",
  });
  let { opa4, transytop4 } = useSpring({
    opa4: inDiv4 ? 1 : 0,
    transytop4: inDiv4 ? "translateY(0)" : "translateY(70px)",
  });

  return (
    <Container>
      <Row>
        <Col className="ml-auto mr-auto text-center mb-5" md="12">
          <h2 className="title">施工事例</h2>
          {/* <h3 className="title"></h3> */}
          <h3 className="mb-4">様々な施工事例をご紹介します。</h3>
          <h5 className="description">
            その他、住宅設備全般に加え外装工事など幅広く取り扱いしておりますので
            <br />
            ぜひ当社に一度ご相談下さい。
          </h5>
        </Col>
        <Col
          lg="6"
          className="mb-5"
          // onClick={() => {
          //   set_modalRenovation(true);
          // }}
        >
          <animated.div
            className="h-100"
            style={{
              // transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
              transition: "all 800ms ease",
              // transitionDelay: "0.0s",
              transform: transytop1.interpolate((p) => p),
              opacity: opa1.interpolate((p) => p),
            }}
          >
            <div
              ref={refDiv1}
              style={{ position: "absolute", height: "200vh" }}
            ></div>
            {/* style="display: inline-block" */}
            <Lightgallery
              description="ガスコンロ"
              before={require("assets/img/matsunaga/company_photo_retouch_HP/施工事例/retouch/ガスコンロ/施工前.jpg")}
              after={require("assets/img/matsunaga/company_photo_retouch_HP/施工事例/retouch/ガスコンロ/施工後.jpg")}
              beforetxt={`
              <div class="text-center">
              <ul class = "list-unstyled" style="width:80vw ;height: 140px ;margin:0px auto;">
              <li class="text-left">【施工前】</li>
              <li class="text-left">使用されて１０年以上経っており、片側のみ強火力で使い勝手が良くない、ホーロー天板で清掃性が良くない、といった不満を解消したいとのことでご用命頂きました。</li>
              </ul>
              </div>
              `}
              aftertxt={`
              <div class="text-center">
              <ul class = "list-unstyled" style="width:80vw;height: 140px ;margin:0px auto;"　 >
              <li class="text-left">【施工後】</li>
              <li class="text-left">リンナイの人気シリーズ「マイトーン」にお取替え。両面強火力、ガラストップ天板で使用感、清掃性共に向上。奥様も大変ご満足されておりました！</li>
              </ul>
              </div>
              `}
            />
          </animated.div>
        </Col>
        <Col lg="6" className="mb-5">
          <animated.div
            className="h-100"
            style={{
              transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
              // transition: "all 800ms ease",
              // transitionDelay: "0.2s",
              transform: transytop2.interpolate((p) => p),
              opacity: opa2.interpolate((p) => p),
            }}
          >
            <div
              ref={refDiv2}
              style={{ position: "absolute", height: "200vh" }}
            ></div>
            <Lightgallery
              description="レンジフード"
              before={require("assets/img/matsunaga/company_photo_retouch_HP/施工事例/retouch/レンジフード/施工前.jpg")}
              after={require("assets/img/matsunaga/company_photo_retouch_HP/施工事例/retouch/レンジフード/施工後.jpg")}
              beforetxt={`
              <div class="text-center">
              <ul class = "list-unstyled" style="width:80vw ;height: 140px ;margin:0px auto;">
              <li class="text-left">【施工前】</li>
              <li class="text-left">ブーツ型のレンジフードから、薄型ノンフィルター型にお取替え。レンジフードの交換をすると毎日のキッチンのお掃除が楽になります！</li>
              </ul>
              </div>
              `}
              aftertxt={`
              <div class="text-center">
              <ul class = "list-unstyled" style="width:80vw ;height: 140px ;margin:0px auto;">
              <li class="text-left">【施工後】</li>
              <li class="text-left">ノーリツ社の薄型レンジフード、ノンフィルタータイプにお取替え。見た目もスッキリ、清掃性もかなり向上します！コンロと同時施工でお値打ち価格にて施工させて頂きました！</li>
              </ul>
              </div>
              `}
            />
          </animated.div>
        </Col>
        <Col lg="6" className="mb-5">
          <animated.div
            className="h-100"
            style={{
              transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
              // transition: "all 800ms ease",
              // transitionDelay: "0.0s",
              transform: transytop3.interpolate((p) => p),
              opacity: opa3.interpolate((p) => p),
            }}
          >
            <div ref={refDiv3}>
              <Lightgallery
                description="洗面化粧台"
                before={require("assets/img/matsunaga/company_photo_retouch_HP/施工事例/retouch/洗面化粧台/施工前.jpg")}
                after={require("assets/img/matsunaga/company_photo_retouch_HP/施工事例/retouch/洗面化粧台/施工後.jpg")}
                beforetxt={`
              <div class="text-center">
              <ul class = "list-unstyled" style="width:80vw ;height: 140px ;margin:0px auto;">
              <li class="text-left">【施工前】</li>
              <li class="text-left">当店で給湯器を交換されたお客様からのご相談で、洗面化粧台も交換したいとご用命頂きました。洗面化粧台ももちろん自社職人にてお取替え致します。</li>
              </ul>
              </div>
              `}
                aftertxt={`
              <div class="text-center">
              <ul class = "list-unstyled" style="width:80vw ;height: 140px ;margin:0px auto;">
              <li class="text-left">【施工後】</li>
              <li class="text-left">パナソニック社のエムラインシリーズにお取替え。洗面場所も広くなり、かなり使いやすくなりました！毎日使う場所なので雰囲気も明るくなったとお客様にもお喜び頂けました！</li>
              </ul>
              </div>
              `}
              />
            </div>
          </animated.div>
        </Col>
        <Col lg="6" className="mb-5">
          <animated.div
            className="h-100"
            style={{
              // transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
              transition: "all 800ms ease",
              // transitionDelay: "0.2s",
              transform: transytop4.interpolate((p) => p),
              opacity: opa4.interpolate((p) => p),
            }}
          >
            <div ref={refDiv4}>
              <Lightgallery
                description="浴室乾燥機"
                before={require("assets/img/matsunaga/company_photo_retouch_HP/施工事例/retouch/浴室乾燥機/施工前.jpg")}
                after={require("assets/img/matsunaga/company_photo_retouch_HP/施工事例/retouch/浴室乾燥機/施工後.jpg")}
                beforetxt={`
              <div class="text-center">
              <ul class = "list-unstyled" style="width:80vw ;height: 140px ;margin:0px auto;">
              <li class="text-left">【施工前】</li>
              <li class="text-left">浴室乾燥機も実は給湯器で動かしているので、浴室乾燥機の交換も給湯器と同時に依頼されることが多いです。温風が出なくなった等、使用年数が１０年を越えると不具合の報告も多く聞かれます。</li>
              </ul>
              </div>
              `}
                aftertxt={`
              <div class="text-center">
              <ul class = "list-unstyled" style="width:80vw ;height: 140px ;margin:0px auto;">
              <li class="text-left">【施工後】</li>
              <li class="text-left">リンナイ社の浴室乾燥機にお取替え。ミスト機能もついて、毎日のお風呂もより良い時間に。給湯器と同時施工でお値打ち価格にて施工させて頂きました！</li>
              </ul>
              </div>
              `}
              />
            </div>
          </animated.div>
        </Col>
      </Row>
    </Container>
  );
}
