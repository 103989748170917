import React from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

import {
  LetterFlowVertLeft,
  LetterFlowVertRight,
  LetterFlowVertLeft2
} from "./LetterFlowVert";

export function ContainerOpacityLeft({ id }) {
  return (
    <Container fluid style={{ position: "absolute" }}>
      <Row>
        <Col lg="6">
          <div className="rectangle-left-opacity"></div>
        </Col>
        <Col lg="6"></Col>
      </Row>
    </Container>
  );
}

export function ContainerOpacityRight({ id }) {
  return (
    <Container fluid style={{ position: "absolute" }}>
      <Row>
        <Col lg="6"></Col>
        <Col lg="6">
          <div className="rectangle-right-opacity"></div>
        </Col>
      </Row>
    </Container>
  );
}

export function ContainerTransparentLeft({ id }) {
  return (
    <Container fluid style={{ position: "absolute" }}>
      <Row>
        <Col lg="6">
          <div className="rectangle-left-transparent">
            <LetterFlowVertLeft
              color="#E5E5E5"
              // str="Renovation Interior Exterior"
              str="By your side, For Life."
            />
          </div>
        </Col>
        <Col lg="6"></Col>
      </Row>
    </Container>
  );
}

export function ContainerTransparentRight({ id }) {
  return (
    <Container fluid style={{ position: "absolute" }}>
      <Row>
        <Col lg="6"></Col>
        <Col lg="6">
          {/* <div className="rectangle-right-transparent">
            <LetterFlowVertRight
              color="#E5E5E5"
              str="By your side, For Life."
              // str="Renovation Interior Exterior"
            />
          </div> */}
          <div className="rectangle-left-transparent">
            <LetterFlowVertLeft2
              color="#E5E5E5"
              // str="Renovation Interior Exterior"
              str="By your side, For Life."
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
}
