import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NotificationAlert from "react-notification-alert";

// pages
import Home from "views/Page/Home.js";
import About from "views/Page/About.js";
import Company from "views/Page/Company.js";
import Message from "views/Page/Message.js";
import Blog from "views/Page/Blog.js";
import BlogDetail from "views/Page/BlogDetail.js";
import Contact from "views/Page/Contact.js";

import i18n from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";

import enJson from "./locales/en.json";
import jaJson from "./locales/ja.json";

import { loadReCaptcha } from 'react-recaptcha-v3'


i18n
  // .use(LngDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    resources: {
      en: { translation: enJson },
      ja: { translation: jaJson },
    },
    lng: "en",
    fallbackLng: false,
    returnEmptyString: false,
  });
export const AppRouter = () => {
  const hist = createBrowserHistory();

  useEffect(() => {
    // loadReCaptcha("6LfGIMEZAAAAAPIdQRUemj4R3GY8wtpXuojJIulx", ()=>{alert("loadReCaptcha");});
    // loadReCaptcha("6LfGIMEZAAAAAPIdQRUemj4R3GY8wtpXuojJIulx", ()=>{});

  }, []);





  return (
    <>
      <Router history={hist}>
        <Switch>
          <Route exact path="/" component={AppRedirect} />
          <Route path="/home" render={(props) => <Home {...props} />} />
          <Route path="/about" render={(props) => <About {...props} />} />
          <Route path="/company" render={(props) => <Company {...props} />} />
          <Route path="/message" render={(props) => <Message {...props} />} />
          <Route path="/blog" exact render={(props) => <Blog {...props} />} />
          <Route path="/blog/:uuid" render={(props) => <BlogDetail {...props} />} />
          <Route path="/contact" render={(props) => <Contact {...props} />} />
          <Redirect to="/home" />
        </Switch>
      </Router>
    </>
  );
};

const Out = (props) => {
  const isLogin = sessionStorage.getItem("AccessToken") !== null;

  return isLogin ? (
    props.children
  ) : (
    <>
      <Redirect to="/home" />
    </>
  );
};

const AppRedirect = ({ history }) => {
  useEffect(() => {
    history.push("/home");
  }, []);
  return <></>;
};
