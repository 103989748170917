import {
  api_GlobalToken,
  api_AccessToken,
  flow_get_tokens_global,
  err,
  api_ApiKey

} from "../stores/sagas";
import { fork, take, put, join, select, call } from "redux-saga/effects";
import { createAction, createReducer } from "redux-act";

export const post_contacts = createAction("post_contacts");

export const fetchingFalse = createAction("fetchingFalse");
export const loadingFalse = createAction("loadingFalse");
export const loadingDataFalse = createAction("loadingDataFalse");

export const failure_contacts = createAction("failure_contacts");

export const contacts = createReducer(
  {
    [post_contacts]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [fetchingFalse]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });
      newState.isFetching = false;
      return newState;
    },
    [loadingDataFalse]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });
      newState.isLoading = false;
      return newState;
    },
    [loadingFalse]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
    isFetching: true,
    contacts: [],
  }
);



export function* flow_post_contacts() {
  while (true) {
    const { payload } = yield take(post_contacts);

    const { data, error } = yield call(
      api_ApiKey,
      `contacts`,
      "post",
      payload.data
    );
    if (!error) {
      yield put(loadingFalse({ data }));

      !payload.page || payload.page();
    } else {
      yield put(loadingFalse({ error }));
      !payload.toast || payload.toast(err(error));
    }
  }
}
