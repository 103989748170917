import React from "react";
import ReactDOM from "react-dom";
import YouTubeSubscribe from "./youtubeSubscribe";
import SocialShare from "./socialShare";

import "./styles.css";

/**
 * LinkedIn YouTube Channel
 * -> Use your preference  for channelName
 */

// Refer) https://developers.google.com/youtube/youtube_subscribe_button
export default function App({title,shareLink}) {
  // Use dynamic data later
  // channelName has precedence over channelId
  // -> Only qualified and who decides to use channelName has it.

  let channelid = "UCaYhcUwRBNscFNUKTjgPFiA";
  let channelName = "LinkedIn";

  let channelImage =
    "https://yt3.ggpht.com/a-/ACSszfGDvjYK2vL_d3Bglghs2VQhTwbPrTGWxBaNDQ=s88-mo-c-c0xffffffff-rj-k-no";

  return (
    <div className="App">
      {/* <section className="c-share"> */}
        {/* <YouTubeSubscribe
          className="youtubeSubscribe"
          theme={"default"}
          layout={"full"}
          count={"default"}
          channelName={channelName}
          channelid={channelid}
        /> */}
        <SocialShare
          title={title}
          shareLink={shareLink}
          
          // channelName={channelName}
          // channelId={channelid}
          // channelImage={channelImage}
        />
      {/* </section> */}
    </div>
  );
}

const rootElement = document.getElementById("root");
