import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  FacebookShareButton,
  GooglePlusShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  PinterestShareButton,
  VKShareButton,
  WhatsappShareButton,
  EmailShareButton,
  LineShareButton,

  // Comment to sepaate, overwriting codesandbox behavior
  FacebookIcon,
  TwitterIcon,
  GooglePlusIcon,
  LinkedinIcon,
  PinterestIcon,
  VKIcon,
  WhatsappIcon,
  EmailIcon,
  LineIcon,

  // Comment to sepaate, overwriting codesandbox behavior
  FacebookShareCount,
  // GooglePlusShareCount,
  PinterestShareCount,
  VKShareCount,

  /**
   * LinkedIn Website iself doesn't support it anymore
   * Don't use it because it always return 0
   */
  // LinkedinShareCount,
} from "react-share"; // https://github.com/nygardk/react-share/

export default class SocialShare extends Component {
  // static propTypes = {
  //   channelName: PropTypes.string,
  //   channelid: PropTypes.string.isRequired,
  //   shareImage: PropTypes.string
  // };

  // static defaultProps = {
  //   channelName: "LinkedIn",
  //   channelid: "",
  //   shareImage: ""
  // };

  render() {
    // const { channelName, channelId, shareImage } = this.props;

    // const selectedPath = channelName || channelId;
    // const shareLink = channelName
    //   ? `https://www.youtube.com/user/${selectedPath}`
    //   : `https://www.youtube.com/channel/${selectedPath}`;
    // const title = `YouTube Channel - ${shareLink}`;

    // const shareLink = "https://www.matsunaga-industrial.com"
    // const title = `松永興業株式会社`;

    return (
      <section className="c-network">
        <div className="network">
          <FacebookShareButton
            url={this.props.shareLink}
            quote={this.props.title}
            className="network__share-button"
          >
            <FacebookIcon
              size="15vw" // You can use rem value isntead of numbers
              // color="#C3B189"
              iconFillColor="#C3B189"
              round
            />
          </FacebookShareButton>
          {/* 
          <FacebookShareCount url={shareLink} className="network__share-count">
            {count => count}
          </FacebookShareCount> */}
        </div>

        <div className="network">
          <TwitterShareButton
            url={this.props.shareLink}
            title={this.props.title}
            className="network__share-button"
          >
            <TwitterIcon size="15vw" round iconFillColor="#C3B189" />
          </TwitterShareButton>

          <div className="network__share-count">&nbsp;</div>
        </div>

        <div className="network">
          <LineShareButton
            url={this.props.shareLink}
            title={this.props.title}
            className="network__share-button"
          >
            <LineIcon size="15vw" round iconFillColor="#C3B189" />
          </LineShareButton>

          <div className="network__share-count">&nbsp;</div>
        </div>

        {/* <div className="network">
          <WhatsappShareButton
            url={shareLink}
            title={title}
            separator=":: "
            className="network__share-button"
          >
            <WhatsappIcon size={"2rem"} round />
          </WhatsappShareButton>

          <div className="network__share-count">&nbsp;</div>
        </div>

        <div className="network">
          <LinkedinShareButton
            url={shareLink}
            title={title}
            windowWidth={750}
            windowHeight={600}
            className="network__share-button"
          >
            <LinkedinIcon size={"2rem"} round />
          </LinkedinShareButton>
        </div> */}

        {/* <div className="network">
          <GooglePlusShareButton
            url={shareLink}
            className="network__share-button"
          >
            <GooglePlusIcon size={"2rem"} round />
          </GooglePlusShareButton>

          <GooglePlusShareCount
            url={shareLink}
            className="network__share-count"
          >
            {count => count}
          </GooglePlusShareCount>
        </div> */}

        {/* <div className="network">
          <PinterestShareButton
            url={String(window.location)}
            media={`${shareImage}`}
            windowWidth={1000}
            windowHeight={730}
            className="network__share-button"
          >
            <PinterestIcon size={"2rem"} round />
          </PinterestShareButton>

          <PinterestShareCount
            url={shareLink}
            className="network__share-count"
          />
        </div> */}

        {/* <div className="network">
          <VKShareButton
            url={shareLink}
            image={`${shareImage}`}
            windowWidth={660}
            windowHeight={460}
            className="network__share-button"
          >
            <VKIcon size={"2rem"} round />
          </VKShareButton>

          <VKShareCount url={shareLink} className="network__share-count" />
        </div> */}
        {/* <div className="network">
          <EmailShareButton
            url={shareLink}
            subject={title}
            body="body"
            className="network__share-button"
          >
            <EmailIcon size={"2rem"} round />
          </EmailShareButton>
        </div> */}
      </section>
    );
  }
}
