import { useDispatch, useSelector } from "react-redux";
import React, { FC, useEffect, useRef, useState } from "react";
import Home from "../ContainerLG/Home";
import HomeSM from "../ContainerSM/Home";
import { Helmet } from "react-helmet";
function HomeContainer() {
  const [width, set_width] = React.useState(window.innerWidth);

  function resizeListener() {
    set_width(window.innerWidth);
  }

  useEffect(() => {
    // window
    //   .jQuery(window)
    //   .off("resize")
    //   .resize(resizeListener);

    // window.removeEventListener("resize", resizeListener);
    window.addEventListener("resize", resizeListener);

    return function cleanup() {
      // window.jQuery(window).off("resize");
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  return (
    <>
      <Helmet>
        <meta
          name="keywords"
          content="松永興業株式会社,東大阪市,給湯器交換,外壁塗装"
        />
        <title>住宅設備関連、リフォームのことなら松永興業株式会社へ</title>
        <meta
          name="description"
          content="東大阪市の本社をはじめとし、奈良県に奈良支店を構え、地域の皆様のご要望により多くお応え致します。 ～リフォーム～ アフターメンテナンスをワンストップで対応。年間3000件以上の施工実績。"
        />

        <link
          rel="canonical"
          href="https://www.matsunaga-industrial.com/home"
        />
      </Helmet>

      {width > 1330 ? (
        <div id="style-lg" className="overflow-hidden">
          <Home />
        </div>
      ) : (
        <div id="style-sm" className="overflow-hidden">
          <HomeSM />
        </div>
      )}
      {/* <div className="d-none d-lg-block">
        <Home />
      </div>
      <div id ="style-sm" className="d-lg-none overflow-hidden">
        <HomeSM/>
        </div> */}
    </>
  );
}

export default HomeContainer;
