import React, { Component } from "react";
import { ReCaptcha } from "react-recaptcha-v3";

import {
  Badge,
  ButtonGroup,
  Button,
  Table,
  Form,
  FormGroup,
  UncontrolledCollapse,
  CardHeader,
  Label,
  Collapse,
  Input,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  CardImg,
  Container,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

class ExampleComponent extends Component {
  // api = false;

  componentDidMount() {}

  verifyCallback = (recaptchaToken) => {
    this.props.set_retoken(recaptchaToken);
    // alert(recaptchaToken);
    // Here you will get the final recaptchaToken!!!
    // if(this.api){
    //   alert(recaptchaToken);
    // }
    // this.api = true;
  };

  updateToken = () => {
    // you will get a new token in verifyCallback
    this.recaptcha.execute();
  };
  render() {
    return (
      <div>
        {/* <Button
          className="btn-just-icon mr-1"
          color="twitter"
          onClick={() => {
            this.updateToken();
          }}
        >
          <i className="fa fa-twitter" />
        </Button> */}
        <ReCaptcha
          ref={(ref) => (this.recaptcha = ref)}
          sitekey="6LfGIMEZAAAAAPIdQRUemj4R3GY8wtpXuojJIulx"
          action="action_name"
          verifyCallback={this.verifyCallback}
        />
      </div>
    );
  }
}

export default ExampleComponent;
