import React from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates strings
import classnames from "classnames";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// import Headroom from "react-headroom";
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavLink,
  NavItem,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";
// core components
import { useParams, useLocation, useHistory } from "react-router-dom";

let scrollvalue = 0;
let e_touchstart = 0;
let e_touchmove = 0;

function ColorNavbar({ transparent = "navbar-transparent", barcolor = "" }) {
  const history = useHistory();
  // const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [navbarColor, setNavbarColor] = React.useState(transparent);
  const [bodyClick, setBodyClick] = React.useState(false);
  const [collapseOpen, setCollapseOpen] = React.useState(false);

  const [navbar, set_navbar] = React.useState(false);

  React.useEffect(() => {
    let headroom = new Headroom(document.getElementById("navbar-main"), {
      classes: {
        //初期化するときにターゲットに適用するclass
        // initial: "headroom--initial",
        //上方にスクロールしたときにターゲットに適用するclass
        pinned: "headroom--pinned",
        //下方にスクロールしたときにターゲットに適用するclass
        unpinned: "headroom--unpinned",
      },
    });
    headroom.init();

    // let headroom = new Headroom(document.getElementById("navbar-main"));

    // 一回イベント付けたら終わりwindow.jQuery("#parallax").scroll(function)
    // 一回イベント付けたら終わりaddEventListener("scroll",function)

    window.jQuery("#parallax").scroll(function () {
      // console.log(window.jQuery(this).scrollTop())
      if (scrollvalue === window.jQuery(this).scrollTop()) {
      } else if (0 === window.jQuery(this).scrollTop()) {
        headroom.pin();
      } else if (scrollvalue > window.jQuery(this).scrollTop()) {
        headroom.pin();
      } else {
        headroom.unpin();
      }
      scrollvalue = window.jQuery(this).scrollTop();
    });

    document
    .getElementById("parallax-bar")
    .addEventListener("touchmove", function (e) {
      e.preventDefault();
    });


    document
      .getElementById("parallax")
      .addEventListener("touchstart", function (e) {
        e_touchstart = e.touches[0].pageY;
        // console.log("touchstart"+e_touchstart);
      });

    document
      .getElementById("parallax")
      .addEventListener("touchmove", function (e) {
        e_touchmove = e.changedTouches[0].pageY;

        if (
          e_touchstart > e_touchmove &&
          window.jQuery("#parallax").scrollTop() >
            window.jQuery("#parallax > div").height() -
              window.jQuery("#parallax").height() -
              1
        ) {
          e.preventDefault();
        }

        if (
          e_touchstart < e_touchmove &&
          window.jQuery("#parallax").scrollTop() <= 0
        ) {
          e.preventDefault();
        }
      });


    // let headroom = new Headroom(document.getElementById("navbar-main"));
    // // initialise
    // headroom.init();
    // const updateNavbarColor = () => {
    //   if (
    //     document.documentElement.scrollTop > 1180 ||
    //     document.body.scrollTop > 1180
    //   ) {
    //     setNavbarColor(transparent);
    //   } else if (
    //     document.documentElement.scrollTop  > 680 ||
    //     document.body.scrollTop  > 680
    //   ) {
    //     setNavbarColor("");
    //   } else if (
    //     document.documentElement.scrollTop < 680 ||
    //     document.body.scrollTop < 680
    //   ) {
    //     setNavbarColor(transparent);
    //     // setNavbarColor("navbar-transparent");
    //   }
    // };
    // window.addEventListener("scroll", updateNavbarColor);
    //   return function cleanup() {
    //   window.removeEventListener("scroll", updateNavbarColor);
    // };
  }, []);

  React.useEffect(() => {
    // let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    // headroom.init();
    // const updateNavbarColor = () => {
    //   if (
    //     document.documentElement.scrollTop > 1180 ||
    //     document.body.scrollTop > 1180
    //   ) {
    //     setNavbarColor(transparent);
    //   } else if (
    //     document.documentElement.scrollTop  > 680 ||
    //     document.body.scrollTop  > 680
    //   ) {
    //     setNavbarColor("");
    //   } else if (
    //     document.documentElement.scrollTop < 680 ||
    //     document.body.scrollTop < 680
    //   ) {
    //     setNavbarColor(transparent);
    //     // setNavbarColor("navbar-transparent");
    //   }
    // };
    // window.addEventListener("scroll", updateNavbarColor);
    // return function cleanup() {
    //   window.removeEventListener("scroll", updateNavbarColor);
    // };
  });
  return (
    <>
      {bodyClick ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setBodyClick(false);
            setCollapseOpen(false);
          }}
        />
      ) : null}
      {/* <Headroom> */}
      <Navbar
        // className={classnames("fixed-top", navbarColor)}
        className={classnames("fixed-top", "rgba(0,0,0,0.6)")}
        // className={classnames("fixed-top", "rgba(255,255,255,0.6)")}
        expand="lg"
        id="navbar-main"
        color={barcolor}
        // backgroundColor="rgba(0,0,0,0.6)"
      >
        <Container>
          <div className="navbar-translate">
            <NavbarBrand
              id="navbar-brand"
              to="/home"
              tag={Link}
              style={{ fontSize: 20, color: "#C3B189" }}
            >
              <img
                className="page-header2"
                // src={"/logo/logo2.png"}
                // src={"/logo/logo7.svg"}
                src={"/logo/logo-gold2.svg"}
                // src={"/logo/logo-gold.png"}
                style={{
                  width: 240,
                  marginLeft: -20,
                  // marginRight: 20,
                  // marginBottom: 15,
                }}
                alt="..."
              />
              {/* 松永興業株式会社 */}
            </NavbarBrand>
            {/* <UncontrolledTooltip
              placement="bottom"
              target="navbar-brand"
              style={{ fontSize: 16, color: "#C3B189" }}
            >
              松永興業株式会社
            </UncontrolledTooltip> */}
            <button
              className="navbar-toggler"
              id="navigation"
              type="button"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setBodyClick(true);
                setCollapseOpen(true);
              }}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Collapse navbar isOpen={collapseOpen}>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink
                  href="/home"
                  style={{ fontSize: 16, color: "#C3B189" }}
                  onClick={(e) => {
                    e.preventDefault();
                    history.push({
                      pathname: `/home`,
                      // state: {
                      //   content: prop.content,
                      //   title: prop.title,
                      //   date: post_date,
                      // },
                    });
                  }}
                >
                  Home
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="/about"
                  style={{ fontSize: 16, color: "#C3B189" }}
                  onClick={(e) => {
                    e.preventDefault();
                    history.push({
                      pathname: `/about`,
                      // state: {
                      //   content: prop.content,
                      //   title: prop.title,
                      //   date: post_date,
                      // },
                    });
                  }}
                >
                  ABOUT
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="/message"
                  style={{ fontSize: 16, color: "#C3B189" }}
                  onClick={(e) => {
                    e.preventDefault();
                    history.push({
                      pathname: `/message`,
                      // state: {
                      //   content: prop.content,
                      //   title: prop.title,
                      //   date: post_date,
                      // },
                    });
                  }}
                >
                  Message
                </NavLink>
              </NavItem>
            

              <NavItem>
                <NavLink
                  href="/blog"
                  style={{ fontSize: 16, color: "#C3B189" }}
                  onClick={(e) => {
                    e.preventDefault();
                    history.push({
                      pathname: `/blog`,
                      // state: {
                      //   content: prop.content,
                      //   title: prop.title,
                      //   date: post_date,
                      // },
                    });
                  }}
                >
                  News
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="/company"
                  style={{ fontSize: 16, color: "#C3B189" }}
                  onClick={(e) => {
                    e.preventDefault();
                    history.push({
                      pathname: `/company`,
                      // state: {
                      //   content: prop.content,
                      //   title: prop.title,
                      //   date: post_date,
                      // },
                    });
                  }}
                >
                  Company
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="/contact"
                  style={{ fontSize: 16, color: "#C3B189" }}
                  onClick={(e) => {
                    e.preventDefault();
                    history.push({
                      pathname: `/contact`,
                      // state: {
                      //   content: prop.content,
                      //   title: prop.title,
                      //   date: post_date,
                      // },
                    });
                  }}
                >
                  Contact
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
      {/* </Headroom> */}
    </>
  );
}

export default ColorNavbar;
