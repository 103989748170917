import React from "react";
import { Button, Container, Row, Col } from "reactstrap";
import { Transition } from "react-transition-group";
import { initReactI18next, useTranslation } from "react-i18next";

export function DivMessage1({ scroll }) {
  const [t, i18n] = useTranslation();

  return (
    <>
      <div style={{ height: "12vw" }}>
        <div className="height4vw" style={{ fontWeight: 600 }}>
          Renovation
        </div>
        <div style={{ height: "3vw" }}></div>

        <div className="height5vw" style={{ fontWeight: 400 }}>
          リノベーションで素敵なお家に
        </div>
      </div>
      <div style={{ height: "16vw" }}></div>
      <div style={{ height: "16vw" }}>
        <div className="height4vw" style={{ fontWeight: 400, opacity: 0.9 }}>
          お問い合わせ受付から営業、工事までを一貫して自社にて行うことで不要な中間コストをカット。適正な価格でのご提案を実現します。
        </div>
      </div>

      <div style={{ height: "8vw" }}></div>
      <div style={{ height: "8vw" }}>
        <div className="height4vw" style={{ fontWeight: 400, opacity: 0.9 }}>
          地域の皆様のご要望により多くお応え致します。
        </div>
      </div>
    </>
  );
}

export function DivMessage2({ scroll }) {
  const [t, i18n] = useTranslation();

  return (
    <>
      <div style={{ height: "12vw" }}>
        <div className="height4vw" style={{ fontWeight: 600 }}>
          Exterior
        </div>
        <div style={{ height: "3vw" }}></div>

        <div className="height5vw" style={{ fontWeight: 400 }}>
          外装事業
        </div>
      </div>
      <div style={{ height: "16vw" }}></div>
      <div style={{ height: "16vw" }}>
        <div className="height4vw" style={{ fontWeight: 400, opacity: 0.9 }}>
          屋根、外壁の事ならご相談下さい。お客様にとって本当に良いものとは？
          <br />
          を追求しお客様へご提案させて頂きます。
        </div>
      </div>

      <div style={{ height: "4vw" }}></div>
      <div style={{ height: "8vw" }}>
        <div className="height4vw" style={{ fontWeight: 400, opacity: 0.9 }}>
          {/* 全国展開中のガイソーのVC加盟店です。 */}
          {"　"}
        </div>
      </div>
    </>
  );
}

export function DivMessage3({ scroll }) {
  const [t, i18n] = useTranslation();

  return (
    <>
      <div style={{ height: "12vw" }}>
        <div className="height4vw" style={{ fontWeight: 600 }}>
          Interior
        </div>
        <div style={{ height: "3vw" }}></div>

        <div className="height5vw" style={{ fontWeight: 400 }}>
          内装・設備関連事業
        </div>
      </div>
      <div style={{ height: "16vw" }}></div>
      <div style={{ height: "28vw" }}>
        <div className="height4vw" style={{ fontWeight: 400, opacity: 0.9 }}>
        エコキュート・ガス給湯器・コンロを始めとして、浴室乾燥機・トイレ・洗面化粧台・食洗器・水栓まで家の内装設備のことであればすべて自社職人にて対応致します。年間３,０００件の工事実績、創業以来１万件を超える工事実績で自信を持ってご対応させて頂きます。
        </div>
      </div>

      <div style={{ height: "4vw" }}></div>
      <div style={{ height: "4vw" }}>
        <div className="height4vw" style={{ fontWeight: 400, opacity: 0.9 }}>
          {/* 全国展開中の正直屋のFC加盟店です。 */}
          {"　"}
        </div>
      </div>

      {/* 

      <div className="d-flex justify-content-center align-items-center">
        <div
          style={{
            // backgroundColor: "white",
            height: "100%",
            width: "60vw",
          }}
        >
          <p style={{ fontSize: 14, fontWeight: 600, marginTop: 20 }}>
            Interior
          </p>
          <h3 style={{ fontSize: 16, fontWeight: 400 }}>内装・設備関連事業</h3>
          <div
            style={{
              // marginTop: "6vw",
              marginTop: "8vh",
              // backgroundColor: "white",
              // height: "100%",
              // color: "#E0E0E0",
              // color: "white",
              opacity: 0.9,
            }}
          >
            <p
              style={{
                fontSize: 12,
              }}
            >
              エコキュート・ガス給湯器・コンロを始めとして、浴室乾燥機・トイレ・洗面化粧台・食洗器・水栓まで家の内装設備のことであればすべて自社職人にて対応致します。年間３,０００件の工事実績、創業以来１万件を超える工事実績で自信を持ってご対応させて頂きます。
            </p>
            <p
              style={{
                marginTop: "2vw",
                fontSize: 12,
              }}
            >
              全国展開中の正直屋のFC加盟店です。
            </p>
          </div>
        </div>
      </div> */}
    </>
  );
}
