import React, { FC, useEffect, useRef, useState } from "react";
import ReactTable from "react-table";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { HashLink } from "react-router-hashlink";
import {
  Badge,
  ButtonGroup,
  Button,
  Table,
  Form,
  FormGroup,
  UncontrolledCollapse,
  CardHeader,
  Label,
  Collapse,
  Input,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  CardImg,
  Container,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

import { initReactI18next, useTranslation } from "react-i18next";

export const CardImage = ({
  src,
}) => {
  const [t, i18n] = useTranslation();
  const dispatch = useDispatch();
  const ref = useRef();

  return (
    <>
      <Card className="card-plain card-blog d-block">
        <div className="card-image">
          <img alt="..." className="img" src={src} style={{borderRadius:0}}/>
        </div>
      </Card>
    </>
  );
};

export const CardDescription = ({
  datacolor,
  engtitle,
  title,
  description,
}) => {
  const [t, i18n] = useTranslation();
  const dispatch = useDispatch();
  const ref = useRef();

  return (
    <>
      <Card className="card-plain card-blog" >
        <CardBody>
          <h6 className={`card-category ${datacolor}`}>{engtitle}</h6>
          <CardTitle tag="h3">
            <a href="..." onClick={(e) => e.preventDefault()}>
              {title}
            </a>
          </CardTitle>
          <p className="card-description">
            {description}
            {/* <a href="..." onClick={(e) => e.preventDefault()}>
                Read More
                </a> */}
          </p>
          {/* <p className="author">
                by{" "}
                <a href="..." onClick={(e) => e.preventDefault()}>
                <b>Megan Rose Dickey</b>
                </a>
                , 11 days ago
                </p> */}
        </CardBody>
      </Card>
    </>
  );
};
