/* eslint-disable */
require("dotenv").config();

import { fork, take, put, join, select, call } from "redux-saga/effects";
import { socket } from "../AppRouter";

import axios from "axios";
axios.interceptors.request.use(function (config) {
  if (typeof config.params === "undefined") {
    config.params = {};
  }
  if (typeof config.params === "object") {
    if (
      typeof URLSearchParams === "function" &&
      config.params instanceof URLSearchParams
    )
      config.params.append("_", Date.now());
    else config.params._ = Date.now();
  }

  return config;
});

import { flow_get_entries } from "../stores_api/entries";
import { flow_get_entries_id } from "../stores_api/entries_id";
import { flow_post_contacts } from "../stores_api/contacts";
import { flow_post_postal } from "../stores_api/postal";

export function* flow_get_tokens_global() {
  if (sessionStorage.getItem("GlobalToken") === null) {
    const { data, error } = yield call(api_ApiKey, `tokens/global`, "get", {});
    if (data && !error) {
      sessionStorage.setItem("GlobalToken", data.token);
    }
  }
}

export default function* rootSaga() {
  yield fork(flow_get_entries);
  yield fork(flow_get_entries_id);
  yield fork(flow_post_contacts);
  yield fork(flow_post_postal);
}
export const api_Raw = (data) => {
  // const options = {
  //   method: "get",
  //   url: `https://api.zipaddress.net`,
  //   params: data,
  // };

    // const res = await 
    // if (200 === res.data.code) {
    //   this.$set(this.form, "address", res.data.data.fullAddress);
    // }
    // const httpsAgent = new https.Agent({ rejectUnauthorized: false });

  return axios.get(`https://zipcloud.ibsnet.co.jp/api/search?zipcode=${data.postal_code}`)
    .then((res) => {
      return res.data;
    })
    .then((data) => {
      return { data };
    })
    .catch((error) => {
      if (error.response) {
      }

      return { error };
    });
};

export const api_ApiKey = (url, method, data) => {
  var headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
  };

  return axiousCall(headers, url, method, data);
};

export const api_GlobalToken = (url, method, data) => {
  const globalToken = sessionStorage.getItem("GlobalToken");
  var headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${globalToken}`,
  };
  return axiousCall(headers, url, method, data);
};

export const api_AccessToken = (url, method, data, multipart = false) => {
  const accessToken = sessionStorage.getItem("AccessToken");
  let headers = {};
  if (multipart) {
    headers = {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${accessToken}`,
    };
  } else {
    headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
  }

  return axiousCallAccess(headers, url, method, data, multipart);
};

export const getOptions = (headers, url, method, data, multipart = false) => {
  if (method === "get") {
    const options = {
      method,
      url: `${process.env.REACT_APP_API_URL}${url}`,
      headers,
      params: data,
    };

    return options;
  } else {
    const options = {
      method,
      url: `${process.env.REACT_APP_API_URL}${url}`,
      headers,
      data,
    };

    return options;
  }
};

export const axiousCall = (headers, url, method, data, multipart) => {
  return axios(getOptions(headers, url, method, data))
    .then((res) => {
      return res.data;
    })
    .then((data) => {
      return { data };
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          sessionStorage.removeItem("GlobalToken");
          sessionStorage.removeItem("AccessToken");
          // window.location.href = "/";
        }
      }

      return { error };
    });
};

export const axiousCallAccess = (headers, url, method, data, multipart) => {
  const options = getOptions(headers, url, method, data, multipart);

  return axios(options)
    .then((res) => {
      if (method === "get") {
      } else {
        if (process.env.mutable_token === "true") {
          const mAtoken = res.headers["x-mat-id"];
          sessionStorage.setItem("AccessToken", mAtoken);
          // socket.extraHeaders={'AccessToken': mAtoken}
        }
      }
      return res.data;
    })
    .then((data) => {
      return { data };
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          sessionStorage.removeItem("GlobalToken");
          sessionStorage.removeItem("AccessToken");
          // window.location.href = "/";
        }
      }

      return { error };
    });
};

export function err(error) {
  if (error.response) {
    const data = error.response.data;
    if (data) {
      if (data.errors) {
        let err1 = data.errors[Object.keys(data.errors)[0]];
        return err1;
      }
    }
  }
  return error.message;
}
