import React, { FC, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NotificationAlert from "react-notification-alert";
import { initReactI18next, useTranslation } from "react-i18next";
import ColorNavbar from "./Navbars/ColorNavbar";
import { Parallax, ParallaxLayer } from "react-spring/renderprops-addons";

// import SectionFooterAreas from "./Component/SectionFooterAreas.js";
// import SectionFooterAreas from "./Component/SectionFooterAreas.js";
import SectionFooterAreas2 from "./Component/SectionFooterAreas2.js";
import SectionFooterAreas3 from "./Component/SectionFooterAreas3.js";

import Carousel from "./Component/Carousel.js";
import { useInView } from "react-intersection-observer";
import {
  Badge,
  ButtonGroup,
  Button,
  Table,
  Form,
  FormGroup,
  UncontrolledCollapse,
  CardHeader,
  Label,
  Collapse,
  Input,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  CardImg,
  Container,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import {
  ContainerSMART1,
  ContainerSMART2,
  ContainerSMART3,
  ContainerSMART4,
  ContainerFullImage
} from "./Message/ContainerSMART";

import {
  // ContainerHead,

  ContainerLeftImage,
  ContainerRightImage,
  ContainerLeftImage2,
} from "./Message/Container";

import {
  ContainerHead,
  ContainerLeftRectangle,
  ContainerRightRectangle,
} from "./Common/Container";

import { Decimal } from "decimal.js";

function DashBoard() {
  const [t, i18n] = useTranslation();
  const ref = useRef();
  document.documentElement.classList.remove("nav-open");

  let parallax = null;

  const [refContainerHead, inContainerHead] = useInView();
  const [refContainerLeftImage, inContainerLeftImage] = useInView();
  const [refContainerRightImage, inContainerRightImage] = useInView();
  const [refContainerLeftImage2, inContainerLeftImage2] = useInView();
  const [refContainerRightImage2, inContainerRightImage2] = useInView();

  const [ph1, set_ph1] = useState(0);
  const [ph2, set_ph2] = useState(0);
  const [ph3, set_ph3] = useState(0);
  const [ph4, set_ph4] = useState(0);
  const [ph5, set_ph5] = useState(0);
  const [ph6, set_ph6] = useState(0);
  // const [ph7, set_ph7] = useState(0);
  // const [ph8, set_ph8] = useState(0);
  // const [ph9, set_ph9] = useState(0);
  // const [ph10, set_ph10] = useState(0);

  const ph = () => {
    const phfunc = () => {
      // const wi = window.innerHeight;
      // const wi = document.documentElement.clientHeight;

      const vh = window.jQuery("#parallax").height();

      const ph0 = window.jQuery("#ph0").height();
      const ph1 = window.jQuery("#ph1").height() + ph0;
      const ph2 = window.jQuery("#ph2").height() + ph1;
      const ph3 = window.jQuery("#ph3").height() + ph2;
      const ph4 = window.jQuery("#ph4").height() + ph3;
      const ph5 = window.jQuery("#ph5").height() + ph4;
      // const ph6 = window.jQuery("#ph6").height() + ph5;
      // const ph7 = window.jQuery("#ph7").height() + ph6;
      // const ph8 = window.jQuery("#ph8").height() + ph7;
      // const ph9 = window.jQuery("#ph9").height() + ph8;
      // const ph10 = window.jQuery("#ph10").height() + ph9;

      if (ph0) {
        set_ph1(ph0 / vh);
        set_ph2(ph1 / vh);
        set_ph3(ph2 / vh);
        set_ph4(ph3 / vh);
        set_ph5(ph4 / vh);
        set_ph6(ph5 / vh);
        // set_ph7(ph6 / vh);
        // set_ph8(ph7 / vh);
        // set_ph9(ph8 / vh);
        // set_ph10(ph9 / vh);
      }
    };
    setTimeout(phfunc, 1000);
    setTimeout(phfunc, 2000);
  };

  useEffect(() => {
    ph();
  }, []);

  return (
    <>
      <div id="parallax-bar">
        <NotificationAlert ref={ref} />
        <ColorNavbar
          title="住宅設備関連、リフォームのことなら松永興業株式会社へ"
          shareLink="https://www.matsunaga-industrial.com/message"
        />
      </div>

      <div style={{ position: "relative", height: "100vh" }}>
        <Parallax
          id="parallax"
          ref={(ref) => {
            return (parallax = ref);
          }}
          // config={{
          //   tension: 0,
          //   friction: 0,
          // }}
          // pages={ph5}
          pages={ph6}
          config={{ tension: 280, friction: 60 }}
        >
          {/* ================== */}
          <ParallaxLayer id="ContainerHead" offset={0} factor={1} speed={0}>
            <div ref={refContainerHead} style={{ height: "200vh" }}>
              <div id="ph0">
                <ContainerHead
                  inview={inContainerHead}
                  title="Message"
                  subtitle="あなたの想いをかたちに"
                  background={{
                    // backgroundImage: { src },
                    backgroundImage:
                      "url(" +
                      require("assets/img/envato/close-hand-min.jpg") +
                      ")",
                    // backgroundPosition: "0vw -15vh",
                    backgroundSize: "cover",
                    // backgroundSize: "78%",
                    // backgroundRepeat: "no-repeat",
                  }}
                />
              </div>
            </div>
          </ParallaxLayer>
          {/* ================== */}
          <ParallaxLayer
            id="GREETINGSRectangle"
            offset={ph1 + 0.9}
            speed={0.8}
            factor={1}
          >
            <ContainerRightRectangle />
          </ParallaxLayer>

          <ParallaxLayer
            id="PHILOSOPHYRectangle"
            offset={ph2 + 0.5}
            speed={0.8}
            factor={1}
          >
            <ContainerLeftRectangle />
          </ParallaxLayer>

          <ParallaxLayer
            id="POLICYRectangle"
            offset={ph3 + 0.9}
            speed={0.8}
            factor={1}
          >
            <ContainerRightRectangle />
          </ParallaxLayer>

          <ParallaxLayer
            id="GUIDELINESRectangle"
            offset={ph4 + 0.5}
            speed={0.8}
            factor={1}
          >
            <ContainerLeftRectangle />
          </ParallaxLayer>

          {/* ================== */}
          <ParallaxLayer id="GREETINGS" offset={ph1} factor={1} speed={0}>
            <div ref={refContainerRightImage2} style={{ height: "1000vh" }}>
              <div id="ph1">
                <ContainerSMART1 inview={inContainerRightImage2} />
                {/* <ContainerFullImage inview={inContainerRightImage2} /> */}

              </div>
            </div>
          </ParallaxLayer>

          <ParallaxLayer id="PHILOSOPHY" offset={ph2} factor={1} speed={0}>
            <div ref={refContainerLeftImage} style={{ height: "100vh" }}>
              <div id="ph2">
                <ContainerSMART2 inview={inContainerLeftImage} />
              </div>
            </div>
          </ParallaxLayer>
          {/* ================== */}

          <ParallaxLayer id="POLICY" offset={ph3} factor={1} speed={0}>
            <div ref={refContainerRightImage} style={{ height: "1000vh" }}>
              <div id="ph3">
                <ContainerSMART3 inview={inContainerRightImage} />
              </div>
            </div>
          </ParallaxLayer>
          {/* ================== */}

          <ParallaxLayer id="GUIDELINES" offset={ph4} factor={1} speed={0}>
            <div ref={refContainerLeftImage2} style={{ height: "100vh" }}>
              <div id="ph4">
                <ContainerSMART4 inview={inContainerLeftImage2} />
              </div>
            </div>
          </ParallaxLayer>
          {/* ================== */}

          <ParallaxLayer
            id="CarouselSectionFooterAreas"
            offset={ph5}
            // offset={5}
            speed={0}
            factor={1}
          >
            {/* <div
              className="d-flex justify-content-center align-items-center "
              style={{ height: "20vh", width: "100vw" }}
            > */}
            {/* <Carousel /> */}
            {/* </div> */}
            {/* <div
              style={{ height: "50vh", width: "100vw" }}
            >
            <SectionFooterAreas2 title="住宅設備関連、リフォームのことなら松永興業株式会社へ" shareLink="https://www.matsunaga-industrial.com/message"/>
            </div> */}
            <div id="ph5">
              <SectionFooterAreas3
                title="住宅設備関連、リフォームのことなら松永興業株式会社へ"
                shareLink="https://www.matsunaga-industrial.com/message"
              />
            </div>
          </ParallaxLayer>
        </Parallax>
      </div>
    </>
  );
}
export default DashBoard;
