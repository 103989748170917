import React, { FC, useEffect, useRef, useState } from "react";
import ReactTable from "react-table";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { HashLink } from "react-router-hashlink";
import {
  Badge,
  ButtonGroup,
  Button,
  Table,
  Form,
  FormGroup,
  UncontrolledCollapse,
  CardHeader,
  Label,
  Collapse,
  Input,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  CardImg,
  Container,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

import { initReactI18next, useTranslation } from "react-i18next";

const AppContainer = ({
  pathname,
  hash,
  datacolor,
  engtitle,
  title,
  description,
}) => {
  const [t, i18n] = useTranslation();
  const dispatch = useDispatch();
  const ref = useRef();

  return (
    <>
      <div className="card-big-shadow h-100">
        {/* <HashLink
          to={{
            pathname,
            hash,
          }}
          behavior="smooth"
          delay={500}
        > */}
          <Card
            className="card-just-text h-100"
            data-color={datacolor}
            data-radius="none"
          >
            <CardBody className="pb-0 px-4">
              <h6 className="card-category text-danger">{engtitle}</h6>
              <CardTitle tag="h4">{title}</CardTitle>
              <p className="card-description">{description}</p>
            </CardBody>
          </Card>
        {/* </HashLink> */}

        {/* end card */}
      </div>
    </>
  );
};
export default AppContainer;
