import {
  api_GlobalToken,
  api_AccessToken,
  flow_get_tokens_global,
  err,
  api_ApiKey
} from "../stores/sagas";
import { fork, take, put, join, select, call } from "redux-saga/effects";
import { createAction, createReducer } from "redux-act";

export const get_entries_id = createAction("get_entries_id");

export const fetchingFalse = createAction("fetchingFalse");
export const loadingFalse = createAction("loadingFalse");
export const loadingDataFalse = createAction("loadingDataFalse");

export const failure_entries_id = createAction("failure_entries_id");

export const entries_id = createReducer(
  {
    [get_entries_id]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isFetching = true;
      return newState;
    },
    [fetchingFalse]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });
      newState.isFetching = false;
      return newState;
    },
    [loadingDataFalse]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });
      newState.isLoading = false;
      return newState;
    },
    [loadingFalse]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
    isFetching: true,
    entries: [],
  }
);


export function* flow_get_entries_id() {
  while (true) {
    const { payload } = yield take(get_entries_id);

    const { data, error } = yield call(
      api_ApiKey,
      `entries/${payload.data.entry_id}`,
      "get",
      payload.data
    );
    if (!error) {
      yield put(fetchingFalse({ data }));
      !payload.page || payload.page();
    } else {
      yield put(fetchingFalse({ error }));
      !payload.toast || payload.toast(err(error));
    }
  }
}
