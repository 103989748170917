import React, { FC, useEffect, useRef, useState } from "react";

import Lightgallery from "../About/Lightgallery";
import BeforeAfter from "../About/BeforeAfter";
import { useInView } from "react-intersection-observer";

import { useSpring, animated, interpolate } from "react-spring";
// 親要素がabsoluteであることから、ContainerやRowはすべて高さが０である
// このことを利用して、Rowを重ねていこう

import { useDispatch, useSelector } from "react-redux";
import { ReCaptcha } from "react-recaptcha-v3";
import ReactBSAlert from "react-bootstrap-sweetalert";
import {
  Badge,
  ButtonGroup,
  Button,
  Table,
  Form,
  FormGroup,
  UncontrolledCollapse,
  CardHeader,
  Label,
  Collapse,
  Input,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  CardImg,
  Container,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Row,
  Col,
  UncontrolledTooltip,
  Spinner,
} from "reactstrap";
import notification from "views/Basic/notification";
import info from "views/Basic/info";
import NotificationAlert from "react-notification-alert";

import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";
import ReCaptchaApp from "../Contact/react-recaptcha";

import { post_contacts } from "stores_api/contacts";
import { post_postal } from "stores_api/postal";

// 子要素を無視するabsoluteとfixed
// 子要素を計算して自分の大きさを決めるrelativeとstatic

export function ContainerHead({ inview }) {
  // { opa, tra, clip }
  let { opa, flowright, flowleft, transxleft, transytop } = useSpring({
    opa: inview ? 1 : 0,
    flowright: inview
      ? "rect(0vh, 100vw, 100vh, 0vw)"
      : "rect(0vh, 0vw, 100vh, 0vw)",
    flowleft: inview
      ? "rect(0vh, 100vw, 100vh, 0vw)"
      : "rect(0vh, 100vw, 100vh, 100vw)",
    // transxleft: inview ? "translate3d(0px, 0, 0)" : "translate3d(70px, 0, 0)",
    transxleft: inview ? "translateX(0)" : "translateX(70px)",
    transxright: inview ? "translateX(0)" : "translateX(-70px)",
    transytop: inview ? "translateY(0)" : "translateY(70px)",
  });

  return (
    <Container fluid>
      <Row>
        <Col lg="12" className="px-0">
          <animated.div
            style={{
              // transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
              transition: "all 800ms ease",
              opacity: opa.interpolate((p) => p),
              clip: flowright.interpolate((p) => p),
              position: "fixed",
            }}
          >
            <div
              style={{ position: "absolute", height: "70vh", width: "100vw" }}
              className="black-cool"
            ></div>
          </animated.div>
        </Col>
      </Row>
      <Row>
        <Col lg="2" className="px-0"></Col>
        <Col lg="10" className="px-0">
          <animated.div
            style={{
              // transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
              transition: "all 800ms ease",
              transitionDelay: "0.3s",
              transform: transxleft.interpolate((p) => p),
              opacity: opa.interpolate((p) => p),
              // clip: flowleft.interpolate((p) => p),
              position: "fixed",
            }}
          >
            <div
              className="backgroundImage-80"
              style={{
                position: "absolute",
                left: "5vw",
                top: "15vh",
                width: "100vw",
                hiight: "100vh",
                minHeight: "65vh",
                maxHeight: "65vh",
                backgroundImage:
                  // "url(" + require("assets/img/envato/handshake2.jpg") + ")",
                  "url(" +
                  require("assets/img/envato/concept.jpg") +
                  // require("assets/img/close-cool.jpg") +
                  // require("assets/img/handshake2.jpg") +

                  ")",
              }}
            ></div>
            <div
              className="backgroundImage-80"
              style={{
                position: "absolute",
                left: "5vw",
                top: "15vh",
                width: "100vw",
                hiight: "100vh",
                minHeight: "65vh",
                maxHeight: "65vh",
                backgroundColor: "rgba(0, 0, 0, 0.2)",
              }}
            ></div>
          </animated.div>

          {/* <div
              style={{
                position: "absolute",
                left: "5vw",
                top: "15vh",
                height: "65vh",
                width: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.2)",
              }}
            ></div> */}
        </Col>
      </Row>
      <Row>
        <Col lg="6" style={{ paddingLeft: 0 }}>
          <animated.div
            style={{
              // transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
              transition: "all 800ms ease",
              transitionDelay: "0.3s",
              transform: transytop.interpolate((p) => p),
              opacity: opa.interpolate((p) => p),
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "25vh",
                left: "10vw",
                // width: "100vw",
                // hiight: "100vh",
                // backgroundColor: "white",
              }}
            >
              <h1
                style={{
                  // color: "white",
                  color: "#C3B189",
                  fontWeight: 600,
                  letterSpacing: 4,
                }}
              >
                Company
              </h1>
              <p
                style={{
                  // color: "white",
                  color: "#C3B189",
                  // fontWeight: 600,
                  letterSpacing: 1,
                  paddingTop: 10,
                }}
              >
                会社概要
              </p>
            </div>
          </animated.div>
        </Col>
      </Row>
    </Container>
  );
}

export function ContainerInfo({ refnotify }) {
  const dispatch = useDispatch();

  // const ref = useRef();
  const [retoken, set_retoken] = React.useState();
  const [modalMembers, set_modalMembers] = useState(false);
  const [alert, set_alert] = useState();
  const [recaptcha, set_recaptcha] = React.useState();

  const postal = useSelector((state) => state.postal);
  const inLoading_postal = postal.isLoading;

  const contacts = useSelector((state) => state.contacts);
  const inLoading_contacts = contacts.isLoading;

  React.useEffect(() => {
    window.HeightEqual();
  }, []);

  const toast = (inMessage) => {
    notification(refnotify.current, inMessage);
  };

  const toastinfo = () => {
    info(refnotify.current, "お問合せのメールを送信致しました。");
  };

  const titleAndTextAlert = () => {
    set_alert(
      // <ReactBSAlert
      //   style={{ display: "block", marginTop: "-100px" }}
      //   title="メールを送信しました。"
      //   onConfirm={() => set_alert(null)}
      //   onCancel={() => set_alert(null)}
      //   confirmBtnBsStyle="info"
      // >
      //   控えのメールをお送りしています。
      // </ReactBSAlert>
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="メールを送信しました。"
        onConfirm={() => set_alert(null)}
        onCancel={() => set_alert(null)}
        confirmBtnBsStyle="info"
      >
        {/* のち程ご連絡致します。 */}
      </ReactBSAlert>
    );
  };

  const on_first_name = (val) => {
    return val;
  };

  const on_last_name = (val) => {
    return val;
  };
  const on_tel = (val) => {
    return val;
  };

  const on_mail_address = (val) => {
    var emailRex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(val)) {
      return true;
    }
    return false;
  };

  const valid = () => {
    if (first_nameState !== "has-success") {
      set_first_nameState("has-danger");
    }
    if (last_nameState !== "has-success") {
      set_last_nameState("has-danger");
    }
    if (mail_addressState !== "has-success") {
      set_mail_addressState("has-danger");
    }
    if (telState !== "has-success") {
      set_telState("has-danger");
    }
    // if (messageState !== "has-success") {
    //   set_messageState("has-danger");
    // }

    if (
      true &&
      first_nameState === "has-success" &&
      last_nameState === "has-success" &&
      mail_addressState === "has-success" &&
      telState === "has-success"
      // messageState === "has-success"
    ) {
      return true;
    } else {
      toast("必須項目を入力してください。");
      return false;
    }
  };

  const [first_name, set_first_name] = React.useState("");
  const [last_name, set_last_name] = React.useState("");
  const [mail_address, set_mail_address] = React.useState("");
  const [tel, set_tel] = React.useState("");
  const [message, set_message] = React.useState("");

  const [first_nameState, set_first_nameState] = React.useState("");
  const [last_nameState, set_last_nameState] = React.useState("");
  const [mail_addressState, set_mail_addressState] = React.useState("");
  const [telState, set_telState] = React.useState("");
  const [messageState, set_messageState] = React.useState("");

  const [postal_code, set_postal_code] = React.useState("");
  const [address1, set_address1] = React.useState("");
  const [address2, set_address2] = React.useState("");

  const [refDiv1, inDiv1] = useInView();
  const [refDiv2, inDiv2] = useInView();
  const [refDiv3, inDiv3] = useInView();
  const [refDiv4, inDiv4] = useInView();
  const [refDiv5, inDiv5] = useInView();
  const [refDiv6, inDiv6] = useInView();

  React.useEffect(() => {}, []);
  let { opa1, trans1 } = useSpring({
    opa1: inDiv1 ? 1 : 0,
    trans1: inDiv1 ? "translateY(0)" : "translateY(70px)",
  });
  let { opa2, trans2 } = useSpring({
    opa2: inDiv2 ? 1 : 0,
    trans2: inDiv2 ? "translate3d(0px, 0, 0)" : "translate3d(100px, 0, 0)",
  });
  let { opa3, trans3 } = useSpring({
    opa3: inDiv3 ? 1 : 0,
    trans3: inDiv3 ? "translate3d(0px, 0, 0)" : "translate3d(-100px, 0, 0)",
  });
  let { opa4, trans4 } = useSpring({
    opa4: inDiv4 ? 1 : 0,
    trans4: inDiv4 ? "translateY(0)" : "translateY(70px)",
  });
  let { opa5, trans5 } = useSpring({
    opa5: inDiv5 ? 1 : 0,
    trans5: inDiv5 ? "translateY(0)" : "translateY(70px)",
  });
  let { opa6, trans6 } = useSpring({
    opa6: inDiv6 ? 1 : 0,
    trans6: inDiv6 ? "translate3d(0px, 0, 0)" : "translate3d(100px, 0, 0)",
  });

  return (
    <>
      {alert}

      <Container style={{ paddingBottom: 100 }}>
        <Row>
          <Col className="ml-auto mr-auto text-center" md="8">
            <h2 className="title">Please contact us</h2>
            <h5 className="description">
              どんな小さなことでも構いません。是非、弊社にご相談ください。
            </h5>
          </Col>
        </Row>
        <div className="space-top" />
        {/* <Row>
      <Col className="ml-auto mr-auto text-center" md="6">
        <h3 className="title">
          <small>Find us on social networks</small>
        </h3>
        <Button className="btn-just-icon mr-1" color="twitter">
          <i className="fa fa-twitter" />
        </Button>
        <Button className="btn-just-icon mr-1" color="facebook">
          <i className="fa fa-facebook" />
        </Button>
        <Button className="btn-just-icon mr-1" color="google">
          <i className="fa fa-google" />
        </Button>
        <Button className="btn-just-icon mr-1" color="dribbble">
          <i className="fa fa-dribbble" />
        </Button>
        <Button className="btn-just-icon mr-1" color="instagram">
          <i className="fa fa-instagram" />
        </Button>
        <Button className="btn-just-icon" color="youtube">
          <i className="fa fa-youtube" />
        </Button>
      </Col>
    </Row> */}
        <Row>
          <Col lg="8">
            <CardBody>
              <div
                className="info info-horizontal"
                style={{ marginLeft: 0, maxWidth: 1000 }}
              >
                <div className="icon icon-info">
                  <i className="nc-icon nc-pin-3" />
                </div>
                <div className="description" style={{ color: "#66615b" }}>
                  <h4>住所</h4>
                  <p style={{ whiteSpace: "nowrap" }}>
                    <table style={{ marginBottom: 10 }}>
                      <tr>
                        <th style={{ width: "25%" }}>本社</th>
                        <td>大阪府東大阪市荒川３丁目２５番１９号</td>
                      </tr>
                      <tr>
                        <th style={{ width: "25%" }}>北大阪店</th>
                        <td>大阪府高槻市富田町６丁目１５番２号</td>
                      </tr>
                      <tr>
                        <th style={{ width: "25%" }}>京都城陽店</th>
                        <td>京都府城陽市寺田樋尻１２番地７４</td>
                      </tr>
                      <tr>
                        <th style={{ width: "25%" }}>奈良店</th>
                        <td>奈良県大和郡山市小林町西１丁目４番２号</td>
                      </tr>
                      <tr>
                        <th style={{ width: "25%" }}>桜井店</th>
                        <td>奈良県桜井市川合２５６ー６南野駅前ビル１階</td>
                      </tr>
                      <tr>
                        <th style={{ width: "25%" }}>和歌山店</th>
                        <td>和歌山県和歌山市島橋東ノ丁１−１</td>
                      </tr>
                      <tr>
                        <th style={{ width: "25%" }}>東京オフィス</th>
                        <td>
                          東京都中央区日本橋室町１丁目１１番１２号
                          <br />
                          日本橋水野ビル７階
                        </td>
                      </tr>
                    </table>
                  </p>
                </div>
              </div>
            </CardBody>
          </Col>
          <Col lg="4">
            <CardBody>
              <div
                className="info info-horizontal"
                style={{ marginLeft: 0, maxWidth: 1000 }}
              >
                <div className="icon icon-danger">
                  <i className="nc-icon nc-badge" />
                </div>
                <div className="description">
                  <h4 className="info-title">電話番号</h4>
                  <p>
                    <dl class="row">
                      <dt class="col-sm-2">TEL</dt>
                      <dd class="col-sm-10">０６-４３０９-５５５０</dd>
                    </dl>
                    <dl class="row">
                      <dt class="col-sm-2">FAX</dt>
                      <dd class="col-sm-10">０６-４３０９-５５１０</dd>
                    </dl>
                  </p>
                </div>
              </div>
            </CardBody>
          </Col>
        </Row>
        <Row>
          <Col lg="2"></Col>
          <Col lg="8">
            {/* <Form id="contact-form" method="post" role="form"> */}
            <CardBody>
              <div
                className="info info-horizontal  d-flex justify-content-center align-items-center"
                style={{ marginLeft: 0, maxWidth: 1400 }}
              >
                <div className="icon icon-primary">
                  <i className="nc-icon nc-email-85" />
                </div>
                <div className="description">
                  <h4 className="info-title">
                    下記フォームより気軽にお問い合わせください。
                  </h4>
                </div>
              </div>
              <Row>
                <Col lg="6">
                  <FormGroup className={`has-label ${first_nameState}`}>
                    <label className="control-label">お名前</label>
                    <Input
                      name="name1"
                      placeholder="例）田中太郎"
                      type="text"
                      value={first_name}
                      onChange={(e) => {
                        const val = e.target.value;
                        set_first_name(val);
                        if (on_first_name(val)) {
                          set_first_nameState("has-success");
                        } else {
                          set_first_nameState("has-danger");
                        }
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup className={`has-label ${last_nameState}`}>
                    <label className="control-label">フリガナ</label>
                    <Input
                      name="name2"
                      placeholder="例）タナカタロウ"
                      type="text"
                      value={last_name}
                      onChange={(e) => {
                        const val = e.target.value;
                        set_last_name(val);
                        if (on_last_name(val)) {
                          set_last_nameState("has-success");
                        } else {
                          set_last_nameState("has-danger");
                        }
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup className={`has-label ${mail_addressState}`}>
                <label className="control-label">メールアドレス</label>
                <Input
                  name="email"
                  placeholder="例）XXX@gmail.com"
                  type="email"
                  value={mail_address}
                  onChange={(e) => {
                    const val = e.target.value;
                    set_mail_address(val);
                    if (on_mail_address(val)) {
                      set_mail_addressState("has-success");
                    } else {
                      set_mail_addressState("has-danger");
                    }
                  }}
                />
              </FormGroup>
              <FormGroup className={`has-label ${telState}`}>
                <label className="control-label">電話番号</label>
                <Input
                  name="tel"
                  placeholder="例）080XXXXYYYY"
                  type="tel"
                  style={{ fontSize: 16 }}
                  value={tel}
                  onChange={(e) => {
                    const val = e.target.value;
                    set_tel(val);
                    if (on_tel(val)) {
                      set_telState("has-success");
                    } else {
                      set_telState("has-danger");
                    }
                  }}
                />
              </FormGroup>
              <Row>
                <Col lg="6">
                  <FormGroup className="label-floating">
                    <label className="control-label">郵便番号</label>
                    <Input
                      name="postal"
                      placeholder="例）5770843"
                      type="text"
                      style={{ fontSize: 16 }}
                      value={postal_code}
                      onChange={(e) => {
                        const val = e.target.value;
                        set_postal_code(val);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  {/* <div className="d-flex justify-content-center align-items-end h-100"> */}
                  <FormGroup className="label-floating">
                    <label className="control-label">　</label>
                    <br />
                    <Button
                      // block
                      style={{ display: "inline-block" }}
                      color="primary"
                      onClick={() => {
                        dispatch(
                          post_postal({
                            data: {
                              zipcode: postal_code,
                            },
                            toast: (inMessage) => {
                              toast(inMessage);
                            },
                            page: (data) => {
                              set_address1(data.address);
                            },
                          })
                        );
                      }}
                    >
                      {inLoading_postal && (
                        <Spinner className="position-relative" size="sm" />
                      )}
                      {!inLoading_postal && <span>住所検索</span>}
                    </Button>
                  </FormGroup>

                  {/* </div> */}
                </Col>
              </Row>
              <FormGroup className="label-floating">
                <label className="control-label">住所１</label>
                <Input
                  name="address1"
                  placeholder="例）大阪府東大阪市荒川"
                  type="text"
                  style={{ fontSize: 16 }}
                  value={address1}
                  onChange={(e) => {
                    const val = e.target.value;
                    set_address1(val);
                  }}
                />
              </FormGroup>
              <FormGroup className="label-floating">
                <label className="control-label">住所２</label>
                <Input
                  name="address2"
                  placeholder="例）３丁目２５番１９号"
                  type="text"
                  style={{ fontSize: 16 }}
                  value={address2}
                  onChange={(e) => {
                    const val = e.target.value;
                    set_address2(val);
                  }}
                />
              </FormGroup>
              <FormGroup className="label-floating">
                <label className="control-label">お問合せ内容</label>
                <Input
                  id="message"
                  name="message"
                  placeholder="ご相談内容をご記入ください"
                  type="textarea"
                  rows="12"
                  style={{ resize: "none" }}
                  value={message}
                  onChange={(e) => {
                    const val = e.target.value;
                    set_message(val);
                  }}
                />
              </FormGroup>
              <Row>
                {/* <Col lg="6"> */}
                {/* <FormGroup check>
                  <Label check>
                    <Input defaultValue="" type="checkbox" />
                    ロボットではありません{" "}
                    <span className="form-check-sign" />
                  </Label>
                </FormGroup> */}
                {/* <GoogleReCaptchaProvider reCaptchaKey="6LfGIMEZAAAAAJTH6IY8hkAXgqikSmt9GB5nrjBO">
                  <GoogleReCaptcha
                    onVerify={(token) => alert(token)}
                  />
                </GoogleReCaptchaProvider> */}
                {/* </Col> */}
                <Col lg="12" className="text-center">
                  <Button
                    // className="pull-right"
                    color="primary"
                    block
                    // type="submit"
                    onClick={() => {
                      // set_modalMembers(true);
                      // titleAndTextAlert();
                      if (!valid()) return;

                      dispatch(
                        post_contacts({
                          data: {
                            first_name: first_name,
                            last_name: last_name,
                            mail_address: mail_address,
                            message: message,
                            tel: tel,
                            postal_code: postal_code,
                            address: `${address1}${address2}`,
                            // retoken: retoken,
                            // reaction: "action_name",
                          },
                          toast: (inMessage) => {
                            toast(inMessage);
                            // recaptcha.execute();
                          },
                          page: () => {
                            // titleAndTextAlert();
                            set_first_name("");
                            set_last_name("");
                            set_mail_address("");
                            set_tel("");

                            set_message("");

                            set_postal_code("");
                            set_address1("");
                            set_address2("");

                            toastinfo();
                            // recaptcha.execute();
                          },
                        })
                      );
                    }}
                  >
                    {inLoading_contacts && (
                      <Spinner className="position-relative" size="sm" />
                    )}
                    {!inLoading_contacts && <span>お問合せを送信</span>}
                  </Button>

                  {/* <ReCaptchaApp set_retoken={set_retoken} /> */}
                  {/* <ReCaptcha
                  ref={(ref) => set_recaptcha(ref)}
                  sitekey="6LfGIMEZAAAAAPIdQRUemj4R3GY8wtpXuojJIulx"
                  action="action_name"
                  verifyCallback={(recaptchaToken) => {
                    set_retoken(recaptchaToken);
                  }}
                /> */}
                  {/* <ReCaptchaApp /> */}
                </Col>
              </Row>
            </CardBody>
            {/* </Form> */}
          </Col>
          <Col lg="2"></Col>
        </Row>
      </Container>
    </>
  );
}
