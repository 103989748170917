import React, { FC, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import App from "./entries";

import NotificationAlert from "react-notification-alert";

import { get_entries } from "stores_api/entries";
import {
  BrowserRouter,
  Switch,
  Route,
  useParams,
  useLocation,
  useHistory,
} from "react-router-dom";
import { initReactI18next, useTranslation } from "react-i18next";
import notification from "views/Basic/notification";

const AppContainer = ({scrollTo,ph}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  // alert(history)
  const ref = useRef();
  const [t, i18n] = useTranslation();

  useEffect(() => {}, []);

  const toast = (inMessage) => {
    notification(ref.current, inMessage);
  };

  const entries = useSelector((state) => state.entries);
  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={ref} />
      </div>
      <App
      scrollTo={scrollTo}
        t={t}
        history={history}
        on_get_entries={({ data, page }) =>
          dispatch(
            get_entries({
              data,
              toast,
              page:()=>{
                ph();
              },
            })
          )
        }
        entries={entries}
      />
    </>
  );
};
export default AppContainer;
