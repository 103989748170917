import React, { useState, useEffect } from "react";
import { useSpring, useTransition, animated, config, interpolate } from "react-spring";

const slides = [
  {
    id: 0,
    url: `url(${require("assets/img/envato/back1-min.jpg")})`,
  },
  {
    id: 1,
    url: `url(${require("assets/img/envato/back2-min.jpg")})`,
  },
  {
    id: 2,
    url: `url(${require("assets/img/envato/back3-min.jpg")})`,
  },
];

function Topimagetrans() {
  const [index, set] = useState(0);
  const transitions = useTransition(slides[index], (item) => item.id, {
    from: { opacity: 0, transform: "scale(1, 1)" },
    enter: { opacity: 1, transform: "scale(1.1, 1.1)" },
    leave: { opacity: 0, transform: "scale(1, 1)" },
    config: config.molasses,
  });

  React.useEffect(() => void setInterval(() => set((state) => (state + 1) % 3), 4000), []);

  return (
    <>
      {transitions.map(({ item, props, key }) => (
        <animated.div
          key={key}
          class="bg"
          style={{
            ...props,
            backgroundImage: item.url,
            position: "absolute",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundSize: "cover",
            backgroundPosition: "center",
            willChange: "opacity ,transform",
          }}
        />
      ))}
      <div
        style={{
          height: "100vh",
        }}
      ></div>
    </>
  );
}

export default Topimagetrans;
