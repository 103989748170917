import React, { FC, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NotificationAlert from "react-notification-alert";
import { initReactI18next, useTranslation } from "react-i18next";
import ColorNavbar from "./Navbars/ColorNavbar";
import { Parallax, ParallaxLayer } from "react-spring/renderprops-addons";

import SectionFooterAreas2 from "./Component/SectionFooterAreas2.js";
import SectionFooterAreas from "./Component/SectionFooterAreas.js";
import SectionFooterAreas3 from "./Component/SectionFooterAreas3.js";

import Carousel from "./Component/Carousel.js";
import { useInView } from "react-intersection-observer";

import {
  ContainerHead,
  ContainerLeftRectangle,
  ContainerRightRectangle,
} from "./Common/Container";

import { ContainerBlogDetail } from "./BlogDetail/Container";

import { Decimal } from "decimal.js";

function DashBoard() {
  const [t, i18n] = useTranslation();
  const ref = useRef();
  document.documentElement.classList.remove("nav-open");

  let parallax = null;

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [refContainerHead, inContainerHead] = useInView();
  const [refContainer1, inContainer1] = useInView();
  const [refContainer2, inContainer2] = useInView();
  const [refContainer3, inContainer3] = useInView();

  const [ph1, set_ph1] = useState(0);
  const [ph2, set_ph2] = useState(0);
  const [ph3, set_ph3] = useState(0);
  const [ph4, set_ph4] = useState(0);
  const [ph5, set_ph5] = useState(0);
  const [ph6, set_ph6] = useState(0);
  // const [ph7, set_ph7] = useState(0);
  // const [ph8, set_ph8] = useState(0);
  // const [ph9, set_ph9] = useState(0);
  // const [ph10, set_ph10] = useState(0);

  const ph = () => {
    const phfunc = () => {
      // const wi = window.innerHeight;
      // const wi = document.documentElement.clientHeight;

      const vh = window.jQuery("#parallax").height();
      // console.log(window.jQuery("#ph1").height());
      const ph0 = window.jQuery("#ph0").height();
      const ph1 = window.jQuery("#ph1").height() + ph0;
      const ph2 = window.jQuery("#ph2").height() + ph1;
      const ph3 = window.jQuery("#ph3").height() + ph2;
      const ph4 = window.jQuery("#ph4").height() + ph3;
      const ph5 = window.jQuery("#ph5").height() + ph4;

      // const ph6 = window.jQuery("#ph6").height() + ph5;
      // const ph7 = window.jQuery("#ph7").height() + ph6;
      // const ph8 = window.jQuery("#ph8").height() + ph7;
      // const ph9 = window.jQuery("#ph9").height() + ph8;
      // const ph10 = window.jQuery("#ph10").height() + ph9;

      set_ph1(ph0 / vh);
      set_ph2(ph1 / vh);
      set_ph3(ph2 / vh);
      set_ph4(ph3 / vh);
      set_ph5(ph4 / vh);
      set_ph6(ph5 / vh);
      // set_ph7(ph6 / vh);
      // set_ph8(ph7 / vh);
      // set_ph9(ph8 / vh);
      // set_ph10(ph9 / vh);
    };
    setTimeout(phfunc, 1000);
    setTimeout(phfunc, 2000);
  };

  useEffect(() => {
    ph();
  }, []);
  return (
    <>
      <div id="parallax-bar">
        <NotificationAlert ref={ref} />
        <ColorNavbar />
      </div>

      <div style={{ position: "relative", height: "100vh" }}>
        <Parallax
          id="parallax"
          ref={(ref) => (parallax = ref)}
          // config={{
          //   tension: 0,
          //   friction: 0,
          // }}
          pages={ph3}
          config={{ tension: 280, friction: 60 }}
        >
          {/* ================== */}

          <ParallaxLayer
            id="BackRectangle1"
            offset={ph1 + 0.5}
            speed={0.8}
            factor={1}
          >
            <ContainerRightRectangle />
          </ParallaxLayer>

          <ParallaxLayer
            id="BackRectangle2"
            offset={ph2 + 0.5}
            speed={0.8}
            factor={1}
          >
            <ContainerLeftRectangle />
          </ParallaxLayer>

          {/* ================== */}
          <ParallaxLayer id="ContainerHead" offset={0} factor={1} speed={0}>
            <div ref={refContainerHead} style={{ height: "200vh" }}>
              <div id="ph0">
                <ContainerHead
                  inview={inContainerHead}
                  title="News"
                  subtitle="企業ニュース"
                  background={{
                    // backgroundImage: { src },
                    backgroundImage:
                      "url(" +
                      require("assets/img/envato/workplace-min.jpg") +
                      ")",
                    // backgroundPosition: "0vw -15vh",
                    backgroundSize: "cover",
                    // backgroundSize: "78%",
                    // backgroundRepeat: "no-repeat",
                  }}
                />
              </div>
            </div>
          </ParallaxLayer>
          {/* ================== */}
          <ParallaxLayer id="Container1" offset={ph1} factor={1} speed={0}>
            <div ref={refContainer1} style={{ height: "100vh" }}>
              {/* <div className="Variable" id="variable"> */}
              {/* style={{ overflowY: "auto", height: "100vh" }} */}
              <div id="ph1">
                <ContainerBlogDetail
                  ph={ph}
                  inview={inContainer1}
                  scrollTo={() => parallax.scrollTo(0)}
                />
              </div>
              {/* </div> */}
            </div>
          </ParallaxLayer>
          {/* ================== */}
          {/* <ParallaxLayer id="Container2" offset={part2} factor={1} speed={0}>
            <div ref={refContainer2} style={{ height: "100vh" }}>
              <div className="pricing-1 section">
              <ContainerColorDescription inview={inContainer2} />
              </div>
            </div>
          </ParallaxLayer> */}
          {/* ================== */}

          {/* <ParallaxLayer id="Container3" offset={part3} factor={1} speed={0}>
            <div ref={refContainer3} style={{ height: "100vh" }}>
              <div className="pricing-1 section">
              <ContainerGallery inview={inContainer3} />
              </div>
            </div>
          </ParallaxLayer> */}
          {/* ================== */}

          <ParallaxLayer
            id="CarouselSectionFooterAreas"
            offset={ph2}
            speed={0}
            factor={1}
          >
            {/* <Carousel /> */}
            {/* <SectionFooterAreas /> */}
            {/* <SectionFooterAreas title="住宅設備関連、リフォームのことなら松永興業株式会社へ" shareLink="https://www.matsunaga-industrial.com/blog"/> */}

            {/* <Carousel /> */}
            {/* </div> */}
            {/* <div style={{ height: "50vh", width: "100vw" }}>
              <SectionFooterAreas2
                title="住宅設備関連、リフォームのことなら松永興業株式会社へ"
                shareLink="https://www.matsunaga-industrial.com/blog"
              />
            </div> */}
            <div id="ph2">
              <SectionFooterAreas3
                title="住宅設備関連、リフォームのことなら松永興業株式会社へ"
                shareLink="https://www.matsunaga-industrial.com/blog"
              />
            </div>
          </ParallaxLayer>
        </Parallax>
      </div>
    </>
  );
}
export default DashBoard;
