import React, { FC, useEffect, useRef, useState } from "react";
import ReactTable from "react-table";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import notification from "views/Basic/notification";
import NotificationAlert from "react-notification-alert";

import { initReactI18next, useTranslation } from "react-i18next";
import ReactDatetime from "react-datetime";
import Select from "react-select";
import moment from "moment/moment";
import classnames from "classnames";
import Loader from "views/Basic/loader";

import {
  Label,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  Form,
  FormGroup,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  Row,
  Col,
  Spinner,
} from "reactstrap";

import {
  LightgalleryProvider,
  LightgalleryItem,
  withLightgallery,
  useLightgallery,
} from "react-lightgallery";

import "lightgallery.js/dist/css/lightgallery.css";

import BeforeAfter from "./BeforeAfter";

const AppContainer = ({ description, before, after, beforetxt, aftertxt }) => {
  const [t, i18n] = useTranslation();
  const dispatch = useDispatch();
  const ref = useRef();

  return (
    <>
      <LightgalleryProvider
        lightgallerySettings={{
          mode: "lg-fade",
          // mode: "lg-rotate",
          // mode: "lg-lollipop",
          // cssEasing: "cubic-bezier(0.25, 0, 0.25, 1)",
          cssEasing: "ease",
          // cssEasing: "ease-in",
          // cssEasing: "ease-out",
          // cssEasing: "cubic-bezier(0.83, 0, 0.17, 1)",
          speed:800,
          // rotate: false,
          // rotateLeft: false,
          // rotateRight: false,
          // flipHorizontal: false,
          // flipVertical: false,
          // actualSize: false,
          // zoom: false,
          fullScreen: false,
          // download: true,
          thumbnail:false,
          // toggleThumb:false
        }}
        onBeforeOpen={() => console.info("onBeforeOpen")}
        onAfterOpen={() => console.info("onAfterOpen")}
        onSlideItemLoad={() => console.info("onSlideItemLoad")}
        onBeforeSlide={() => console.info("onBeforeSlide")}
        onAfterSlide={() => console.info("onAfterSlide")}
        onBeforePrevSlide={() => console.info("onBeforePrevSlide")}
        onBeforeNextSlide={() => console.info("onBeforeNextSlide")}
        onDragstart={() => console.info("onDragstart")}
        onDragmove={() => console.info("onDragmove")}
        onDragend={() => console.info("onDragend")}
        onSlideClick={() => console.info("onSlideClick")}
        onBeforeClose={() => console.info("onBeforeClose")}
        onCloseAfter={() => console.info("onCloseAfter")}
      >
        <div>
          <div key={0}>
            <LightgalleryItem
              group={"group1"}
              src={before}
              thumb={before}
              subHtml={beforetxt}
            >
              <BeforeAfter
                description={description}
                before={before}
                after={after}
              />
            </LightgalleryItem>
          </div>
          <div key={1}>
            <LightgalleryItem
              group={"group1"}
              src={after}
              thumb={after}
              subHtml={aftertxt}
            ></LightgalleryItem>
          </div>
        </div>
      </LightgalleryProvider>
    </>
  );
};
export default AppContainer;

// `
// <div class="text-center">
// <ul style="display: inline-block" >
// <li class="text-left">キッチンとリビングが隔たれていて使い勝手が悪いので、対面キッチンのあるLDKにしたい</li>
// <li class="text-left">洗面脱衣室を広くして、収納を充実させたい</li>
// <li class="text-left">和室が有効に使えていないので、配置を考えてほしい</li>
// <li class="text-left">階段の勾配をゆるくしたい</li>
// </ul>
// </div>
// `

// `
// <div class="text-center">
// <ul class = "list-unstyled" style="display: inline-block" >
// <li class="text-left">当初は増築を考えておられたが、玄関の位置と和室を移動することにより、</li>
// <li class="text-left">低コストで希望通りの広いLDKを実現しました。</li>
// <li class="text-left">キッチン背面のデスクコーナーは、朝は二人のお嬢様のお化粧スペース、</li>
// <li class="text-left">昼は奥様がアイロンかけをかけたりする家事スペース、夜はご主人のパソコンスペースと大活躍。</li>
// <li class="text-left">上部には、それぞれの必要なものが収納可能な吊り戸を設置しました。</li>
// </ul>
// </div>
// `
