import React from "react";
import Particles from "react-particles-js";
import { ShapeType } from "tsparticles/dist/Enums/ShapeType";

function App() {
  return (
    <>
      <div id="stars" className="preparticles"></div>
      {/* <div id="stars2" className="preparticles"></div> */}
      <div id="stars3" className="preparticles"></div>
    </>
  );
}

export default App;
