import React, { FC, useEffect, useRef, useState } from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  Table,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import Lightgallery from "../About/Lightgallery";
import BeforeAfter from "../About/BeforeAfter";
import { useInView } from "react-intersection-observer";

import { useSpring, animated, interpolate } from "react-spring";
// 親要素がabsoluteであることから、ContainerやRowはすべて高さが０である
// このことを利用して、Rowを重ねていこう
import VerticalTabs from "../Company/VerticalTabs";
import { CardImage, CardDescription } from "../Company/CardCompany";

// 子要素を無視するabsoluteとfixed
// 子要素を計算して自分の大きさを決めるrelativeとstatic

export function ContainerHead({ inview }) {
  // { opa, tra, clip }
  let { opa, flowright, flowleft, transxleft, transytop } = useSpring({
    opa: inview ? 1 : 0,
    flowright: inview
      ? "rect(0vh, 100vw, 100vh, 0vw)"
      : "rect(0vh, 0vw, 100vh, 0vw)",
    flowleft: inview
      ? "rect(0vh, 100vw, 100vh, 0vw)"
      : "rect(0vh, 100vw, 100vh, 100vw)",
    // transxleft: inview ? "translate3d(0px, 0, 0)" : "translate3d(70px, 0, 0)",
    transxleft: inview ? "translateX(0)" : "translateX(70px)",
    transxright: inview ? "translateX(0)" : "translateX(-70px)",
    transytop: inview ? "translateY(0)" : "translateY(70px)",
  });

  return (
    <Container fluid>
      <Row>
        <Col lg="12" className="px-0">
          <animated.div
            style={{
              // transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
              transition: "all 800ms ease",
              opacity: opa.interpolate((p) => p),
              clip: flowright.interpolate((p) => p),
              position: "fixed",
            }}
          >
            <div
              style={{ position: "absolute", height: "70vh", width: "100vw" }}
              className="black-cool"
            ></div>
          </animated.div>
        </Col>
      </Row>
      <Row>
        <Col lg="2" className="px-0"></Col>
        <Col lg="10" className="px-0">
          <animated.div
            style={{
              // transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
              transition: "all 800ms ease",
              transitionDelay: "0.3s",
              transform: transxleft.interpolate((p) => p),
              opacity: opa.interpolate((p) => p),
              // clip: flowleft.interpolate((p) => p),
              position: "fixed",
            }}
          >
            <div
              className="backgroundImage-80"
              style={{
                position: "absolute",
                left: "5vw",
                top: "15vh",
                width: "100vw",
                hiight: "100vh",
                minHeight: "65vh",
                maxHeight: "65vh",
                backgroundImage:
                  // "url(" + require("assets/img/envato/handshake2.jpg") + ")",
                  "url(" +
                  require("assets/img/envato/concept.jpg") +
                  // require("assets/img/close-cool.jpg") +
                  // require("assets/img/handshake2.jpg") +

                  ")",
              }}
            ></div>
            <div
              className="backgroundImage-80"
              style={{
                position: "absolute",
                left: "5vw",
                top: "15vh",
                width: "100vw",
                hiight: "100vh",
                minHeight: "65vh",
                maxHeight: "65vh",
                backgroundColor: "rgba(0, 0, 0, 0.2)",
              }}
            ></div>
          </animated.div>

          {/* <div
              style={{
                position: "absolute",
                left: "5vw",
                top: "15vh",
                height: "65vh",
                width: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.2)",
              }}
            ></div> */}
        </Col>
      </Row>
      <Row>
        <Col lg="6" style={{ paddingLeft: 0 }}>
          <animated.div
            style={{
              // transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
              transition: "all 800ms ease",
              transitionDelay: "0.3s",
              transform: transytop.interpolate((p) => p),
              opacity: opa.interpolate((p) => p),
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "25vh",
                left: "10vw",
                // width: "100vw",
                // hiight: "100vh",
                // backgroundColor: "white",
              }}
            >
              <h1
                style={{
                  // color: "white",
                  color: "#C3B189",
                  fontWeight: 600,
                  letterSpacing: 4,
                }}
              >
                Company
              </h1>
              <p
                style={{
                  // color: "white",
                  color: "#C3B189",
                  // fontWeight: 600,
                  letterSpacing: 1,
                  paddingTop: 10,
                }}
              >
                会社概要
              </p>
            </div>
          </animated.div>
        </Col>
      </Row>
    </Container>
  );
}

export function ContainerGaiyou() {
  React.useEffect(() => {
    window.HeightEqual();
  }, []);

  const [refDiv1, inDiv1] = useInView();
  const [refDiv2, inDiv2] = useInView();
  const [refDiv3, inDiv3] = useInView();
  const [refDiv4, inDiv4] = useInView();
  const [refDiv5, inDiv5] = useInView();
  const [refDiv6, inDiv6] = useInView();

  React.useEffect(() => {}, []);
  let { opa1, trans1 } = useSpring({
    opa1: inDiv1 ? 1 : 0,
    trans1: inDiv1 ? "translateY(0)" : "translateY(70px)",
  });
  let { opa2, trans2 } = useSpring({
    opa2: inDiv2 ? 1 : 0,
    trans2: inDiv2 ? "translate3d(0px, 0, 0)" : "translate3d(100px, 0, 0)",
  });
  let { opa3, trans3 } = useSpring({
    opa3: inDiv3 ? 1 : 0,
    trans3: inDiv3 ? "translate3d(0px, 0, 0)" : "translate3d(-100px, 0, 0)",
  });
  let { opa4, trans4 } = useSpring({
    opa4: inDiv4 ? 1 : 0,
    trans4: inDiv4 ? "translateY(0)" : "translateY(70px)",
  });
  let { opa5, trans5 } = useSpring({
    opa5: inDiv5 ? 1 : 0,
    trans5: inDiv5 ? "translateY(0)" : "translateY(70px)",
  });
  let { opa6, trans6 } = useSpring({
    opa6: inDiv6 ? 1 : 0,
    trans6: inDiv6 ? "translate3d(0px, 0, 0)" : "translate3d(100px, 0, 0)",
  });

  return (
    <Container>
      {/* <div className="title">
      <h3>Tables</h3>
    </div> */}
      <Row>
        <Col className="ml-auto mr-auto text-center" md="8">
          <h2 className="title">会社概要</h2>
          {/* <h3 className="title">「関わるすべての人に愛される企業に」</h3> */}
          {/* <h5 className="description">
          関わるすべての人に愛される企業に
        </h5> */}
        </Col>
      </Row>
      <div className="space-top" />
      <Row>
        {/* <Col lg="12">
        <h4>Simple Table</h4>
      </Col> */}
        <Col className="ml-auto mr-auto" md="8">
          {/* <h4 className="title">
          <small>Simple With Actions</small>
        </h4> */}
          {/* <Table responsive striped> */}
          <Table responsive>
            {/* <thead>
            <tr>
              <th className="text-center">#</th>
              <th>Name</th>
              <th>Job Position</th>
              <th>Since</th>
              <th className="text-right">Salary</th>
              <th className="text-right">Actions</th>
            </tr>
          </thead> */}
            <tbody>
              <tr>
                <td>会社名</td>
                <td>松永興業株式会社</td>
              </tr>
              <tr>
                <td>代表</td>
                <td>永田 悠介 </td>
              </tr>
              <tr>
                <td>資本金</td>
                <td>１０,０００,０００円 </td>
              </tr>
              <tr>
                <td>所在地</td> <td>大阪府東大阪市荒川３丁目２５番１９号</td>
              </tr>
              <tr>
                <td>連絡先</td>
                <td>０６-４３０９-５５５０ </td>
              </tr>
              <tr>
                <td>設立</td>
                <td>平成２８年１０月３日 </td>
              </tr>
              <tr>
                <td>事業内容</td>{" "}
                <td>
                  住宅設備機器販売・設置工事
                  住宅水回りリフォーム工事、外装リフォーム工事{" "}
                </td>
              </tr>
              <tr>
                <td>従業員数</td>
                <td>３５名（パート・アルバイト含む）</td>
              </tr>
              <tr>
                <td style={{ whiteSpace: "nowrap" }}>主要取引先銀行</td>{" "}
                <td>
                  南都銀行、関西みらい銀行、三井住友銀行、大阪シティ信用金庫
                </td>
              </tr>
              <tr>
                <td>有資格</td>
                <td>
                  一般建設業許可電気工事業、一般建設業許可管工事業、
                  給水装置工事主任技術者液化石油ガス設備士、ガス消費機器設置工事監督者、
                  簡易内管施工士、ガス可とう管接続工事監督者、第二種電気工事士、
                  ガス機器設置スペシャリスト etc...
                </td>
              </tr>
              <tr>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
}

export function ContainerEnkaku() {
  React.useEffect(() => {
    window.HeightEqual();
  }, []);

  const [refDiv1, inDiv1] = useInView();
  const [refDiv2, inDiv2] = useInView();
  const [refDiv3, inDiv3] = useInView();
  const [refDiv4, inDiv4] = useInView();
  const [refDiv5, inDiv5] = useInView();
  const [refDiv6, inDiv6] = useInView();

  React.useEffect(() => {}, []);
  let { opa1, trans1 } = useSpring({
    opa1: inDiv1 ? 1 : 0,
    trans1: inDiv1 ? "translateY(0)" : "translateY(70px)",
  });
  let { opa2, trans2 } = useSpring({
    opa2: inDiv2 ? 1 : 0,
    trans2: inDiv2 ? "translate3d(0px, 0, 0)" : "translate3d(100px, 0, 0)",
  });
  let { opa3, trans3 } = useSpring({
    opa3: inDiv3 ? 1 : 0,
    trans3: inDiv3 ? "translate3d(0px, 0, 0)" : "translate3d(-100px, 0, 0)",
  });
  let { opa4, trans4 } = useSpring({
    opa4: inDiv4 ? 1 : 0,
    trans4: inDiv4 ? "translateY(0)" : "translateY(70px)",
  });
  let { opa5, trans5 } = useSpring({
    opa5: inDiv5 ? 1 : 0,
    trans5: inDiv5 ? "translateY(0)" : "translateY(70px)",
  });
  let { opa6, trans6 } = useSpring({
    opa6: inDiv6 ? 1 : 0,
    trans6: inDiv6 ? "translate3d(0px, 0, 0)" : "translate3d(100px, 0, 0)",
  });

  return (
    <Container style={{ paddingBottom: 100 }}>
      <Row>
        <Col className="ml-auto mr-auto text-center" md="8">
          <h4 className="text-left">
            <small>沿革</small>
          </h4>
          <VerticalTabs />
        </Col>
      </Row>
    </Container>
  );
}

export function ContainerMaps() {
  React.useEffect(() => {
    window.HeightEqual();
  }, []);

  const [refDiv1, inDiv1] = useInView();
  const [refDiv2, inDiv2] = useInView();
  const [refDiv3, inDiv3] = useInView();
  const [refDiv4, inDiv4] = useInView();
  const [refDiv5, inDiv5] = useInView();
  const [refDiv6, inDiv6] = useInView();

  React.useEffect(() => {}, []);
  let { opa1, trans1 } = useSpring({
    opa1: inDiv1 ? 1 : 0,
    trans1: inDiv1 ? "translateY(0)" : "translateY(70px)",
  });
  let { opa2, trans2 } = useSpring({
    opa2: inDiv2 ? 1 : 0,
    trans2: inDiv2 ? "translate3d(0px, 0, 0)" : "translate3d(100px, 0, 0)",
  });
  let { opa3, trans3 } = useSpring({
    opa3: inDiv3 ? 1 : 0,
    trans3: inDiv3 ? "translate3d(0px, 0, 0)" : "translate3d(-100px, 0, 0)",
  });
  let { opa4, trans4 } = useSpring({
    opa4: inDiv4 ? 1 : 0,
    trans4: inDiv4 ? "translateY(0)" : "translateY(70px)",
  });
  let { opa5, trans5 } = useSpring({
    opa5: inDiv5 ? 1 : 0,
    trans5: inDiv5 ? "translateY(0)" : "translateY(70px)",
  });
  let { opa6, trans6 } = useSpring({
    opa6: inDiv6 ? 1 : 0,
    trans6: inDiv6 ? "translate3d(0px, 0, 0)" : "translate3d(100px, 0, 0)",
  });

  return (
    <Container style={{ paddingBottom: 100 }}>
      <Row>
        <Col className="ml-auto mr-auto text-center" md="8">
          {/* <h2 className="title">会社概要</h2> */}
          {/* <h5 className="description">営業所一覧</h5> */}
        </Col>
      </Row>
      <div className="space-top" />
      <Row>
        <Col className="" md="6">
          <div className="d-flex justify-content-center align-items-center">
            <CardDescription
              uptitle=""
              title="東大阪本社"
              address="大阪府東大阪市荒川３丁目２５番１９号"
              mapsrc="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3281.7603079267597!2d135.5697800151055!3d34.660755792864485!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6000dfb779b81be9%3A0x2b59964e1b4b5984!2z44CSNTc3LTA4NDMg5aSn6Ziq5bqc5p2x5aSn6Ziq5biC6I2S5bed77yT5LiB55uu77yS77yV4oiS77yR77yZ!5e0!3m2!1sja!2sjp!4v1599534786595!5m2!1sja!2sjp"
            />
          </div>
        </Col>
        <Col className="" md="6">
          <div className="d-flex justify-content-center align-items-center">
            <CardDescription
              uptitle=""
              title="北大阪店"
              address="大阪府高槻市富田町６丁目１５番２号"
              mapsrc="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3275.142195518522!2d135.59215607577732!3d34.827517176397706!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6000e2aee7378f51%3A0x23c5591ae89ea1e3!2z44CSNTY5LTA4MTQg5aSn6Ziq5bqc6auY5qe75biC5a-M55Sw55S677yW5LiB55uu77yR77yV4oiS77ySIOOCpuOCp-ODq-OCt-ODvOOCs-ODvOODiOWvjOeUsA!5e0!3m2!1sja!2sjp!4v1725202899119!5m2!1sja!2sjp"
            />
          </div>
        </Col>
      </Row>
      <br />
      <hr className="my-5" />
      <br />
      <Row>
        <Col className="" md="6">
          <div className="d-flex justify-content-center align-items-center">
            <CardDescription
              uptitle=""
              title="京都城陽店"
              address="京都府城陽市寺田樋尻１２番地７４"
              mapsrc="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3274.143578798787!2d135.7686856757783!3d34.85261947504634!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x600110c7feb8a2a3%3A0x52698ca6da2a5fe5!2z44CSNjEwLTAxMjEg5Lqs6YO95bqc5Z-O6Zm95biC5a-655Sw5qiL5bC777yR77yS4oiS77yX77yU!5e0!3m2!1sja!2sjp!4v1725202997431!5m2!1sja!2sjp"
            />
          </div>
        </Col>
        <Col className="" md="6">
          <div className="d-flex justify-content-center align-items-center">
            <CardDescription
              uptitle=""
              title="奈良店"
              address="奈良県大和郡山市小林町西１丁目４番２号"
              mapsrc="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3283.2975021074985!2d135.76474147576948!3d34.621921487435685!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6001300c39ae72f7%3A0x39400fb9cfd8eed!2z44CSNjM5LTEwNDUg5aWI6Imv55yM5aSn5ZKM6YOh5bGx5biC5bCP5p6X55S66KW_77yR5LiB55uu77yU4oiS77yS!5e0!3m2!1sja!2sjp!4v1725203041646!5m2!1sja!2sjp"
            />
          </div>
        </Col>
      </Row>
      <br />
      <hr className="my-5" />
      <br />
      <Row>
        <Col className="" md="6">
          <div className="d-flex justify-content-center align-items-center">
            <CardDescription
              uptitle=""
              title="桜井店"
              address="奈良県桜井市川合２５６ー６南野駅前ビル１階"
              mapsrc="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3287.5548871717187!2d135.84348727576537!3d34.51416709319858!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6006cb49c950fb7d%3A0x37f84e1392091142!2z44CSNjMzLTAwNjMg5aWI6Imv55yM5qGc5LqV5biC5bed5ZCI77yS77yV77yW4oiS77yWIOWNl-mHjumnheWJjeODk-ODqyDvvJHpmo4!5e0!3m2!1sja!2sjp!4v1725203138943!5m2!1sja!2sjp"
            />
          </div>
        </Col>
        <Col className="" md="6">
          <div className="d-flex justify-content-center align-items-center">
            <CardDescription
              uptitle=""
              title="和歌山店"
              address="和歌山県和歌山市島橋東ノ丁１−１"
              mapsrc="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3298.2032568792765!2d135.14874207575505!3d34.24335680761473!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6000b278db64a61f%3A0x450a52323d4f100f!2z44CSNjQwLTg0MTMg5ZKM5q2M5bGx55yM5ZKM5q2M5bGx5biC5bO25qmL5p2x44OO5LiB77yR4oiS77yR!5e0!3m2!1sja!2sjp!4v1725203110058!5m2!1sja!2sjp"
            />
          </div>
        </Col>
      </Row>
      <br />
      <hr className="my-5" />
      <br />
      <Row>
        <Col className="" md="6">
          <div className="d-flex justify-content-center align-items-center">
            <CardDescription
              uptitle=""
              title="東京オフィス"
              address="東京都中央区日本橋室町１丁目１１番１２号 日本橋水野ビル７階"
              mapsrc="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.652530888047!2d139.7732192758104!3d35.685556829736235!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188954295310c1%3A0xaa08eb9001502934!2z44CSMTAzLTAwMjIg5p2x5Lqs6YO95Lit5aSu5Yy65pel5pys5qmL5a6k55S677yR5LiB55uu77yR77yR4oiS77yR77ySIOaXpeacrOapi-awtOmHjuODk-ODqyDvvJfpmo4!5e0!3m2!1sja!2sjp!4v1725203200430!5m2!1sja!2sjp"
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col className=" mr-auto text-center" md="8"></Col>
      </Row>
      <br />
      <hr className="my-5" />
      <br />
    </Container>
  );
}

//  {/* <Col className="" md="5">
//           <CardImage
//             src={{
//               backgroundImage:
//                 "url(" +
//                 require("assets/img/matsunaga/company_photo_retouch_HP/店舗写真/compress/re_187E57A7-74C4-4D80-83E2-19B35EBD4432.jpg") +
//                 ")",
//               // "url(" + require("assets/img/matsunaga/正直屋.jpg") + ")",
//               backgroundSize: "contain",
//               backgroundRepeat: "no-repeat",
//               backgroundColor: "rgb(23, 23, 23)",
//             }}
//           />
//         </Col> */}
//  {/* <Col className="" md="6">
//           <CardDescription
//             uptitle=""
//             title="奈良支店"
//             address="奈良県大和郡山市小林町西１丁目４番２号"
//             mapsrc="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3283.2977832879988!2d135.76512701523023!3d34.621914380454974!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6001300c39ae72f7%3A0x39400fb9cfd8eed!2z44CSNjM5LTEwNDUg5aWI6Imv55yM5aSn5ZKM6YOh5bGx5biC5bCP5p6X55S66KW_77yR5LiB55uu77yU4oiS77yS!5e0!3m2!1sja!2sjp!4v1640607922349!5m2!1sja!2sjp"
//           />
//         </Col> */}
//         {/* <Col className="" md="5">
//           <CardImage
//             className="backgroundzize"
//             src={{
//               backgroundImage:
//                 "url(" +
//                 require("assets/img/matsunaga/company_photo_retouch_HP/店舗写真/compress/re_F532E77B-5102-4623-A8EC-874F0093B71F.jpg") +
//                 ")",
//               // "url(" + require("assets/img/matsunaga/ガス工事.jpg") + ")",
//               backgroundSize: "contain",
//               backgroundRepeat: "no-repeat",
//               backgroundColor: "rgb(23, 23, 23)",
//             }}
//           />
//         </Col> */}
