import React from "react";

const notification = (refs, inMessage) => {
  if (refs) {
    var options = {};
    options = {
      place: "tc",
      message: (
        <div>
          <span data-notify="icon" className="nc-icon nc-bell-55" />
          <span>{inMessage}</span>
        </div>
      ),
      type: "info",
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 5,
    };
    refs.notificationAlert(options);
  }
};
export default notification;
