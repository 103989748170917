import { useDispatch, useSelector } from "react-redux";
import React, { FC, useEffect, useRef, useState } from "react";
import Contact from "../ContainerLG/Contact";
import ContactSM from "../ContainerSM/Contact";

import { Helmet } from "react-helmet";
function ContactContainer() {
  const [width, set_width] = React.useState(window.innerWidth);

  function resizeListener() {
    set_width(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", resizeListener);

    return function cleanup() {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  return (
    <>
      <Helmet>
        <meta
          name="keywords"
          content="松永興業株式会社,ガイソー,給湯器交換,外壁塗装"
        />
        <title>どんな事でもご相談ください｜松永興業株式会社</title>
        <meta
          name="description"
          content="東大阪市の本社をはじめとし、奈良県に奈良支店を構え、地域の皆様のご要望により多くお応え致します。 ～リフォーム～ アフターメンテナンスをワンストップで対応。年間3000件以上の施工実績。"
        />
        <link
          rel="canonical"
          href="https://www.matsunaga-industrial.com/contact"
        />
      </Helmet>

      {width > 992 ? (
        <Contact />
      ) : (
        <div id="style-sm" className="overflow-hidden">
          <ContactSM />
        </div>
      )}


      {/* <div className="d-none d-lg-block">
        <Contact />
      </div>
      <div className="d-lg-none overflow-hidden"><ContactSm /></div> */}
    </>
  );
}

export default ContactContainer;
