import React from "react";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";

// reactstrap components
import { Button, FormGroup, Container, Row, Col } from "reactstrap";
import SocialShare from "./Social";
import { useHistory } from "react-router-dom";

function SectionFooterAreas({ title, shareLink }) {
  const history = useHistory();

  const [languageSelect, setLanguageSelect] = React.useState({
    value: "en",
    label: "English",
  });
  const [curencySelect, setCurencySelect] = React.useState({
    value: "USD",
    label: "USD",
  });

  React.useEffect(() => {
    // document.addEventListener(
    //   "touchmove",
    //   function (e) {
    //     // if (window.jQuery(document).scrollTop() > 0) {
    //     // e.preventDefault();
    //     // }
    //   },
    //   { passive: true }
    // );
  }, []);

  return (
    <>
      <footer
        className="footer footer-black footer-big"
        style={{
          paddingTop: 20,
          height: 650,
          backgroundColor: "#171717",
        }}
      >
        <Container fluid>
          <Row style={{ paddingTop: 40 }}>
            <Col
              className="text-center"
              style={{ color: "#a2a2a1" }}
              xs="12"
              lg="12"
            >
              {/* <div className="d-flex text-left"> */}
              <div className="text-center">
                <h3
                  className="text-left"
                  style={{
                    color: "#fff",
                    marginTop: 0,
                    marginBottom: 0,
                    paddingLeft: 10,
                  }}
                >
                  <img
                    alt="..."
                    className="img"
                    src={"/logo/logo-white2.svg"}
                    style={{ width: 240 }}
                  />
                  {/* 松永興業株式会社 */}
                </h3>

                <div className="col-lg-8"></div>
                
                <p
                  style={{
                    display: "inline-block",
                    textAlign: "left",
                    lineHeight: 2,
                    letterSpacing: 1,
                    fontSize: 12,
                    color: "#a2a2a2",
                    paddingLeft: 10,
                    paddingTop: 28,
                    paddingBottom: 24,
                    width: "100%",
                  }}
                >
                  <table style={{ marginBottom: 10 }}>
                    <tr>
                      <th style={{ width: "25%", color: "#fff" }}>本社</th>
                      <td>大阪府東大阪市荒川３丁目２５番１９号</td>
                    </tr>
                    <tr>
                      <th style={{ width: "25%", color: "#fff" }}>北大阪店</th>
                      <td>大阪府高槻市富田町６丁目１５番２号</td>
                    </tr>
                    <tr>
                      <th style={{ width: "25%", color: "#fff" }}>
                        京都城陽店
                      </th>
                      <td>京都府城陽市寺田樋尻１２番地７４</td>
                    </tr>
                    <tr>
                      <th style={{ width: "25%", color: "#fff" }}>奈良店</th>
                      <td>奈良県大和郡山市小林町西１丁目４番２号</td>
                    </tr>
                    <tr>
                      <th style={{ width: "25%", color: "#fff" }}>桜井店</th>
                      <td>奈良県桜井市川合２５６ー６南野駅前ビル１階</td>
                    </tr>
                    <tr>
                      <th style={{ width: "25%", color: "#fff" }}>和歌山店</th>
                      <td>和歌山県和歌山市島橋東ノ丁１−１</td>
                    </tr>
                  </table>
                  <b>
                    TEL/FAX:
                    <a href="tel:0643095550" style={{ color: "white" }}>
                      06-4309-5550
                    </a>
                    /06-4309-5510
                  </b>
                </p>
                <br />
                <p
                  style={{
                    display: "inline-block",
                    textAlign: "left",
                    lineHeight: 2,
                    letterSpacing: 1,
                    fontSize: 12,
                    color: "#a2a2a2",
                    paddingLeft: 10,
                    // paddingTop: 28,
                    paddingBottom: 24,
                    width: "100%",
                  }}
                >
                  <table id="table01">
                    <tr>
                      <th style={{ width: "25%", color: "#fff" }}>
                        東京オフィス
                      </th>
                      <td>
                        東京都中央区日本橋室町１丁目１１番１２号
                        <br />
                        日本橋水野ビル７階
                      </td>
                    </tr>
                  </table>
                  <br />
                  <b>
                    TEL:
                    <a href="tel:0120853910" style={{ color: "white" }}>
                      0120-853-910
                    </a>
                  </b>
                </p>
                <p
                  style={{
                    // display: "inline-block",
                    // position: "absolute",
                    // bottom:0,
                    // textAlign: "left",
                    lineHeight: 2,
                    letterSpacing: 1,
                    fontSize: 12,
                    color: "#a2a2a2",
                    paddingTop: 20,
                    paddingBottom: 2,
                  }}
                >
                  <b>
                    Copyright © 2020 松永興業株式会社 <br />
                    All Rights Reserved.
                  </b>
                </p>

                {/* <Button
                style={{
                  backgroundColor: "#454542",
                  border: "1px solid #454542",
                  borderRadius: 0,
                  width: 240,
                }}
              >
                会社情報
              </Button> */}
              </div>
            </Col>
            {/* <Col
              className="text-center"
              style={{ color: "#a2a2a1", height: "50vh" }}
              xs="12"
              lg="4"
            >
              <div
                style={{
                  margin: "0px auto",
                  textAlign: "center",
                  display: "block",
                }}
              >
               
              </div>
            </Col> */}
          </Row>
        </Container>
        {/* <Container fluid style={{ paddingRight: 100, paddingLeft: 100 }}>
          <Row style={{ height: "10vh" }}>
            <Col
              className="text-left"
              style={{ color: "#a2a2a1" }}
              lg="4"
            ></Col>
            <Col
              className="text-left"
              style={{ color: "#a2a2a1" }}
              lg="4"
            ></Col>
            <Col
              className="text-left"
              style={{ color: "#a2a2a1", paddingRight: 0, marginRight: -50 }}
              lg="4"
            >
              <div className="text-left">
                <p
                  style={{
                    lineHeight: 2,
                    letterSpacing: 1,
                    fontSize: 12,
                    color: "#a2a2a2",
                    paddingBottom: 10,
                  }}
                >
                  <span style={{ marginTop: 0, marginBottom: 30 }}>
                    <b>
                      Copyright © 2020 松永興業株式会社 All Rights Reserved.
                    </b>
                  </span>
                </p>
              </div>
            </Col>
          </Row>
        </Container> */}
      </footer>
      {/* <footer
        className="footer footer-black footer-big d-flex justify-content-center align-items-center "
        style={{
          paddingTop: "3vh",
          // paddingRight: 100,
          // paddingLeft: 100,
          backgroundColor: "#212121",
          // backgroundColor: "#171717",
        }}
      >
        <Container
          fluid
          style={{
            // marginRight: -115,
            // marginLeft: -115,
          }}
        >
          <Row>
            <Col
              className="text-center"
              // className="text-left"
              style={{ color: "#a2a2a1" }}
              lg="4"
            >
              <a
                href="https://syouzikiya.jp/osaka/"
                target="_blank"
                rel="noopener noreferrer"
                // onClick={(e) => e.preventDefault()}
              >
                <img
                  alt="..."
                  className="img"
                  src={"/logo/footer_image1.jpg"}
                  style={{ maxHeight: "10vh" }}
                  // style={{ maxHeight: 100, maxWidth: "100%" }}
                />
              </a>
            </Col>
            <Col
              className="text-center"
              style={{ color: "#a2a2a1", paddingTop: "3vh" }}
              lg="4"
            >
              <a
                href="http://www.gaiso-ibaraki.co/"
                target="_blank"
                rel="noopener noreferrer"
                // onClick={(e) => e.preventDefault()}
              >
                <img
                  alt="..."
                  className="img"
                  src={"/logo/footer_image2.jpg"}
                  style={{ maxHeight: "10vh" }}
                  // style={{ maxHeight: 100, maxWidth: "100%" }}
                />
              </a>
            </Col>
            <Col
              className="text-center"
              style={{ color: "#a2a2a1", paddingTop: "3vh" }}
              lg="4"
            >
              <a
                href="https://www.evessa.com/"
                target="_blank"
                rel="noopener noreferrer"
                // onClick={(e) => e.preventDefault()}
              >
                <img
                  alt="..."
                  className="img"
                  src={"/logo/footer_image3.jpg"}
                  style={{ maxHeight: "10vh" }}
                />
              </a>
            </Col>
            <Col
              className="text-center"
              style={{ color: "#a2a2a1", height: 60 }}
              lg="4"
            >
            
            </Col>
          </Row>
        </Container>
      </footer> */}
    </>
  );
}

export default SectionFooterAreas;

// <Col
// className="text-center"
// style={{
//   color: "#a2a2a1",
//   height: "50vh",
//   paddingLeft: "15vw",
//   paddingRight: "15vw",
// }}
// xs="12"
// lg="4"
// >
// <div
//   style={{
//     // margin: "0px auto",
//     // textAlign: "left",
//     display: "inline-block",
//     // display: "block",
//     // position:"relative",
//     // width:"100%",
//   }}
// >
//   <div className="d-flex justify-content-between align-items-center">
//     <div>
//       <p
//         style={{
//           lineHeight: 2,
//           letterSpacing: 1,
//           fontSize: 12,
//           color: "#a2a2a2",
//           paddingBottom: 10,
//         }}
//       >
//         <span
//           style={{
//             color: "#fff",
//             marginTop: 0,
//             marginBottom: 30,
//           }}
//         >
//           <b
//             className="cursor-pointer"
//             onClick={() => {
//               history.push("/home");
//             }}
//           >
//             HOME
//           </b>
//         </span>
//       </p>
//       <p
//         style={{
//           lineHeight: 2,
//           letterSpacing: 1,
//           fontSize: 12,
//           color: "#a2a2a2",
//           paddingBottom: 10,
//         }}
//       >
//         <span
//           style={{
//             color: "#fff",
//             marginTop: 0,
//             marginBottom: 30,
//           }}
//         >
//           <b
//             className="cursor-pointer"
//             onClick={() => {
//               history.push("/message");
//             }}
//           >
//             MESSAGE
//           </b>
//         </span>
//       </p>
//       <p
//         style={{
//           lineHeight: 2,
//           letterSpacing: 1,
//           fontSize: 12,
//           color: "#a2a2a2",
//           paddingBottom: 10,
//         }}
//       >
//         <span
//           style={{
//             color: "#fff",
//             marginTop: 0,
//             marginBottom: 30,
//           }}
//         >
//           <b
//             className="cursor-pointer"
//             onClick={() => {
//               history.push("/about");
//             }}
//           >
//             ABOUT
//           </b>
//         </span>
//       </p>
//     </div>
//     <div>
//       <p
//         style={{
//           lineHeight: 2,
//           letterSpacing: 1,
//           fontSize: 12,
//           color: "#a2a2a2",
//           paddingBottom: 10,
//         }}
//       >
//         <span
//           style={{
//             color: "#fff",
//             marginTop: 0,
//             marginBottom: 30,
//           }}
//         >
//           <b
//             className="cursor-pointer"
//             onClick={() => {
//               history.push("/blog");
//             }}
//           >
//             NEWS
//           </b>
//         </span>
//       </p>
//       <p
//         style={{
//           lineHeight: 2,
//           letterSpacing: 1,
//           fontSize: 12,
//           color: "#a2a2a2",
//           paddingBottom: 10,
//         }}
//       >
//         <span
//           style={{
//             color: "#fff",
//             marginTop: 0,
//             marginBottom: 30,
//           }}
//         >
//           <b
//             className="cursor-pointer"
//             onClick={() => {
//               history.push("/company");
//             }}
//           >
//             COMPANY
//           </b>
//         </span>
//       </p>
//       <p
//         style={{
//           lineHeight: 2,
//           letterSpacing: 1,
//           fontSize: 12,
//           color: "#a2a2a2",
//           paddingBottom: 10,
//         }}
//       >
//         <span
//           style={{
//             color: "#fff",
//             marginTop: 0,
//             marginBottom: 30,
//           }}
//         >
//           <b
//             className="cursor-pointer"
//             onClick={() => {
//               history.push("/contact");
//             }}
//           >
//             CONTACT
//           </b>
//         </span>
//       </p>
//       {/* <div className="">
//       <SocialShare title={title} shareLink={shareLink} />
//     </div> */}
//     </div>
//   </div>
//   <div className="">
//     <SocialShare title={title} shareLink={shareLink} />
//   </div>
//   <p
//     style={{
//       // display: "inline-block",
//       // position: "absolute",
//       // bottom:0,
//       textAlign: "left",
//       lineHeight: 2,
//       letterSpacing: 1,
//       fontSize: 12,
//       color: "#a2a2a2",
//       paddingBottom: 2,
//     }}
//   >
//     <b>
//       Copyright © 2020 松永興業株式会社 <br />
//       All Rights Reserved.
//     </b>
//   </p>
//   <br />
// </div>
// </Col>
