import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
// import { ParallaxProvider } from "react-scroll-parallax";
// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/component.scss";
import "assets/scss/common.scss";
import "assets/demo/demo.css";
import "assets/demo/react-demo.css";

import "react-app-polyfill/ie11";
// import 'react-app-polyfill/stable';
import "core-js/stable";
import "regenerator-runtime/runtime";

import { AppRouter } from "./AppRouter";

import { store, persistor } from "stores/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      {/* <ParallaxProvider> */}
      {/* <GoogleReCaptchaProvider
        reCaptchaKey="6LfGIMEZAAAAAPIdQRUemj4R3GY8wtpXuojJIulx"
        // language="[optional_language]"
        useRecaptchaNet
      > */}
        <AppRouter />
      {/* </GoogleReCaptchaProvider> */}

      {/* </ParallaxProvider> */}
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
