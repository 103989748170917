import { useDispatch, useSelector } from "react-redux";
import React, { FC, useEffect, useRef, useState } from "react";
// import AboutSm from "../ContainerSM/About";
import {
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
function AboutContainer() {
  const [vTabs, setVTabs] = React.useState("1");
  return (
    <>
      <div
        id="description-areas"
        style={{
          marginLeft: 35,
          marginRight: 35,
          // paddingLeft: 30,
          // paddingRight: 30,
        }}
        className="pb-5"
      >
        <Row>
          {/* <Col lg="2" sm="2" xs="2"> */}
          {/* <div className="nav-tabs-navigation">
              <div className="nav-tabs-wrapper">
                <Nav className="flex-column nav-stacked" role="tablist" tabs>
                  <NavItem>
                    <NavLink
                      className={vTabs === "1" ? "active" : ""}
                      onClick={() => {
                        setVTabs("1");
                      }}
                    >
                      ２０１６年
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={vTabs === "2" ? "active" : ""}
                      onClick={() => {
                        setVTabs("2");
                      }}
                    >
                      ２０１７年
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={vTabs === "3" ? "active" : ""}
                      onClick={() => {
                        setVTabs("3");
                      }}
                    >
                      ２０１８年
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={vTabs === "4" ? "active" : ""}
                      onClick={() => {
                        setVTabs("4");
                      }}
                    >
                      ２０１９年
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
            </div> */}
          {/* </Col> */}
          <Col lg="12" sm="12" xs="12">
            <dl class="row">
              <dt class="col-sm-4 text-left">２０１６年　２月</dt>
              <dd class="col-sm-8  text-left">東大阪市加納にて創業</dd>
            </dl>
            <dl class="row">
              <dt class="col-sm-4 text-left">２０１７年　９月</dt>
              <dd class="col-sm-8  text-left">リフォーム事業開始</dd>
            </dl>
            <dl class="row">
              <dt class="col-sm-4 text-left">２０１８年　３月</dt>
              <dd class="col-sm-8  text-left">本社を東大阪市荒川に移転</dd>
            </dl>
            <dl class="row">
              <dt class="col-sm-4 text-left">２０１９年　７月</dt>
              <dd class="col-sm-8  text-left">資本金を1,000万円に増資</dd>
            </dl>
            <dl class="row">
              <dt class="col-sm-4 text-left">２０２１年　９月</dt>
              <dd class="col-sm-8  text-left">奈良支店を開設</dd>
            </dl>
            <dl class="row">
              <dt class="col-sm-4 text-left">２０２２年　６月</dt>
              <dd class="col-sm-8  text-left">和歌山支店を開設</dd>
            </dl>
            <dl class="row">
              <dt class="col-sm-4 text-left">２０２３年　５月</dt>
              <dd class="col-sm-8  text-left">北大阪店を開設</dd>
            </dl>
            <dl class="row">
              <dt class="col-sm-4 text-left">２０２３年　６月</dt>
              <dd class="col-sm-8  text-left">京都城陽店を開設</dd>
            </dl>
            <dl class="row">
              <dt class="col-sm-4 text-left">２０２３年　８月</dt>
              <dd class="col-sm-8  text-left">桜井店を開設</dd>
            </dl>
            <dl class="row">
              <dt class="col-sm-4 text-left">２０２４年　８月</dt>
              <dd class="col-sm-8  text-left">
                工事エリアを全国47都道府県に拡大
              </dd>
            </dl>
            <dl class="row">
              <dt class="col-sm-4 text-left">２０２４年　９月</dt>
              <dd class="col-sm-8  text-left">東京オフィスを開設</dd>
            </dl>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default AboutContainer;
