import React from "react";
import { initReactI18next, useTranslation } from "react-i18next";
import { Transition } from "react-transition-group";
import StarsUp from "../Component/StarsUp";

const defaultStyle = {
  // opacity: 0,
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
};

const expand = {
  width: "120%",
  height: "120%",
  left: "-10%",
  top: "-10%",
};

const normal = {
  width: "100%",
  height: "100%",
  left: 0,
  top: 0,
};

// 通常から拡大、拡大から通常
const transitionStyles = {
  entering: {
    transition: "all 5s ease",
    ...normal,
  },
  entered: {
    transition: "",

    ...normal,
  },
  exiting: {
    transition: "all 5s ease",
    ...expand,
  },
  exited: {
    transition: "",
    ...expand,
  },
};

let first = true;

function Topimagetrans({ tri }) {
  const [t, i18n] = useTranslation();

  const [trans1, set_trans1] = React.useState(false);
  const [trans2, set_trans2] = React.useState(true);
  const [trans3, set_trans3] = React.useState(false);
  const [other1, set_other1] = React.useState(-1);
  const [other2, set_other2] = React.useState(-2);
  const [other3, set_other3] = React.useState(-3);

  const [count, set_count] = React.useState(-4);

  React.useEffect(() => {
    if (tri && first) {
      set_trans1(true);
      first = false;
    }
  });

  return (
    <>
      <Transition
        in={trans1}
        timeout={5000}
        onEntered={() => {
          set_count(count - 1);
          set_other1(count);
          set_trans2(false);
        }}
        onExited={() => {
          set_count(count - 1);
          set_other1(count);
          set_trans2(true);
        }}
      >
        {(state) => {
          return (
            <div
              style={{
                // display: other1,
                minHeight: "100vh",
                backgroundPosition: "center center",
                backgroundSize: "cover",
                backgroundImage: `url(${require("assets/img/envato/back1-min.jpg")})`,
                // backgroundImage: `url(${require("assets/img/envato/terrace1-min.jpg")})`,
                zIndex: other1,
                ...defaultStyle,
                ...transitionStyles[state],
              }}
            ></div>
          );
        }}
      </Transition>
      <Transition
        in={trans2}
        timeout={5000}
        onEntered={() => {
          set_count(count - 1);
          set_other2(count);
          set_trans3(false);
        }}
        onExited={() => {
          set_count(count - 1);
          set_other2(count);
          set_trans3(true);
        }}
      >
        {(state) => {
          return (
            <div
              style={{
                // display: other2,
                minHeight: "100vh",
                backgroundPosition: "center center",
                backgroundSize: "cover",
                backgroundImage: `url(${require("assets/img/envato/back2-min.jpg")})`,
                // backgroundImage: `url(${require("assets/img/envato/kitchen-min.jpg")})`,
                
                zIndex: other2,
                ...defaultStyle,
                ...transitionStyles[state],
              }}
            ></div>
          );
        }}
      </Transition>
      <Transition
        in={trans3}
        timeout={5000}
        onEntered={() => {
          set_count(count - 1);
          set_other3(count);
          set_trans1(false);
        }}
        onExited={() => {
          set_count(count - 1);
          set_other3(count);
          set_trans1(true);
        }}
      >
        {(state) => {
          return (
            <div
              style={{
                // display: other3,
                minHeight: "100vh",
                backgroundPosition: "center center",
                backgroundSize: "cover",
                backgroundImage: `url(${require("assets/img/envato/back3-min.jpg")})`,
                zIndex: other3,
                ...defaultStyle,
                ...transitionStyles[state],
              }}
            ></div>
          );
        }}
      </Transition>
    </>
  );
}

export default Topimagetrans;
