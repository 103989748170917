import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import { useParams, useLocation, useHistory } from "react-router-dom";

import { useSpring, animated, interpolate } from "react-spring";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import { DivMessage1, DivMessage2, DivMessage3 } from "./DivMessage";

import { get_entries } from "stores_api/entries";
import { useDispatch, useSelector } from "react-redux";
import React, { FC, useEffect, useRef, useState } from "react";
import moment from "moment/moment";
import { HashLink } from "react-router-hashlink";

import { useInView } from "react-intersection-observer";

export function ContainerSquare1({}) {
  return (
    <Container fluid className="pb-5">
      <Row>
        <Col xs="12" className="px-0">
          <div className="square-right square-black">
            <DivMessage1 />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export function ContainerSquare2({ Square2_opa, Square2_tra }) {
  return (
    <Container fluid>
      <Row>
        <Col xs="12" className="px-0">
          <animated.div
            style={{
              transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
              transform: Square2_tra.interpolate((p) => p),
              opacity: Square2_opa.interpolate((p) => p),
              // 親の幅にしたい
              // paddingにコンテンツ領域があって、それの100%にしかならないwidthはinnerwidth
              width: "100%",
              // position: "absolute",
              // or
              // colのpx-0
              left: 0,
            }}
          >
            {/* <a
              href="http://www.gaiso-ibaraki.co/"
              onClick={(e) => {
                // e.preventDefault();
              }}
              style={{display:"block",height:300}}
            >
            </a> */}
            {/* <img
              alt="..."
              className="backgroundImage-80"
              src={require("assets/img/envato/windows-skyscraper-business-reflect-office-corpora-PGZWL9F-min.jpg")}
              style={{ position: "absolute", width: "45vw", height: "70vh" }}
            /> */}
            <div
              id="target0"
              className="rectangle-left square-black"
              // onClick={() => {
              //   window.location.href = "http://www.gaiso-ibaraki.co/";
              // }}
            >
              <DivMessage2 />

              {/* <a
                href="http://www.gaiso-ibaraki.co/"
                target="_blank"
                rel="noopener noreferrer"
                // onClick={(e) => e.preventDefault()}
                style={{
                  display: "block",
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  top: 0,
                  left: 0,
                  zIndex: 1000,
                  // backgroundColor:"red"
                }}
              >
                {""}
              </a> */}
            </div>
          </animated.div>
        </Col>
      </Row>
    </Container>
  );
}

export function ContainerSquare3({ Square3_opa, Square3_tra }) {
  return (
    <Container fluid>
      <Row>
        <Col xs="12" className="px-0">
          <animated.div
            id="anime"
            style={{
              // backgroundColor: "red",
              // height: 20,
              transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
              transform: Square3_tra.interpolate((p) => p),
              opacity: Square3_opa.interpolate((p) => p),
              width: "100%",
              // position: "absolute",
              left: 0,
            }}
          >
            <div
              id="target1"
              className="rectangle-right square-black"
              onClick={() => {
                // window.location.href = "https://syouzikiya.jp/osaka/";
              }}
            >
              <DivMessage3 />
            </div>
          </animated.div>
        </Col>
      </Row>
    </Container>
  );
}

export function ContainerNews({ opa, tra, ph }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const entries = useSelector((state) => state.entries);

  const inLoading = entries.isFetching || entries.isLoading;
  const data_entries = inLoading ? [] : entries.entries;

  useEffect(() => {
    dispatch(
      get_entries({
        data: {
          page: 1,
          limit: 5,
        },
        page: () => {
          ph();
        },
      })
    );
  }, []);

  // const news = [
  //   { date: "2020.05.10", title: " 5期目スタートしました。" },
  //   {
  //     date: "2019.12.04",
  //     title: " 施工事例ブログ（羽曳野市｜ベランダ防水工事）を更新しました。",
  //   },
  //   {
  //     date: "2019.12.22",
  //     title: " 施工事例ブログ（付帯部塗装）を更新しました。",
  //   },
  //   {
  //     date: "2019.12.19",
  //     title:
  //       " 施工事例ブログ（能勢町：外壁塗装・屋根塗装・ベランダ防水工事）を更新しました。",
  //   },
  //   {
  //     date: "2019.12.18",
  //     title:
  //       " 施工事例ブログ（能勢町：外壁塗装・屋根塗装・ベランダ防水工事）を更新しました。",
  //   },
  // ];

  return (
    <Container fluid className="pb-5">
      <Row>
        <Col lg="6" className="pl-4">
          <div style={{ overflow: "hidden" }}>
            <animated.div
              style={{
                transition: `all 800ms ease-in-out`,
                opacity: opa.interpolate((p) => p),
              }}
            >
              <h2
                style={{
                  fontSize: 55,
                  fontWeight: 600,
                  marginTop: 20,
                  marginBottom: 45,
                  color: "black",
                  letterSpacing: 3,
                }}
              >
                News
              </h2>
            </animated.div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg="6" className="pl-4">
          <div className="square-transparent row-hover">
            <Container fluid className="px-3">
              <hr
                style={{
                  borderColor: "rgba(0,0,0,.05)",
                  // width: "100%",
                  marginRight: -15,
                  marginLeft: -15,
                }}
                className="my-0"
              />
              {data_entries.map((x, idx) => {
                const date = moment
                  .utc(x.post_date)
                  .local()
                  .format("YYYY/MM/DD");
                // .format("YYYY/MM/DD HH:mm");

                return (
                  <>
                    <div style={{ overflow: "hidden" }}>
                      <animated.div
                        style={{
                          transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
                          transform: tra.interpolate((p) => p),
                          opacity: opa.interpolate((p) => p),
                          transitionDelay: `${0.2 * idx}s`,
                        }}
                      >
                        <Row
                          className=""
                          style={{
                            // paddingBottom: 30,
                            height: 80,
                            // borderBottom: 3,
                            // borderCollapse: "collapse",
                            // borderBottomColor: "blue",
                          }}
                          onClick={() => {
                            history.push({
                              pathname: `/blog/${x.entry_id}`,
                              // state: {
                              //   content: x.content,
                              //   title: x.title,
                              //   date: x.post_date,
                              // },
                            });
                          }}
                        >
                          <Col
                            md="4"
                            className="d-flex justify-content-between align-items-center"
                          >
                            {date}
                            <Badge style={{ backgroundColor: "black" }}>
                              News
                            </Badge>
                          </Col>
                          <Col
                            md="8"
                            className="d-flex justify-content-between align-items-center"
                          >
                            {x.title}
                            {/* content: "<p></p>↵<div style="text-align:left;"><img src="https://www.matsunaga-industrial.com/uploads/2020/08/20/dcda7847c7944feec2a08847b0c3d720.jpg" alt="" style="height: auto;width: 300px"/></div>↵<p></p>↵<p>亜sdfあsf</p>↵<p>亜sdfあsd</p>↵<p></p>"
entry_id: "a1b0f238-c210-43d4-bbd2-23960381bc00"
post_date: "2020-08-19T15:00:00.000Z"
title: "fasdfasdfas" */}
                          </Col>
                        </Row>
                      </animated.div>
                      <hr
                        style={{
                          borderColor: "rgba(0,0,0,.05)",
                          // width: "100%",
                          marginRight: -15,
                          marginLeft: -15,
                        }}
                        className="my-0"
                      />
                    </div>
                  </>
                );
              })}
            </Container>
          </div>
        </Col>
        <Col lg="6"></Col>
      </Row>
    </Container>
  );
}

export function ContainerCard() {
  const [ref_col1, in_col1] = useInView();
  let { opa_col1, tra_col1 } = useSpring({
    opa_col1: in_col1 ? 1 : 0,
    tra_col1: in_col1 ? "translateY(0)" : "translateY(100px)",
  });

  const [ref_col2, in_col2] = useInView();
  let { opa_col2, tra_col2 } = useSpring({
    opa_col2: in_col2 ? 1 : 0,
    tra_col2: in_col2 ? "translateY(0)" : "translateY(100px)",
  });

  const [ref_col3, in_col3] = useInView();
  let { opa_col3, tra_col3 } = useSpring({
    opa_col3: in_col3 ? 1 : 0,
    tra_col3: in_col3 ? "translateY(0)" : "translateY(100px)",
  });

  return (
    <div>
      <Container fluid className="pb-5">
        <Row>
          <Col
            // ref={ref_col1}
            lg="4"
            className="py-3"
            style={{ height: "65vh" }}
          >
            <animated.div
              ref={ref_col1}
              className="h-100"
              style={{
                transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
                transform: tra_col1.interpolate((p) => p),
                opacity: opa_col1.interpolate((p) => p),
                // transitionDelay: `${0}s`,
              }}
            >
              <Card
                className="card-normal d-block card-mask card-horizon h-100"
                style={{
                  borderRadius: 0,
                }}
                // onClick={() => {
                //   window.location.href = "https://syouzikiya.jp/osaka/";
                // }}
              >
                {/* <a
                  href="https://syouzikiya.jp/osaka/"
                  target="_blank"
                  rel="noopener noreferrer"
                  // onClick={(e) => e.preventDefault()}
                > */}
                  <div
                    className="card-image moimg1"
                    style={{ position: "relative" }}
                  >
                    {/* <a href="..." onClick={(e) => e.preventDefault()}> */}
                    {/* <img
                      alt="..."
                      className="img"
                      style={{ borderRadius: 0 }}
                      src={require("assets/img/envato/back3-min.jpg")}
                    /> */}
                    <div
                      className="d-flex justify-content-center align-items-center divimg"
                      style={{
                        height: "35vh",
                        backgroundImage:
                          "url(" +
                          require("assets/img/envato/back3-min.jpg") +
                          ")",
                        backgroundPosition: "center center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                      }}
                    ></div>

                    <div className="mask d-flex justify-content-center align-items-center">
                      <div className="caption">ガス・電気機器の販売工事</div>
                    </div>
                    {/* </a> */}
                  </div>
                  <CardBody className="text-center pb-0 h-100">
                    <CardTitle tag="h4">ガス・電気機器の販売工事</CardTitle>
                    <div className="card-description">
                      エコキュート、電気温水器、ガス給湯器、浴室乾燥機、コンロを始めとするガス・電気機器の販売工事を自社職人にて一括で行います。
                      <br />
                      {/* 全国展開中の正直屋のFC加盟店です。 */}
                      {"　"}
                    </div>
                    <CardFooter>
                      {/* <Button
                        className="btn"
                        color="info"
                        href="..."
                        onClick={(e) => e.preventDefault()}
                      >
                        Detail
                      </Button> */}
                    </CardFooter>
                  </CardBody>
                {/* </a> */}
              </Card>
            </animated.div>
          </Col>
          <Col lg="4" className="py-3" style={{ height: "65vh" }}>
            <animated.div
              ref={ref_col2}
              className="h-100"
              style={{
                transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
                transform: tra_col2.interpolate((p) => p),
                opacity: opa_col2.interpolate((p) => p),
                // transitionDelay: `${0.4}s`,
              }}
            >
              <Card
                className="card-normal d-block  card-mask card-vertical h-100"
                style={{
                  borderRadius: 0,
                }}
                onClick={() => {
                  // window.location.href = "http://www.gaiso-ibaraki.co/";
                }}
              >
                {/* <a
                  href="http://www.gaiso-ibaraki.co/"
                  target="_blank"
                  rel="noopener noreferrer"
                  // onClick={(e) => e.preventDefault()}
                > */}
                  <div
                    className="card-image moimg1"
                    style={{ position: "relative" }}
                  >
                    {/* <img
                    alt="..."
                    className="img"
                    style={{ borderRadius: 0 }}
                    src={require("assets/img/envato/back2-min.jpg")}
                  /> */}
                    <div
                      className="d-flex justify-content-center align-items-center divimg"
                      style={{
                        height: "35vh",
                        backgroundImage:
                          "url(" +
                          require("assets/img/envato/back2-min.jpg") +
                          ")",
                        backgroundPosition: "center center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                      }}
                    ></div>

                    <div className="mask d-flex justify-content-center align-items-center">
                      <div className="caption">屋根・外壁</div>
                    </div>
                  </div>
                  <CardBody className="text-center pb-0 h-100">
                    <CardTitle tag="h4">屋根・外壁</CardTitle>
                    <div className="card-description">
                      屋根、外壁の事のならご相談下さい。お客様にとって本当に良いものとは？を追求しお客様へご提案させて頂きます。
                      <br />
                      {/* 全国展開中のガイソーのVC加盟店です。 */}
                      {"　"}
                    </div>
                    <CardFooter>
                      {/* <Button
                        className="btn"
                        color="info"
                        href="..."
                        onClick={(e) => e.preventDefault()}
                      >
                        Detail
                      </Button> */}
                    </CardFooter>
                  </CardBody>
                {/* </a> */}
              </Card>
            </animated.div>
          </Col>
          <Col lg="4" className="py-3" style={{ height: "65vh" }}>
            <animated.div
              ref={ref_col3}
              className="h-100"
              style={{
                transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
                transform: tra_col3.interpolate((p) => p),
                opacity: opa_col3.interpolate((p) => p),
                // transitionDelay: `${0.8}s`,
              }}
            >
              <Card
                className="card-normal d-block card-mask card-horizon h-100"
                style={{
                  borderRadius: 0,
                }}
              >
                <div
                  className="card-image moimg1 notcursor"
                  style={{ position: "relative" }}
                >
                  {/* <a href="..." onClick={(e) => e.preventDefault()}> */}
                  {/* <img
                  alt="..."
                  className="img"
                  style={{ borderRadius: 0 }}
                  src={require("assets/img/envato/card3-min.jpg")}
                /> */}
                  <div
                    className="d-flex justify-content-center align-items-center divimg"
                    style={{
                      height: "35vh",
                      backgroundImage:
                        "url(" +
                        require("assets/img/envato/card3-min.jpg") +
                        ")",
                      backgroundPosition: "center center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                  ></div>

                  <div className="mask d-flex justify-content-center align-items-center">
                    <div className="caption">その他内装設備全般</div>
                  </div>
                  {/* </a> */}
                </div>
                <CardBody className="text-center pb-0 h-100">
                  <CardTitle tag="h4">その他内装設備全般</CardTitle>
                  <div className="card-description">
                    レンジフード・トイレ・洗面化粧台・食洗器・水栓の交換・クロスの張り替えまでなんでもお任せ下さい。
                    {/* <br/> {"　"} */}
                  </div>
                  <CardFooter>
                    {/* <Button className="btn-fill mt-5" color="default" style={{borderRadius:0}} size="lg">
                        Detail
                      </Button> */}
                  </CardFooter>
                </CardBody>
              </Card>
            </animated.div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
