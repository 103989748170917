import React from "react";
import ReactTable from "react-table";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import ReactBSAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import ReactDatetime from "react-datetime";
import classnames from "classnames";

import TablePagination from "@material-ui/core/TablePagination";
import {
  Label,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  Form,
  FormGroup,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  Row,
  Col,
  Spinner,
} from "reactstrap";

import Loader from "views/Basic/loader";

export const schparam = (that) => {
  const upd = {};
  return upd;
};

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      alert: null,
      // month: moment([m.year(), m.month(), m.date(), 0, 0, 0, 0]),
      // sch_date: moment([m.year(), m.month(), m.date() + 1, 0, 0, 0, 0]),
      modal: false,
      sch_page: 0,
      sch_limit: 5,
      option_finish: { value: 0, label: "unfinished" },
    };
  }
  handleChangeSearch = () => {
    this.props.on_get_entries({
      data: {
        ...schparam(this),
        limit: this.state.sch_limit,
        sch_page: 0,
      },
    });
    this.setState({ sch_page: 0 });
    this.props.scrollTo();
  };

  handleChangePage = (event, newPage) => {
    this.setState({ sch_page: newPage });
    this.props.on_get_entries({
      data: {
        ...schparam(this),
        limit: this.state.sch_limit,
        page: newPage + 1,
      },
    });
    this.props.scrollTo();
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ sch_limit: parseInt(event.target.value, 10) });
    this.setState({ sch_page: 0 });
    this.props.on_get_entries({
      data: {
        ...schparam(this),
        limit: parseInt(event.target.value, 10),
        page: 0 + 1,
      },
    });
    this.props.scrollTo();
  };

  componentDidMount() {
    this.props.on_get_entries({
      data: {
        limit: this.state.sch_limit,
        ...schparam(this),
        page: 0 + 1,
      },
    });
  }

  render() {
    const t = this.props.t;

    const entries = this.props.entries.isFetching
      ? []
      : this.props.entries.entries;

    const count = this.props.entries.isFetching ? 0 : this.props.entries.count;

    const inLoading =
      this.props.entries.isFetching || this.props.entries.isLoading;

    return (
      <>
        {inLoading ? (
          <>
            <div className="px-0 d-flex justify-content-center align-items-center h-100">
              <Spinner
                className={classnames({
                  "position-relative": true,
                  "button-style": !true,
                  visible: true,
                  invisible: !true,
                })}
                size="sm"
              />
            </div>
          </>
        ) : (
          <>
            <Row className="d-flex flex-row justify-content-between px-1 mx-0">
              <Col lg="6" className="px-0"></Col>
              <Col lg="6" className="px-0  ">
                <TablePagination
                  // labelRowsPerPage = 'Rows per page:'
                  // rowsPerPageOptions={[10, 50]}
                  // rowsPerPageOptions={[2, 5, 10, { value: -1, label: "All" }]}
                  rowsPerPageOptions={[5]}
                  labelRowsPerPage="行数"
                  labelDisplayedRows={({ from, to, count }) =>
                    `${count} 件中 ${from} ~ ${to} 件表示`
                  }
                  component="div"
                  count={count}
                  page={this.state.sch_page}
                  onChangePage={this.handleChangePage}
                  rowsPerPage={this.state.sch_limit}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </Col>
            </Row>
            {/* {entries.map((d) => {
              return  <Row className="d-flex flex-row justify-content-between px-1 mx-0">
              <Col lg="2" className="px-0">
                {d.title}
              </Col>
              <Col lg="4" className="px-0  ">
              
              </Col>
            </Row>
            ;
            })} */}
            <Card className="card-plain card-blog" id="self-produce">
              {entries.map((prop, key) => {
                const post_date = moment
                  .utc(prop.post_date)
                  .local()
                  .format("YYYY.MM.DD");

                return (
                  <Row
                    className="mb-4"
                    onClick={() => {
                      this.props.history.push({
                        pathname: `/blog/${prop.entry_id}`,
                        state: {
                          content: prop.content,
                          title: prop.title,
                          date: post_date,
                        },
                      });
                    }}
                  >
                    <Col lg="4">
                      <div className="card-image  h-100">
                        {/* <div className="text-center"> */}
                        <div className="d-flex justify-content-center align-items-center h-100">
                          {prop.eyecatch ? (
                            <div
                              className="backgroundImage-80"
                              style={{
                                width: "100%",
                                height: "40vh",
                                borderRadius: 12,
                                backgroundImage: "url(" + prop.eyecatch + ")",
                              }}
                            ></div>
                          ) : (
                            <div
                              className="d-flex justify-content-center align-items-center"
                              style={{
                                height: "40vh",
                                width: "100%",
                                border: "2px #dddddd solid",
                                borderRadius: 12,
                              }}
                            >
                              <img
                                alt="..."
                                className="img"
                                src={require("assets/img/matsunaga/logo.png")}
                                style={{
                                  maxHeight: 120,
                                  width: "auto",
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col lg="8">
                      <CardBody className="pt-0 pb-5">
                        <h4 className="card-category text-default mt-0">
                          {post_date}
                        </h4>
                        <CardTitle tag="h3">
                          {/* <a href="..." onClick={(e) => e.preventDefault()}>
                            {prop.title}
                          </a> */}
                          <Link
                            to={{
                              pathname: `/blog/${prop.entry_id}`,
                              state: {
                                content: prop.content,
                                title: prop.title,
                                date: post_date,
                              },
                            }}
                            activeClassName="active"
                          >
                            {prop.title}
                          </Link>
                        </CardTitle>
                        <p className="card-description">
                          {prop.content}
                          {/* <a href="..." onClick={(e) => e.preventDefault()}>
                            Read More
                          </a> */}
                        </p>
                        {/* <p className="author">
                          by{" "}
                          <a href="..." onClick={(e) => e.preventDefault()}>
                            <b>Megan Rose Dickey</b>
                          </a>
                          , 11 days ago
                        </p> */}
                      </CardBody>
                    </Col>
                  </Row>
                );
              })}
            </Card>
            {/* <ReactTable
              data={entries.map((prop, key) => {
                const post_date = moment
                  .utc(prop.post_date)
                  .local()
                  .format("YYYY.MM.DD");
                return {
                  id: key,
                  title: prop.title,
                  post_date: post_date,
                  content: prop.content,
                  entry_id: prop.entry_id,
                  image: (
                    <div className="text-center">
                      {prop.eyecatch ? (
                        <img
                          alt="..."
                          className="img"
                          src={prop.eyecatch}
                          style={{ maxHeight: 100, width: "auto" }}
                        />
                      ) : null}
                    </div>
                  ),
                  actions: (
                    <div className="actions-left">
                      <Link
                        to={{
                          pathname: `/blog/${prop.entry_id}`,
                          state: {
                            content: prop.content,
                            title: prop.title,
                            date: post_date,
                          },
                        }}
                        activeClassName="active"
                      >
                        {prop.title}
                      </Link>
                    </div>
                  ),
                };
              })}
              filterable
              columns={[
                {
                  Header: t(""),
                  accessor: "image",
                  width: 200,
                },
                {
                  Header: t("日付"),
                  accessor: "post_date",
                  width: 100,
                },
                {
                  // Header: () => <div className="text-center">{t("Edit")}</div>,
                  Header: t("タイトル"),
                  accessor: "actions",
                  sortable: false,
                  filterable: false,
                  width: 300,
                },
                {
                  // Header: () => <div className="text-center">{t("Edit")}</div>,
                  Header: t("本文"),
                  accessor: "content",
                  sortable: false,
                  filterable: false,
                  width: 500,
                },
              ]}
              sortable={false}
              multiSort={false}
              resizable={false}
              // filterable={false}
              showPageJump={true}
              defaultSortDesc={false}
              defaultSorted={[]}
              defaultFiltered={[]}
              defaultResized={[]}
              page={this.state.tablePage}
              onPageChange={(pageIndex) => {
                this.setState({ tablePage: pageIndex });
                // window.scrollTo(0, this.props.scrollPosition);
              }}
              getTrProps={(state, rowInfo, column) => {
                return {
                  onClick: (e, t) => {
                    this.props.history.push({
                      pathname: `/blog/${rowInfo.original.entry_id}`,
                    });
                  },
                  // style: {
                  //     background: rowInfo && rowInfo.row.selected ? 'green' : 'red'
                  // }
                };
              }}
              // page={2}
              // PaginationComponent={this.Pagination1}
              // defaultPageSize={this.state.rowsPerPage}
              pageSize={this.state.sch_limit}
              // showPagination={false}
              showPageSizeOptions={false}
              showPaginationTop={false}
              showPaginationBottom={false}
              previousText={<i className="fas fa-arrow-left"></i>}
              nextText={<i className="fas fa-arrow-right"></i>}
              loading={inLoading}
              // LoadingComponent={Loader}
              loadingText="Loading..."
              noDataText={t("Data Not Found")}
              pageText={t("Page")}
              ofText="/"
              rowsText={t("Row")}
              className="-striped -highlight info-pagination"
            /> */}
            <Row className="d-flex flex-row justify-content-between px-1 mx-0">
              <Col lg="8" className="py-4"></Col>
              <Col lg="4" className="px-0  "></Col>
            </Row>
          </>
        )}
      </>
    );
  }
}

export default App;
