import React from "react";
import {
  Animate,
  AnimateGroup,
  useAnimateGroup,
  AnimateKeyframes,
} from "react-simple-animate";
import { CSSTransition } from "react-transition-group";
import { initReactI18next, useTranslation } from "react-i18next";

export function LetterFlowLeft({ color, str }) {
  const [t, i18n] = useTranslation();

  return (
    <>
      {/* <LetterArea
        opacity={1}
        clipPath="polygon(0 0, 10% 0, 10% 100%, 0 100%)"
        color={color}
        str={str}
      />

      <LetterArea
        opacity={0.1}
        clipPath="polygon(10% 0, 50% 0, 50% 100%, 10% 100%)"
        color={color}
        str={str}
      />
      <LetterArea
        opacity={1}
        clipPath="polygon(50% 0, 100% 0, 100% 100%, 50% 100%)"
        color={color}
        str={str}
      /> */}
    </>
  );
}

export function LetterFlowRight({ color, str }) {
  const [t, i18n] = useTranslation();

  return (
    // <div
    //   style={{
    //     position: "relative",
    //     // top: "-15vh",
    //     bottom: 0,
    //     left: 0,
    //     right: 0,
    //   }}
    // >
    <div
      style={{
        position: "relative",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      <LetterArea
        opacity={0.8}
        clipPath="polygon(0 0, 100% 0, 100% 100%, 0 100%)"
        color="#E5E5E5"
        str="Renovation"
      />
    </div>
    // </div>
  );
}

export function LetterFlowLeftRectangle({ color, str }) {
  const [t, i18n] = useTranslation();

  return (
    <div
      style={{
        position: "relative",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      <LetterArea
        opacity={0.8}
        clipPath="polygon(0 0, 100% 0, 100% 100%, 0 100%)"
        color="#E5E5E5"
        str="Exterior"
      />
    </div>
  );
}

export function LetterFlowRightRectangle({ color, str }) {
  const [t, i18n] = useTranslation();

  return (
    <div
      style={{
        position: "relative",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      <LetterArea
        opacity={0.8}
        clipPath="polygon(0 0, 100% 0, 100% 100%, 0 100%)"
        color="#E5E5E5"
        str="Interior"
      />
    </div>
  );
}

function LetterArea({ opacity, clipPath, color, str }) {
  return (
    <div
      id="id45"
      style={{
        WebkitClipPath: clipPath,
        clipPath: clipPath,
        overflow: "hidden",

        // backgroundColor:"yellow"
      }}
    >
      <AnimateKeyframes
        play={true}
        pause={false}
        easeType="linear"
        iterationCount="infinite"
        // direction="alternate"
        // duration={25}
        // duration={20}
        duration={10}
        keyframes={[
          // 最初に出た状態からのスタート
          // "transform: translate(100%)",
          // "transform: translate(-50%)",
          // 最初に出てない状態からのスタート
          "transform: translate(+100%); opacity:0",
          "transform: translate(+50%); opacity:1",
          "transform: translate(+0%); opacity:1",
          "transform: translate(-50%); opacity:0",
        ]}
      >
        <p
          style={{
            // fontSize: 60,
            // fontSize: "5rem",
            fontSize: "15vw",
            // marginTop: 80,
            fontFamily: "Montserrat, sans-serif",
            fontWeight: 600,
            lineHeight: "16vw",
            letterSpacing: 0,
            WebkitTextStrokeColor: "#E5E5E5",
            WebkitTextStrokeWidth: 2,
            color,
            opacity,
            whiteSpace: "nowrap",
            // width:"100vw"
          }}
        >
          {str}
        </p>
      </AnimateKeyframes>
    </div>
  );
}
