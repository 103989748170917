import React, { FC, useEffect, useRef, useState } from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
  Input,
  UncontrolledTooltip,
} from "reactstrap";

import { useSpring, animated, interpolate } from "react-spring";
// 親要素がabsoluteであることから、ContainerやRowはすべて高さが０である
// このことを利用して、Rowを重ねていこう

// 子要素を無視するabsoluteとfixed
// 子要素を計算して自分の大きさを決めるrelativeとstatic

export function ContainerHead({ inview, title, subtitle, src, background }) {
  // { opa, tra, clip }

  let { opa, flowright, flowleft, transxleft, transytop } = useSpring({
    opa: inview ? 1 : 0,
    flowright: inview
      ? "rect(0vh, 100vw, 100vh, 0vw)"
      : "rect(0vh, 0vw, 100vh, 0vw)",
    flowleft: inview
      ? "rect(0vh, 100vw, 100vh, 0vw)"
      : "rect(0vh, 100vw, 100vh, 100vw)",
    // transxleft: inview ? "translate3d(0px, 0, 0)" : "translate3d(70px, 0, 0)",
    transxleft: inview ? "translateX(0)" : "translateX(70px)",
    transxright: inview ? "translateX(0)" : "translateX(-70px)",
    transytop: inview ? "translateY(0)" : "translateY(70px)",
  });

  return (
    <Container fluid  style={{ height: "100vh" }}>
      <Row>
        <Col lg="12" className="px-0">
          <animated.div
            style={{
              // transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
              transition: "all 800ms ease",
              opacity: opa.interpolate((p) => p),
              clip: flowright.interpolate((p) => p),
              // position: "fixed",
            }}
          >
            <div
              style={{ position: "absolute", height: "50vh", width: "100vw" }}
              className="black-cool"
            ></div>
          </animated.div>
        </Col>
      </Row>
      <Row>
        <Col lg="2" className="px-0"></Col>
        <Col lg="10" className="px-0">
          <animated.div
            style={{
              // transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
              transition: "all 800ms ease",
              transitionDelay: "0.3s",
              transform: transxleft.interpolate((p) => p),
              opacity: opa.interpolate((p) => p),
              // clip: flowleft.interpolate((p) => p),
              // position: "fixed",
            }}
          >
            <div
              className="backgroundImage-80"
              style={{
                position: "absolute",
                left: "20vw",
                top: "30vh",
                width: "100vw",
                height: "100vh",
                minHeight: "40vh",
                maxHeight: "40vh",
                ...background,
              }}
            ></div>
            {/* <div
              className="backgroundImage-80"
              style={{
                position: "absolute",
                left: "20vw",
                top: "30vh",
                width: "100vw",
                hiight: "100vh",
                minHeight: "40vh",
                maxHeight: "40vh",
                backgroundColor: "rgba(0, 0, 0, 0.2)",
              }}
            ></div> */}
          </animated.div>
        </Col>
      </Row>
      <Row>
        <Col lg="6" style={{ paddingLeft: 0 }}>
          <animated.div
            style={{
              // transition: `all 2000ms cubic-bezier(0.165, 0.84, 0.44, 1)`,
              transition: "all 800ms ease",
              transitionDelay: "0.3s",
              transform: transytop.interpolate((p) => p),
              opacity: opa.interpolate((p) => p),
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "10vh",
                left: "10vw",
                // width: "100vw",
                // hiight: "100vh",
                // backgroundColor: "white",
              }}
            >
              <h1
                style={{
                  // color: "white",
                  color: "#C3B189",
                  fontWeight: 600,
                  letterSpacing: 4,
                }}
              >
                {title}
              </h1>
            
              <p
                style={{
                  // color: "white",
                  color: "#C3B189",

                  // fontWeight: 600,
                  letterSpacing: 1,
                  paddingTop: 10,
                }}
              >
                {subtitle}
              </p>
            </div>
          </animated.div>
        </Col>
      </Row>
    </Container>
  );
}


export function ContainerLeftRectangle({ inview }) {
  return (
    <Container fluid>
      <Row>
        <Col lg="8" className="px-0"></Col>
        <Col lg="4" className="px-0">
          <div
            style={{
              position: "absolute",
              // left: "5vw",
              // top: "70vh",
              height: "65vh",
              width: "80%",
            }}
            className="gray-cool"
          ></div>
        </Col>
      </Row>
    </Container>
  );
}

export function ContainerRightRectangle({ inview }) {
  return (
    <Container fluid>
      <Row>
        <Col lg="2" className="px-0"></Col>
        <Col lg="5" className="px-0">
          <div
            style={{
              position: "absolute",
              right: 0,
              // top: "70vh",
              height: "70vh",
              width: "80%",
            }}
            className="gray-cool"
          ></div>
        </Col>
        <Col lg="4" className="px-0"></Col>
      </Row>
    </Container>
  );
}