import React from "react";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";

// reactstrap components
import { Button, FormGroup, Container, Row, Col } from "reactstrap";
import SocialShare from "./Social";

// core components

function SectionFooterAreas({ title, shareLink }) {
  const [languageSelect, setLanguageSelect] = React.useState({
    value: "en",
    label: "English",
  });
  const [curencySelect, setCurencySelect] = React.useState({
    value: "USD",
    label: "USD",
  });
  return (
    <>
      <footer
        className="footer footer-black footer-big"
        style={{ paddingBottom: 100 }}
      >
        <Container fluid>
          <Row>
            <Col className="text-left px-5" style={{ color: "#a2a2a1" }} lg="6">
              <h4>松永興業株式会社</h4>
              ・本社 〒577-0843　大阪府東大阪市荒川3丁目25番19号
              <br />
              ・TEL/FAX : 06-4309-5550/06-4309-5510
              <br />
              ・E-mail: info@matsunaga-ko.com
              <br />
              ・北大阪営業所 〒567-0878　大阪府茨木市蔵垣内3丁目15-12
              <br />
              ・新大阪営業所 〒532-0011　大阪府大阪市淀川区西中島4丁目13-5
              今川第2ビル101号
              <br />
              <Container fluid>
                <Row>
                  <Col xs="6">
                    <div className="links">
                      <ul className="uppercase-links stacked-links">
                        <li>
                          <a href="..." onClick={(e) => e.preventDefault()}>
                            HOME
                          </a>
                        </li>
                        <li>
                          <a href="..." onClick={(e) => e.preventDefault()}>
                            ABOUT
                          </a>
                        </li>
                        <li>
                          <a href="..." onClick={(e) => e.preventDefault()}>
                            COMPANY
                          </a>
                        </li>
                      </ul>
                    </div>
                  </Col>
                  <Col xs="6">
                    <div className="links">
                      <ul className="uppercase-links stacked-links">
                        <li>
                          <a href="..." onClick={(e) => e.preventDefault()}>
                            MESSAGE
                          </a>
                        </li>
                        <li>
                          <a href="..." onClick={(e) => e.preventDefault()}>
                            NEWS
                          </a>
                        </li>
                        <li>
                          <a href="..." onClick={(e) => e.preventDefault()}>
                            CONTACT
                          </a>
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </Container>
              <hr />
              <div className="copyright">
                <div className="pull-left">© {new Date().getFullYear()}</div>
                <div className="links pull-right">
                  {/* <ul>
                        <li className="mr-1">
                          <a href="..." onClick={(e) => e.preventDefault()}>
                            Company Policy
                          </a>
                        </li>
                        |{" "}
                        <li className="mr-1">
                          <a href="..." onClick={(e) => e.preventDefault()}>
                            Terms
                          </a>
                        </li>
                        |{" "}
                        <li>
                          <a href="..." onClick={(e) => e.preventDefault()}>
                            Privacy
                          </a>
                        </li>
                      </ul> */}
                </div>
              </div>
              {/* <div className="social-area">
                <Button
                  className="btn-just-icon btn-round mr-1"
                  color="facebook"
                >
                  <i className="fa fa-facebook" />
                </Button>
                <Button
                  className="btn-just-icon btn-round mr-1"
                  color="twitter"
                >
                  <i className="fa fa-twitter" />
                </Button>
                <Button className="btn-just-icon btn-round" color="google">
                  <i className="fa fa-google-plus" />
                </Button>
              </div> */}
            </Col>
            <Col className=" px-5" lg="6">
              <SocialShare title={title} shareLink={shareLink} />
              <dl class="row">
                <dt class="col-sm-6 text-left">
                  {/* <a
                    href="https://syouzikiya.jp/osaka/"
                    target="_blank"
                    rel="noopener noreferrer"
                    // onClick={(e) => e.preventDefault()}
                  > */}
                    <img
                      alt="..."
                      className="img"
                      src={"/logo/footer_image1.jpg"}
                      style={{ maxHeight: 100, maxWidth: "100%" }}
                    />
                  {/* </a> */}
                </dt>
                <dd class="col-sm-6  text-left">
                  正直屋加盟店 大阪府を中心に
                  年間約３,０００件の給湯器施工を行っております。
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-6 text-left">
                  {/* <a
                    href="http://www.gaiso-ibaraki.co/"
                    target="_blank"
                    rel="noopener noreferrer"
                    // onClick={(e) => e.preventDefault()}
                  > */}
                    <img
                      alt="..."
                      className="img"
                      src={"/logo/footer_image2.jpg"}
                      style={{ maxHeight: 100, maxWidth: "100%" }}
                    />
                  {/* </a> */}
                </dt>
                <dd class="col-sm-6  text-left">
                  ガイソー加盟店 外壁塗装・屋根塗装など
                  住まいの外装リフォームのことならお任せ下さい。
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-6 text-left">
                  <a
                    href="https://www.evessa.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    // onClick={(e) => e.preventDefault()}
                  >
                    <img
                      alt="..."
                      className="img"
                      src={"/logo/footer_image3.jpg"}
                      style={{ maxHeight: 100, maxWidth: "100%" }}
                    />
                  </a>
                </dt>
                <dd class="col-sm-6  text-left">
                  私たちは大阪エヴァッサを応援しています。
                </dd>
              </dl>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default SectionFooterAreas;
