import { useDispatch, useSelector } from "react-redux";
import React, { FC, useEffect, useRef, useState } from "react";
import Company from "../ContainerLG/Company";
import CompanySM from "../ContainerSM/Company";

// import CompanySm from "../ContainerSM/Company";
import { Helmet } from "react-helmet";
function CompanyContainer() {

  const [width, set_width] = React.useState(window.innerWidth);

  function resizeListener() {
    set_width(window.innerWidth);
  }
  
  useEffect(() => {
    window.addEventListener("resize", resizeListener);

    return function cleanup() {
      window.removeEventListener("resize", resizeListener);
    };

  }, []);




  return (
    <>
      <Helmet>
        <meta
          name="keywords"
          content="松永興業株式会社,東大阪市,給湯器交換,外壁塗装"
        />
        <title>大阪府下３拠点で府下全域をカバー｜松永興業株式会社</title>
        <meta
          name="description"
          content="東大阪市の本社をはじめとし、奈良県に奈良支店を構えています。事業内容は住宅設備機器販売・設置工事 住宅水回りリフォーム工事、外装リフォーム工事と多岐に渡ります。"
        />
        <link
          rel="canonical"
          href="https://www.matsunaga-industrial.com/company"
        />
      </Helmet>

      {width > 992 ? (
        <Company />
      ) : (
        <div id="style-sm" className="overflow-hidden">
          <CompanySM />
        </div>
      )}



      {/* <div className="d-none d-lg-block">
        <Company />
      </div>
      <div className="d-lg-none overflow-hidden"><CompanySm /></div> */}
    </>
  );
}

export default CompanyContainer;
