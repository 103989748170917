import React from "react";
import StarsUp from "../Component/StarsUp";


function Topimagetrans({ tri }) {
  return (
    <>
      <div
        style={{
          height: "100vh",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      ></div>
      <StarsUp />
    </>
  );
}

export default Topimagetrans;
