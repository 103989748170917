import { useDispatch, useSelector } from "react-redux";
import React, { FC, useEffect, useRef, useState } from "react";
import Message from "../ContainerLG/Message";
import MessageSM from "../ContainerSM/Message";
// import Message from "../ContainerLG/Message2";
// import MessageSm from "../ContainerSM/Message";
import { Helmet } from "react-helmet";

function MessageContainer() {
  const [width, set_width] = React.useState(window.innerWidth);

  function resizeListener() {
    set_width(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", resizeListener);

    return function cleanup() {
      window.removeEventListener("resize", resizeListener);
    };

  }, []);

  return (
    <>
      <Helmet>
        <meta
          name="keywords"
          content="松永興業株式会社,東大阪市,給湯器交換,外壁塗装"
        />
        <title>弊社代表挨拶・企業理念の紹介｜松永興業株式会社</title>
        <meta
          name="description"
          content="はじめまして。松永興業株式会社の代表をしております永田と申します。常にお客様目線で従業員一同お客様の手助けをさせて頂きます。どんなことでも相談してください。一生涯のお付き合いをさせて頂きます。"
        />
        <link
          rel="canonical"
          href="https://www.matsunaga-industrial.com/messege"
        />
      </Helmet>
      {width > 992 ? (
        <Message />
      ) : (
        <div id="style-sm" className="overflow-hidden">
          <MessageSM />
        </div>
      )}
    </>
  );
}

export default MessageContainer;
