import { useDispatch, useSelector } from "react-redux";
import React, { FC, useEffect, useRef, useState } from "react";
import NotificationAlert from "react-notification-alert";
import { initReactI18next, useTranslation } from "react-i18next";
import notification from "views/Basic/notification";
import ColorNavbar from "./Navbars/ColorNavbar";

import { Parallax, ParallaxLayer } from "react-spring/renderprops-addons";

import SectionFooterAreas3 from "./Component/SectionFooterAreas3.js";
import LetterAppear from "./Home/LetterAppear.js";

import { useInView } from "react-intersection-observer";
import { useSpring, animated, interpolate } from "react-spring";

import Topimagetrans from "./Headers/TopImagetrans.js";
import Topimagetrans2 from "./Headers/TopImagetrans2.js";
import BlackStar from "./Headers/BlackStar.js";
import {
  LetterFlowRight,
  LetterFlowLeft,
  LetterFlowLeftRectangle,
  LetterFlowRightRectangle,
} from "./Home/LetterFlow";

import {
  ContainerOpacityRight,
  ContainerOpacityLeft,
} from "./Home/ContainerOpacity";

import {
  ContainerTransparentRight,
  ContainerTransparentLeft,
} from "./Home/ContainerOpacity";

import {
  ContainerSquare1,
  ContainerSquare2,
  ContainerSquare3,
  ContainerNews,
  ContainerCard,
} from "./Home/ContainerSquare";

import { DivImage1, DivImage2, DivImage3 } from "./Home/DivImage";

function DashBoard() {
  const [t, i18n] = useTranslation();
  const ref = useRef();
  document.documentElement.classList.remove("nav-open");

  const [refCard, inCard] = useInView();
  let { opa, tra } = useSpring({
    opa: inCard ? 1 : 0,
    tra: inCard ? "translateY(0)" : "translateY(80px)",
  });

  const [refSquare2, inSquare2] = useInView();
  let { Square2_opa, Square2_tra } = useSpring({
    Square2_opa: inSquare2 ? 1 : 0,
    Square2_tra: inSquare2 ? "translateX(0)" : "translateX(-100px)",
  });

  const [refSquare3, inSquare3] = useInView();
  let { Square3_opa, Square3_tra } = useSpring({
    Square3_opa: inSquare3 ? 1 : 0,
    Square3_tra: inSquare3 ? "translateX(0)" : "translateX(100px)",
  });

  const [refSquare4, inSquare4] = useInView();
  let { Square4_opa, Square4_tra } = useSpring({
    Square4_opa: inSquare4 ? 1 : 0,
    Square4_tra: inSquare4 ? "translateY(0)" : "translateY(100px)",
  });
  const [triimage, set_triimage] = React.useState(false);

  let parallax = null;

  React.useEffect(() => {
    setTimeout(() => {
      set_triimage(true);
    }, 500);
  }, []);

  const [ph1, set_ph1] = useState(0);
  const [ph2, set_ph2] = useState(0);
  const [ph3, set_ph3] = useState(0);
  const [ph4, set_ph4] = useState(0);
  const [ph5, set_ph5] = useState(0);
  const [ph6, set_ph6] = useState(0);
  const [ph7, set_ph7] = useState(0);
  const [ph8, set_ph8] = useState(0);
  const [ph9, set_ph9] = useState(0);
  // const [ph10, set_ph10] = useState(0);

  const ph = () => {
    const phfunc = () => {
      // const wi = window.innerHeight;
      // const wi = document.documentElement.clientHeight;

      const vh = window.jQuery("#parallax").height();

      const ph0 = window.jQuery("#ph0").height();
      const ph1 = window.jQuery("#ph1").height() - 0.1 * vh + ph0;
      const ph2 = window.jQuery("#ph2").height() + ph1;
      const ph3 = window.jQuery("#ph3").height() + ph2;
      const ph4 = window.jQuery("#ph4").height() + ph3;
      const ph5 = window.jQuery("#ph5").height() + ph4;
      const ph6 = window.jQuery("#ph6").height() + ph5;
      const ph7 = window.jQuery("#ph7").height() + ph6;
      const ph8 = window.jQuery("#ph8").height() + ph7;
      // const ph9 = window.jQuery("#ph9").height() + ph8;
      // const ph10 = window.jQuery("#ph10").height() + ph9;

      if (ph0) {
        set_ph1(ph0 / vh);
        set_ph2(ph1 / vh);
        set_ph3(ph2 / vh);
        set_ph4(ph3 / vh);
        set_ph5(ph4 / vh);
        set_ph6(ph5 / vh);
        set_ph7(ph6 / vh);
        set_ph8(ph7 / vh);
        set_ph9(ph8 / vh);
        // set_ph10(ph9 / vh);
      }
    };
    setTimeout(phfunc, 1000);
    setTimeout(phfunc, 2000);
  };

  useEffect(() => {
    ph();
  }, []);

  return (
    <>
      <div id="parallax-bar">
        <NotificationAlert ref={ref} />
        <ColorNavbar
          title="住宅設備関連、リフォームのことなら松永興業株式会社へ"
          shareLink="https://www.matsunaga-industrial.com/home"
        />
        {/* <Loading set_tri={set_triimage} /> */}
      </div>

      <div
        id="parallax-div"
        style={{
          position: "relative",
          height: "100vh",
          // overflow: "hidden",
          // overscrollBehavior: "contain",
        }}
      >
        <Parallax
          id="parallax"
          ref={(ref) => {
            return (parallax = ref);
          }}
          // horizontal={true}
          pages={ph9}
          // config={{
          //   tension: 0,
          //   friction: 0,
          // }}
          style={
            {
              // overflow: "scroll",
              // overscrollBehavior: "contain",
              // overscrollBehavior: "none",
            }
          }
          config={{ tension: 280, friction: 60 }}
        >
          <ParallaxLayer id="Topimagetrans" offset={0} speed={-0.4}>
            <div id="ph0">
              <Topimagetrans2 tri={true} />
            </div>
          </ParallaxLayer>

          <ParallaxLayer id="Topimagetrans2" offset={0} speed={-0.4}>
            <BlackStar />
          </ParallaxLayer>

          <ParallaxLayer
            id="backgroundColorF0F0F0"
            offset={1}
            speed={0}
            style={{ backgroundColor: "#F0F0F0" }}
            // style={{ backgroundColor: "#FFF" }}
            factor={7}
          />

          <ParallaxLayer
            id="LetterAppear"
            offset={0}
            speed={0}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "right",
            }}
          >
            <LetterAppear tri={triimage} set_tri={() => {}} />
          </ParallaxLayer>

          <ParallaxLayer
            id="ContainerSquare1"
            offset={ph1}
            speed={0}
            factor={1}
          >
            <div id="ph1">
              <ContainerSquare1 />
            </div>
          </ParallaxLayer>

          <ParallaxLayer id="LetterFlowRight" offset={ph1} speed={0} factor={1}>
            <LetterFlowRight />
          </ParallaxLayer>

          {/* ================================================= */}

          <ParallaxLayer id="DivImage1" offset={ph3} speed={0} factor={1}>
            <div id="ph3">
              <DivImage1 />
            </div>
          </ParallaxLayer>

          <ParallaxLayer
            id="ContainerOpacityRight"
            offset={ph3}
            speed={0.3}
            factor={1}
          >
            <ContainerOpacityRight />
          </ParallaxLayer>

          <ParallaxLayer
            id="ContainerSquare2"
            offset={ph4}
            speed={0}
            factor={1}
          >
            <div ref={refSquare2} style={{ height: "200vh" }}>
              <div id="ph4">
                <ContainerSquare2
                  Square2_opa={Square2_opa}
                  Square2_tra={Square2_tra}
                />
              </div>
            </div>
          </ParallaxLayer>

          {/* 縦文字 */}
          <ParallaxLayer
            id="ContainerTransparentRight"
            offset={ph3}
            speed={0.3}
            factor={1}
          >
            <ContainerTransparentRight />
          </ParallaxLayer>

          {/* 横文字 */}
          <ParallaxLayer
            id="LetterFlowLeftRectangle"
            offset={ph4}
            speed={0}
            factor={1}
          >
            <LetterFlowLeftRectangle />
          </ParallaxLayer>
          {/* ================================================= */}

          <ParallaxLayer id="DivImage2" offset={ph5} speed={0} factor={1}>
            <div id="ph5">
              <DivImage2 />
            </div>
          </ParallaxLayer>

          <ParallaxLayer
            id="ContainerOpacityLeft"
            offset={ph5}
            speed={0.3}
            factor={1}
          >
            <ContainerOpacityLeft />
          </ParallaxLayer>

          <ParallaxLayer
            id="ContainerSquare3"
            offset={ph6}
            speed={0}
            factor={1}
          >
            <div ref={refSquare3} style={{ height: "200vh" }}>
              <div id="ph6">
                <ContainerSquare3
                  Square3_opa={Square3_opa}
                  Square3_tra={Square3_tra}
                />
              </div>
            </div>
          </ParallaxLayer>

          <ParallaxLayer
            id="ContainerTransparentLeft"
            offset={ph5}
            speed={0.3}
            factor={1}
          >
            <ContainerTransparentLeft />
          </ParallaxLayer>

          <ParallaxLayer
            id="LetterFlowRightRectangle"
            offset={ph6}
            speed={0}
            factor={1}
          >
            <LetterFlowRightRectangle />
          </ParallaxLayer>
          {/* ================================================= */}

          <ParallaxLayer id="ContainerNews" offset={ph2} speed={0} factor={1}>
            <div ref={refCard} style={{ height: "2200vh" }}>
              <div id="ph2">
                <ContainerNews opa={opa} tra={tra} ph={ph} />
              </div>
            </div>
          </ParallaxLayer>

          <ParallaxLayer id="ContainerCard" offset={ph7} speed={0} factor={1}>
            {/* <div ref={refSquare4} style={{ height: "200vh" }}> */}
            {/* <ContainerCard opa={Square4_opa} tra={Square4_tra} /> */}
            <div id="ph7">
              <ContainerCard />
            </div>
            {/* </div> */}
          </ParallaxLayer>

          <ParallaxLayer
            id="CarouselSectionFooterAreas"
            offset={ph8}
            speed={0}
            factor={1}
          >
            <div id="ph8">
              <SectionFooterAreas3
                title="住宅設備関連、リフォームのことなら松永興業株式会社へ"
                shareLink="https://www.matsunaga-industrial.com/home"
              />
            </div>
            {/* <Carousel2 /> */}
          </ParallaxLayer>

          {/* <ParallaxLayer id="Topimagetrans" offset={0} speed={0}> */}

          {/* </ParallaxLayer> */}
        </Parallax>
      </div>
      {/* <div
        id="parallax-div"
        style={{
          position: "relative",
          height: "110vh",
        }}
      >
      </div> */}

      {/* 親から子供にスクロール伝達は一瞬でできない */}
    </>
  );
}
export default DashBoard;

// config={config.gentle}
// /** default: { tension: 170, friction: 26 } */
// default: SpringConfig
// /** gentle: { tension: 120, friction: 14 } */
// gentle: SpringConfig
// /** wobbly: { tension: 180, friction: 12 } */
// wobbly: SpringConfig
// /** stiff: { tension: 210, friction: 20 } */
// stiff: SpringConfig
// /** slow: { tension: 280, friction: 60 } */
// slow: SpringConfig
// /** molasses: { tension: 280, friction: 120 } */
// molasses: SpringConfig
